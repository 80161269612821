import React from "react";
import { MyLocalesValueInput } from "../../components/MyLocalesValueInput";
import { MyImageSwipersValueInput } from "../../components/MyImageSwipersValueInput";
import { NumberInput, required, TextInput } from "react-admin";
import { format, parse } from "../../utils/percent";
import InputAdornment from "@mui/material/InputAdornment";
import { MyRichTextInput } from "../../components/MyRichTextInput";

export const TgSetting: React.FC = (props) => {
  return (
    <>
      <TextInput label="apiId" source="setting.apiId" validate={required()} />
      <TextInput
        label="apiHash"
        source="setting.apiHash"
        validate={required()}
      />
      <TextInput
        label="telethon url"
        source="setting.baseUrl"
        validate={required()}
      />
    </>
  );
};
