export interface TgFriendGroupModel {
  id?: string;
  // ip
  name?: string;
  remarks?: string;
  startTime?: string;
  stepSecond?: number;
  taskIsOver?: Boolean;
  tgMsgGroupId?: string;
  friendGroupId?: string;
  sendCount?: number;
  sendOver?: PhoneSendRes[];
  // 完成时间
  completedTime?: string;
  taskState?: TaskOverType;
  errorInfo?: string;
}

export enum TaskOverType {
  INIT = "INIT",
  RUNNING = "RUNNING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

export interface PhoneSendRes {
  friendPhone?: string;
  sendOk?: boolean;
  remarks?: string;
}
