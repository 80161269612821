import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import TelegramIcon from "@mui/icons-material/Telegram";
import {
  DefaultCommands,
  ResourceType,
} from "../../components/core/MyResource";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { TgAccountStatusChoices } from "../../model/tgProtocolChatData";
import { TgAcReConnectCommand } from "./commands/TgAcReConnectCommand";
import { defaultBulkActionButtons } from "../../components/core/MyProDataGrid";
import { BulkDeleteButton } from "react-admin";
import { TgAcDelDeactivatedCommand } from "./commands/TgAcDelDeactivatedCommand";
import { TgAcStateCheckCommand } from "./commands/TgAcStateCheckCommand";

const fields: MyField[] = [
  Field.objectId({}),
  Field.text({
    label: "手机号",
    source: "phone",
  }),
  Field.text({
    label: "登录密码",
    source: "password",
    isFilter: false,
  }),
  Field.text({
    label: "昵称",
    source: "nickname",
    isFilter: false,
    isCreate: false,
    isUpdate: false,
  }),
  Field.text({
    label: "用户名",
    source: "username",
    isFilter: false,
    isCreate: false,
    isUpdate: false,
  }),
  Field.ref({
    label: "代理",
    source: "tgProxyAddressId",
    reference: "tgProxyAddress",
    referenceLabel: "host",
    originSource: "tgProxyAddressId",
    isFilter: false,
    isList: false,
    editFilter: { enable: true },
  }),
  Field.functionField({
    label: "代理",
    source: "tgProxyAddress",
    fullWidth: true,
    render: (record?: any, source?: string) => {
      return record.tgProxyAddress
        ? `${record.tgProxyAddress?.host}:${record.tgProxyAddress?.port} ${record.tgProxyAddress?.proxyType}`
        : "";
    },
    isCreate: false,
    isEdit: false,
  }),
  Field.editNumber({
    label: "任务最大发送（天）",
    source: "taskMaxSendInDay",
    isFilter: false,
    isCreate: true,
    isUpdate: true,
    isEdit: true,
  }),
  Field.number({
    label: "已发送(天)",
    source: "daySend",
    isFilter: false,
    isCreate: false,
    isUpdate: false,
    isEdit: false,
  }),
  Field.number({
    label: "发送成功(天)",
    source: "daySendOk",
    isFilter: false,
    isCreate: false,
    isUpdate: false,
    isEdit: false,
  }),
  Field.functionField({
    label: "账号无双向",
    source: "dontSendMsg",
    isFilter: false,
    render: (record?: any, source?: string) => {
      return record.dontSendMsg ? (
        <HighlightOffIcon color={"error"} fontSize="small" />
      ) : (
        <TaskAltIcon color={"success"} fontSize="small" />
      );
    },
    isCreate: false,
    isUpdate: false,
  }),
  Field.dateTime({
    label: "最近一次双向",
    source: "dontSendMsgLastDate",
  }),
  Field.select({
    label: "状态",
    source: "status",
    choices: TgAccountStatusChoices,
    isFilter: true,
    isCreate: false,
    isUpdate: false,
  }),
  Field.text({
    label: "最后一次登录错误状态",
    source: "lastLoginErrorMsg",
    isFilter: false,
    isCreate: false,
    isUpdate: false,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
];

const resource: ResourceType = {
  commands: [
    <TgAcReConnectCommand />,
    <TgAcStateCheckCommand />,
    <TgAcDelDeactivatedCommand />,
    ...DefaultCommands,
  ],
  list: () => {
    return (
      <MyList
        pagination={false}
        fields={fields}
        rowActions={[]}
        bulkActionButtons={<BulkDeleteButton />}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <TelegramIcon />,
};

export default resource;
