import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";
import { VerticalAlignTop } from "@mui/icons-material";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { Loading } from "react-admin";
import { UpBookBtn } from "./UpBookBtn";
import {
  useTgChatFriend,
  useTgUnReadFriendData,
} from "../hook/TgAccountDataContext";
import TgAvatar from "./TgAvatar";
import { TgProtocolApi } from "../../../api/TgProtocolApi";
import { useChatAccount, useTgUnReadFriend } from "../hook/ChatDataContext";
import { TgFriendSearchBtn } from "./TgFriendSearchBtn";

const formatDate = (timestamp: number): string => {
  const date = dayjs(timestamp * 1000);
  const today = dayjs().startOf("day");

  if (date.isSame(today, "day")) {
    return date.format("HH:mm");
  } else {
    return date.format("MM-DD");
  }
};

export interface FriendListProps {}

export const TgFriendList = ({}: FriendListProps) => {
  const { select: account, selectIndex: accountIndex } = useChatAccount();
  const [hoveredIndex, setHoveredIndex] = useState<number>();
  const [isNormal, setIsNormal] = useState<boolean>(true);

  const { list: unreadFriendInfo } = useTgUnReadFriend();

  function isRead(friendId: number) {
    if (!isNormal) {
      return true;
    } else {
      return (
        unreadFriendInfo
          ?.find((d) => d.userId === account?.id)
          ?.unreadChatList?.find((c) => c === `${friendId}`) === undefined
      );
    }
  }

  const {
    changeSelect: acChangeSelect,
    select: acSelect,
    list: acList,
    error: acError,
    isLoading: acIsLoading,
    isError: acIsError,
    refetch: acRefetch,
  } = useTgChatFriend();

  const {
    changeSelect: urChangeSelect,
    select: urSelect,
    list: urList,
    error: urError,
    isLoading: urIsLoading,
    isError: urIsError,
    refetch: urRefetch,
  } = useTgUnReadFriendData();

  useEffect(() => {
    console.log("accountIndex change: ", accountIndex);
    if (accountIndex === 0) {
      setIsNormal(false);
    } else {
      setIsNormal(true);
    }
  }, [accountIndex]);

  useEffect(() => {
    async function readFriend(id: string) {
      await TgProtocolApi.readChat(id);
    }

    if (acSelect) {
      readFriend(`${acSelect?.oid}`);
    }

    if (urSelect) {
      readFriend(`${urSelect?.oid}`);
    }
  }, [acSelect, urSelect]);

  if (isNormal ? acIsLoading : urIsLoading) {
    return <Loading />;
  }

  if (isNormal ? acIsError : urIsError) {
    return <div>Error: {`${isNormal ? acError : urError}`}</div>;
  }

  const handleClick = (index: number) => {
    if (isNormal) {
      acChangeSelect(index);
    } else {
      urChangeSelect(index);
    }
  };

  const handleUpTop = async (friendId: string, isTop: number) => {
    if (account) {
      if (isTop === 1) {
        await TgProtocolApi.doUpAcUser(friendId);
      } else {
        await TgProtocolApi.doCancelUpAcUser(friendId);
      }

      if (isNormal) {
        acRefetch();
      } else {
        urRefetch();
      }
    }
  };

  return (
    <Box
      sx={{
        width: "240px",
        bgcolor: "background.paper",
      }}
    >
      <Stack padding={1} spacing={1}>
        <TgFriendSearchBtn />
        <UpBookBtn />
      </Stack>
      <Divider sx={{ marginBottom: 1 }} />
      <List
        sx={{
          "& ul": { padding: 0 },
        }}
      >
        {(isNormal ? acList : urList)?.map((item, index) => (
          <React.Fragment key={item?.id}>
            <ListItemButton
              sx={{ padding: "1px 4px", textAlign: "left" }}
              selected={item?.id === (isNormal ? acSelect?.id : urSelect?.id)}
              onClick={() => handleClick(index)}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(undefined)}
            >
              <ListItemAvatar>
                <Badge
                  invisible={isRead(item?.oid!!)}
                  color="error"
                  variant="dot"
                  sx={{
                    "& .MuiBadge-badge": {
                      top: "3px",
                      backgroundColor: "#ff0000",
                      fontSize: "10px",
                      padding: "0 4px",
                    },
                  }}
                >
                  <TgAvatar
                    head={`${item?.headUrl}`}
                    firstName={item?.first_name}
                    lastName={item?.last_name}
                  />
                </Badge>
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    fontSize: "0.875rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
                secondaryTypographyProps={{
                  style: {
                    width: "130px",
                    fontSize: "0.75rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
                primary={`${item.first_name ?? ""} ${item.last_name ?? ""}`}
                secondary={item.last_msg ?? ""}
              />
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ fontSize: "0.75rem" }}
                minWidth={"32px"}
              >
                {item?.last_msg_date
                  ? formatDate(new Date(item?.last_msg_date).getTime())
                  : ""}
                <IconButton
                  sx={{
                    height: "18px",
                    width: "18px",
                    visibility:
                      accountIndex !== 0 &&
                      (hoveredIndex === index || item.isTop === 2)
                        ? "visible"
                        : "hidden",
                  }}
                  color={"default"}
                  onClick={(e) => {
                    e.preventDefault();
                    handleUpTop(item.id!!, item.isTop);
                  }}
                >
                  <VerticalAlignTop
                    sx={{
                      height: "18px",
                      width: "18px",
                    }}
                  />
                </IconButton>
              </Box>
            </ListItemButton>

            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};
