import React, { createContext, useContext } from "react";
import { useReqTgHistoryList } from "./useReqTgHistoryList";
import { useReqTgChatFriendList } from "./useReqTgChatFriendList";
import { ContextData } from "./data";
import { useCurrentAdmin } from "../../../hook/useCurrentAdmin";
import { TgAccount } from "../../../model/tgProtocolChatData";
import {
  Message,
  MessagePageResp,
  TgUnreadModel,
  User,
} from "../../../api/TgProtocolApi";
import { useReqTgUnReadChatFriendList } from "./useReqTgUnReadChatFriendList";

interface TgAccountData {
  friendData: ContextData<User>;
  unReadFriendData: ContextData<User>;
  historyData: ContextData<Message>;
}

const TgAccountDataContext = createContext<TgAccountData | undefined>(
  undefined
);

export const TgAccountDataProvider: React.FC<{
  children: React.ReactNode;
  account?: TgAccount | null;
}> = ({ children, account }) => {
  const { admin } = useCurrentAdmin();

  const unReadFriendData = useReqTgUnReadChatFriendList({
    account: account?.id,
  });

  const friendData = useReqTgChatFriendList({
    account: account?.id,
  });

  const historyData = useReqTgHistoryList({
    account: account?.id,
    friendAccount: friendData?.select,
  });

  return (
    <TgAccountDataContext.Provider
      value={{
        friendData,
        unReadFriendData,
        historyData,
      }}
    >
      {children}
    </TgAccountDataContext.Provider>
  );
};

export const useTgChatData = (): TgAccountData => {
  const context = useContext(TgAccountDataContext);
  if (!context) {
    throw new Error("useTgChatData must be used within a ChatDataProvider");
  }
  return context;
};

export function useTgChatFriend() {
  const { friendData } = useTgChatData();
  return friendData;
}

export function useTgUnReadFriendData() {
  const { unReadFriendData } = useTgChatData();
  return unReadFriendData;
}

export function useTgChatHistory() {
  const { historyData } = useTgChatData();
  return historyData;
}
