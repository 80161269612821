export type ApiType = "OPENAI_ASSISTANT" | "OPENAI" | "ALI";

export const RetrievalFileType = [
  ".c",
  ".cpp",
  ".cs",
  ".java",
  ".sh",
  ".js",
  ".ts", // 对应 MediaType.TEXT_PLAIN
  ".csv", // 对应 application/csv
  ".doc", // 对应 application/msword
  ".docx", // 对应 application/vnd.openxmlformats-officedocument.wordprocessingml.document
  ".html", // 对应 MediaType.TEXT_HTML
  ".json", // 对应 MediaType.APPLICATION_JSON
  ".md", // 对应 MediaType.TEXT_MARKDOWN
  ".pdf", // 对应 MediaType.APPLICATION_PDF
  ".php", // 对应 text/x-php
  ".pptx", // 对应 application/vnd.openxmlformats-officedocument.presentationml.presentation
  ".py", // 对应 MediaType.TEXT_PLAIN
  ".rb", // 对应 text/x-ruby
  ".tex", // 对应 text/x-tex
  ".txt", // 对应 MediaType.TEXT_PLAIN
  ".css", // 对应 text/css
  ".tar", // 对应 application/x-tar
  ".xlsx", // 对应 application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
  ".xml", // 对应 MediaType.APPLICATION_XML
  ".zip", // 对应 application/zip
];

export const ApiTypeChoices = [
  {
    id: "OPENAI_ASSISTANT",
    name: "OpenAI Assistant",
  },
  {
    id: "OPENAI",
    name: "OpenAI",
  },
  {
    id: "ALI",
    name: "阿里系列",
  },
];

export const ApiTypeModelChoices = [
  {
    id: "gpt-4",
    name: "GPT-4(废弃)",
  },
  {
    id: "gpt-4-turbo",
    name: "GPT-4 Turbo",
  },
  {
    id: "gpt-4-vision-preview",
    name: "GPT-4 Vision Preview",
  },
  {
    id: "gpt-3.5-turbo",
    name: "GPT-3.5 Turbo",
  },
  {
    id: "gpt-3.5-turbo-instruct(一般不要用)",
    name: "GPT-3.5 Turbo Instruct",
  },
  {
    id: "gpt-3.5-turbo-16k",
    name: "GPT-3.5 Turbo 16k",
  },
  {
    id: "qwen-turbo",
    name: "通义千问6K",
  },
  {
    id: "qwen-plus",
    name: "通义千问增强30K",
  },
  {
    id: "qwen-max",
    name: "通义千问MAX8K(贵)",
  },
];

// qwen-turbo
//
// qwen-plus
//
// qwen-max
