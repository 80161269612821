import { useCallback, useState } from "react";
import { ChatApi } from "../../../api/ChatApi";
import { CustomerFriend } from "../../../model/chatData";
import { useChatHistory } from "./ChatDataContext";

export function useSendMessage(friend?: CustomerFriend | null) {
  const [sending, setSending] = useState(false);
  const { refetch } = useChatHistory();

  const handleSend = useCallback(
    async (message: string) => {
      setSending(true);
      if (friend) {
        try {
          await ChatApi.sendMsg({
            msgType: 1,
            sendAccount: friend.account || "",
            receiveAccount: friend.friendAccount,
            msgContent: message,
            translateText: "",
          });
        } finally {
          setSending(false);
        }
      }
    },
    [friend]
  );

  let send = async function (message: string, handleClose: () => void) {
    try {
      await handleSend(message);
    } finally {
      await refetch();
      handleClose();
    }
  };
  return {
    send,
    sending,
  };
}
