import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";
import { Admin } from "../model/Admin";
import { User, UserTypeTimeType } from "../model/User";

export const UserManagerApi = {
  unlock: async (id: string): Promise<User> => {
    const { data } = await fetchJson(`${API_URL}/user/unlock/${id}`, {
      method: "POST",
    });
    return data;
  },
  upgrade: async (id: string, toUserTypeId: string): Promise<User> => {
    const { data } = await fetchJson(
      `${API_URL}/user/upgrade/${id}/${toUserTypeId}`,
      {
        method: "POST",
      }
    );
    return data;
  },
  open: async (
    id: string,
    userTypeId: string,
    userTypeTimeType: UserTypeTimeType,
    number: number
  ): Promise<User> => {
    const { data } = await fetchJson(
      `${API_URL}/user/open/${id}?userTypeId=${userTypeId}&userTypeTimeType=${userTypeTimeType}&number=${number}`,
      {
        method: "POST",
      }
    );
    return data;
  },
  handDeduct: async (userId: string, amount: number): Promise<User> => {
    const { data } = await fetchJson(`${API_URL}/user/amount/handDeduct`, {
      method: "POST",
      body: new URLSearchParams({
        userId,
        amount: amount.toString(),
      }),
    });
    return data;
  },
  handPay: async (userId: string, amount: number): Promise<User> => {
    const { data } = await fetchJson(`${API_URL}/user/amount/handPay`, {
      method: "POST",
      body: new URLSearchParams({
        userId,
        amount: amount.toString(),
      }),
    });
    return data;
  },
};
