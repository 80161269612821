import {
  CreateButton,
  DeleteButton,
  EditButton,
  ExportButton,
  FilterButton,
  RefreshButton,
  TopToolbar,
} from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import GroupIcon from "@mui/icons-material/Group";
import {
  DefaultCommands,
  ResourceType,
} from "../../components/core/MyResource";
import UploadFriendBtn from "./commands/UploadFriendBtn";
import { UploadMenuButton } from "../sys/UploadMenuButton";

const fields: MyField[] = [
  Field.objectId({}),
  Field.text({
    label: "名称",
    source: "name",
  }),
  Field.text({
    label: "备注",
    source: "remarks",
    isFilter: false,
  }),
  Field.editNumber({
    label: "上传总数",
    source: "totalCount",
    isCreate: false,
    isUpdate: false,
    isEdit: false,
    isFilter: false,
  }),
  Field.editNumber({
    label: "去重后数量",
    source: "validCount",
    isCreate: false,
    isUpdate: false,
    isEdit: false,
    isFilter: false,
  }),
  Field.editNumber({
    label: "账号未找到",
    source: "notFoundCount",
    isCreate: false,
    isUpdate: false,
    isEdit: false,
    isFilter: false,
  }),
  Field.textArray({
    label: "好友列表",
    source: "friendPhoneList",
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource: ResourceType = {
  commands: [...DefaultCommands],
  list: () => {
    return (
      <MyList
        fields={fields}
        actions={
          <>
            <TopToolbar>
              <FilterButton />
              <UploadFriendBtn />
              <CreateButton />
              <ExportButton />
              <RefreshButton />
            </TopToolbar>
          </>
        }
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <GroupIcon />,
};

export default resource;
