import { TgAccountInfo } from "./TgAccountInfo";
import { Badge, Tab, TabProps, Tooltip } from "@mui/material";
import React, { forwardRef, useEffect, useState } from "react";
import { Download } from "@mui/icons-material";
import { TgAccount } from "../../../model/tgProtocolChatData";
import { styled } from "@mui/material/styles";
import { useTgUnReadFriend } from "../hook/ChatDataContext";

export type TgAccountTabProps = TabProps & {
  account?: TgAccount;
  refetch: () => Promise<any>;
  children?: React.ReactNode;
};

const TgTab = styled((props: TabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({}));

export const TgAccountTab = forwardRef<HTMLElement, TgAccountTabProps>(
  ({ account, refetch, ...tabProps }, ref) => {
    const unReadFriend = useTgUnReadFriend();

    const [isRead, setIsRead] = useState(true);

    useEffect(() => {
      if (account && unReadFriend) {
        setIsRead(
          unReadFriend.list?.find((d) => d.userId === account?.id) === undefined
        );
      }
    }, [account, unReadFriend]);

    return (
      <Tooltip
        disableHoverListener={!account}
        title={
          account ? (
            <TgAccountInfo account={account} refetch={refetch} />
          ) : (
            <></>
          )
        }
        arrow
        ref={ref}
        placement={"right"}
        style={{
          backgroundColor: "transparent",
        }}
      >
        <Badge
          invisible={isRead}
          color={"error"}
          overlap="circular"
          badgeContent=" "
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          variant="dot"
        >
          <Badge
            invisible={account?.isTop !== 2}
            badgeContent={
              <Download
                color={"primary"}
                sx={{
                  height: "12px",
                  width: "12px",
                  transform: "translate(10px, -3px)",
                  rotate: "180deg",
                }}
              />
            }
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Tab
              sx={
                {
                  // "&.MuiTab-root": {
                  //   bgcolor:
                  //     !account || account?.status !== 2 ? "#c2c2c2" : "#fff",
                  // },
                }
              }
              {...tabProps}
            />
          </Badge>
        </Badge>
      </Tooltip>
    );
  }
);
