import { StatusType, StatusTypeChoices } from "./Core";

export interface User {
  id: string;
  uid: number;
  name: string;
  email?: string;
  phone?: string;
  desc?: string;
  head?: string;
  parentUserId?: string;
  lock: number;
  identityLevel?: number;
  isAgent?: boolean;
  isMyUser?: boolean;
  isAccountLock?: boolean;
  status: StatusType;
  createdDate: string;
  inviteCode: string;
}

export type UserTypeTimeType = "Month" | "Day";

export const UserTypeTimeTypeChoices = [
  { id: "Month", name: "月" },
  { id: "Day", name: "天" },
];

export const UserTypeOpTypeChoices = [
  { id: "ADD", name: "开通会员" },
  { id: "UPGRADE", name: "升级会员" },
];

export function getUserTypeTimeTypeName(type: UserTypeTimeType) {
  return UserTypeTimeTypeChoices.find((item) => item.id === type)?.name;
}
