import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import {
  Edit as EditIcon,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useTgAuthStateData } from "../../hook/TgAuthStateProvider";
import { TgProtocolApi } from "../../../../api/TgProtocolApi";
import { useChatAccount } from "../../hook/ChatDataContext";

export interface CodeProps {
  onChangePhone: (state: boolean) => void;
}

function Code({ onChangePhone }: CodeProps) {
  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const pwdRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string>();
  const [code, setCode] = useState("");
  const [codeLength, setCodeLength] = useState(5);
  const { select: account } = useChatAccount();
  const authState = useTgAuthStateData();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (Boolean(authState?.login_status === 3)) {
      setLoading(false);
      setError("loginError");
    }
  }, [authState]);

  const handleBack = async () => {
    onChangePhone(true);
  };

  useEffect(() => {
    if (code && codeLength > 0 && code.length === codeLength) {
      // handleNext();
    }
  }, [code]);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleNext();
    }
  };

  const handleNext = () => {
    if (code && isValid(code)) {
      setError("");
      handleDone();
    } else {
      setError("Invalid code. Please try again.");
    }
  };

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleDone = async () => {
    setLoading(true);

    try {
      await TgProtocolApi.loginSendPwd({
        sessionName: account!!.id,
        phoneNumber: account?.phone!!,
        code: code,
        password: password,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSendCode = async () => {
    setSendLoading(true);
    try {
      await TgProtocolApi.loginSendCode(account!!.id, account?.phone!!);
    } finally {
      setSendLoading(false);
    }
  };

  const isValid = (code: string) => {
    let isBad = !code.match(/^[\d\-+\s]+$/);
    if (!isBad) {
      code = code.replace(/\D/g, "");
      if (code.length !== 5) {
        isBad = true;
      }
    }
    return !isBad;
  };

  return (
    <Stack spacing={3}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography variant="h5">
          <span>{account?.phone}</span>
          {/*{connecting && <HeaderProgress />}*/}
        </Typography>
        <IconButton aria-label="edit" onClick={handleBack} disabled={loading}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Box>
      <Typography variant="caption" style={{ width: 300 }}>
        We've sent the code to the <b>Telegram</b> app on your other device.
      </Typography>
      <FormControl className="auth-input" fullWidth variant="outlined">
        <InputLabel htmlFor="adornment-password" error={Boolean(error)}>
          LoginPassword
        </InputLabel>
        <OutlinedInput
          fullWidth
          autoFocus
          autoComplete="off"
          id="adornment-password"
          inputRef={pwdRef}
          type={showPassword ? "text" : "password"}
          disabled={loading}
          error={Boolean(error)}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        {error && (
          <FormHelperText id="password-error-text" error>
            {error}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl className="auth-input" fullWidth variant="outlined">
        <InputLabel htmlFor="code" error={Boolean(error)}>
          Code
        </InputLabel>
        <OutlinedInput
          fullWidth
          autoFocus
          autoComplete="off"
          id="code"
          inputRef={inputRef}
          type={"text"}
          value={code}
          error={Boolean(error)}
          onChange={(e) => setCode(e.target.value)}
          onKeyPress={handleKeyPress}
          endAdornment={
            <InputAdornment position="end">
              <Button disabled={sendLoading} onClick={handleSendCode}>
                发送验证码
              </Button>
            </InputAdornment>
          }
        />
        {error && (
          <FormHelperText id="password-error-text" error>
            {error}
          </FormHelperText>
        )}
      </FormControl>
      <Button variant={"contained"} onClick={handleNext}>
        确认验证码
      </Button>
    </Stack>
  );
}

export default Code;
