import * as React from "react";
import { ChangeEvent, ReactElement, useCallback, useEffect } from "react";
import { isElement } from "react-is";
import PropTypes from "prop-types";
import {
  MenuItem,
  Paper,
  TextFieldProps,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ChoicesProps,
  RaRecord,
  useChoices,
  useChoicesContext,
  useGetRecordRepresentation,
  useInput,
  useTranslate,
} from "ra-core";
import {
  CommonInputProps,
  ResettableTextField,
  ResettableTextFieldStyles,
  sanitizeInputRestProps,
  SupportCreateSuggestionOptions,
  useSupportCreateSuggestion,
} from "react-admin";
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]: {
    marginLeft: -1,
    borderLeft: "1px solid transparent",
  },
}));
/**
 *
 */
export const MyTabSelectInput = (props: MyTabSelectInputProps) => {
  const {
    choices: choicesProp,
    className,
    create,
    createLabel,
    createValue,
    defaultValue,
    disableValue = "disabled",
    emptyText = "",
    emptyValue = "",
    format,
    filter,
    helperText,
    isFetching: isFetchingProp,
    isLoading: isLoadingProp,
    label,
    margin = "dense",
    onBlur,
    onChange,
    onCreate,
    optionText,
    optionValue,
    parse,
    resource: resourceProp,
    source: sourceProp,
    translateChoice,
    validate,
    ...rest
  } = props;

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (emptyValue == null) {
      throw new Error(
        `emptyValue being set to null or undefined is not supported. Use parse to turn the empty string into null.`
      );
    }
  }, [emptyValue]);

  const {
    allChoices,
    isLoading,
    error: fetchError,
    source,
    resource,
    isFromReference,
  } = useChoicesContext({
    choices: choicesProp,
    isLoading: isLoadingProp,
    isFetching: isFetchingProp,
    resource: resourceProp,
    source: sourceProp,
  });

  if (source === undefined) {
    throw new Error(
      `If you're not wrapping the MyTabSelectInput inside a ReferenceInput, you must provide the source prop`
    );
  }

  if (!isLoading && !fetchError && allChoices === undefined) {
    throw new Error(
      `If you're not wrapping the MyTabSelectInput inside a ReferenceInput, you must provide the choices prop`
    );
  }

  const getRecordRepresentation = useGetRecordRepresentation(resource);
  const { getChoiceText, getChoiceValue, getDisableValue } = useChoices({
    optionText:
      optionText ?? (isFromReference ? getRecordRepresentation : undefined),
    optionValue,
    disableValue,
    translateChoice: translateChoice ?? !isFromReference,
  });
  const {
    field,
    fieldState,
    id,
    isRequired,
    formState: { isSubmitted },
  } = useInput({
    defaultValue,
    parse,
    format,
    onBlur,
    onChange,
    resource,
    source,
    validate,
    ...rest,
  });

  const renderMenuItemOption = useCallback(
    (choice: any) => getChoiceText(choice),
    [getChoiceText]
  );

  const handleChange = useCallback(
    async (value: String) => {
      // We might receive an event from the mui component
      // In this case, it will be the choice id
      field.onChange(value);
    },
    [field, getChoiceValue]
  );

  const {
    getCreateItem,
    handleChange: handleChangeWithCreateSupport,
    createElement,
  } = useSupportCreateSuggestion({
    create,
    createLabel,
    createValue,
    handleChange,
    onCreate,
    optionText,
  });

  const createItem = create || onCreate ? getCreateItem() : null;
  let finalChoices = allChoices;
  if (create || onCreate) {
    finalChoices = [...finalChoices, createItem];
  }

  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        border: (theme) => `1px solid ${theme.palette.divider}`,
        flexWrap: "wrap",
      }}
    >
      {" "}
      <StyledToggleButtonGroup
        exclusive
        {...field}
        size={"small"}
        onChange={(e, value) => {
          handleChange(value);
        }}
        aria-label="text alignment"
      >
        {finalChoices.map((choice: any) => {
          return (
            <ToggleButton
              key={getChoiceValue(choice)}
              value={getChoiceValue(choice)}
            >
              {renderMenuItemOption(choice)}
            </ToggleButton>
          );
        })}
      </StyledToggleButtonGroup>
    </Paper>
  );
};

MyTabSelectInput.propTypes = {
  emptyText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  emptyValue: PropTypes.any,
  choices: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.element,
  ]),
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  optionValue: PropTypes.string,
  disableValue: PropTypes.string,
  resettable: PropTypes.bool,
  resource: PropTypes.string,
  source: PropTypes.string,
  translateChoice: PropTypes.bool,
};

const sanitizeRestProps = ({
  afterSubmit,
  allowNull,
  beforeSubmit,
  choices,
  className,
  crudGetMatching,
  crudGetOne,
  data,
  field,
  fieldState,
  formState,
  filter,
  filterToQuery,
  formatOnBlur,
  isEqual,
  limitChoicesToValue,
  multiple,
  name,
  pagination,
  perPage,
  ref,
  reference,
  refetch,
  render,
  setFilter,
  setPagination,
  setSort,
  shouldUnregister,
  sort,
  subscription,
  type,
  validateFields,
  validation,
  value,
  ...rest
}: any) => sanitizeInputRestProps(rest);

const PREFIX = "RaMyTabSelectInput";

const StyledResettableTextField = styled(ResettableTextField, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  ...ResettableTextFieldStyles,
  minWidth: theme.spacing(20),
  "& .MuiFilledInput-root": { paddingRight: 0 },
}));

export type MyTabSelectInputProps = Omit<CommonInputProps, "source"> &
  ChoicesProps &
  Omit<SupportCreateSuggestionOptions, "handleChange"> &
  Omit<TextFieldProps, "label" | "helperText" | "classes" | "onChange"> & {
    disableValue?: string;
    emptyText?: string | ReactElement;
    emptyValue?: any;
    resettable?: boolean;
    // Source is optional as AutocompleteInput can be used inside a ReferenceInput that already defines the source
    source?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement> | RaRecord) => void;
  };
