import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, ButtonGroup, Popover, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTgSendMessage } from "../hook/useTgSendMessage";
import { User } from "../../../api/TgProtocolApi";

const EditMessage = ({
  children,
  friend,
  msg,
}: {
  children: React.ReactNode;
  friend: User;
  msg: string;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [message, setMessage] = useState(msg);

  useEffect(() => {
    setMessage(msg);
  }, [msg]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "edit-message-popover" : undefined;

  const { send, sending } = useTgSendMessage(friend);

  const handleSend = useCallback(() => {
    send(message, handleClose);
  }, [message, send]);

  return (
    <Box>
      <Box onClick={handleClick} sx={{ display: "inline-block" }}>
        {children}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            p: 1,
            width: 500,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
            position: "relative",
          }}
        >
          <TextField
            fullWidth
            multiline
            minRows={2}
            maxRows={3}
            variant="outlined"
            placeholder="Type a message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <ButtonGroup
            sx={{ width: "120px" }}
            variant="outlined"
            orientation="vertical"
            size={"small"}
          >
            <LoadingButton
              loading={sending}
              color="primary"
              variant="contained"
              onClick={handleSend}
            >
              发送
            </LoadingButton>
            <Button color="secondary" onClick={handleClose}>
              关闭
            </Button>
          </ButtonGroup>
        </Box>
      </Popover>
    </Box>
  );
};

export default EditMessage;
