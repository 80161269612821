import Box from "@mui/material/Box";
import { Backdrop, CircularProgress, Tabs } from "@mui/material";
import React, { PropsWithChildren, useState } from "react";
import { useChatAccount } from "./hook/ChatDataContext";
import { TgAccountSearchBtn } from "./compoents/TgAccountSearchBtn";
import { TgAccountTab } from "./compoents/TgAccountTab";
import { AddTgAccountBtn } from "./compoents/AddAccountBtn";
import TgChatMainContent from "./TgChatMainContent";
import TgAvatar from "./compoents/TgAvatar";

export const ChatContent = () => {
  const { selectIndex, list, changeSelect, refetch } = useChatAccount();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    changeSelect(newValue);
  };

  return (
    <ChatContentContainer>
      <AccountChatTabsContainer>
        <AddTgAccountBtn />
        <TgAccountSearchBtn />
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={selectIndex}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
          scrollButtons
        >
          {list && [
            <TgAccountTab refetch={refetch} key={0} label={"未读"} disabled />,
            ...list.map((item, index: number) => (
              <TgAccountTab
                account={item}
                refetch={refetch}
                key={index + 1}
                label={item.nickname}
                icon={
                  <TgAvatar head={item.headUrl} firstName={item.nickname} />
                }
              />
            )),
          ]}
        </Tabs>
      </AccountChatTabsContainer>
      <Box
        sx={{
          flex: 1,
          height: "calc(100vh - (41px + 48px))",
        }}
      >
        <TgChatMainContent />
      </Box>
    </ChatContentContainer>
  );
};

const ChatContentContainer = ({ children }: PropsWithChildren) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          border: "1px solid #ccc",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {children}
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        Server Connecting <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

const AccountChatTabsContainer = ({ children }: PropsWithChildren) => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        bgcolor: "background.paper",
        height: "calc(100vh - (41px + 48px))",
        "& .MuiTab-root": {
          boxSizing: "border-box",
          width: "64px",
          minWidth: "64px",
          margin: "4px",
          height: "64px",
          minHeight: "64px",
          border: "1px solid #ccc",
          borderRadius: "20px",
          fontSize: "12px",
          padding: "4px",
        },
        "& .MuiTouchRipple-root": {},
        "& .Mui-selected": {
          backgroundColor: "#fff",
          border: `2px solid ${theme.palette.primary.main}`,
        },
        "& .MuiTabs-indicator": {
          // display: "none",
        },
      })}
    >
      {children}
    </Box>
  );
};
