import type { ChangeEvent } from "react";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import VideoIcon from "@mui/icons-material/VideoCall";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { LinearProgress } from "@mui/material";
import { Result } from "../utils/request";
import { useUpload } from "../hook/useUpload";
import { useNotify } from "react-admin";

export interface VideoUploadProps {
  id: string;
  onSuccess?: (result?: string[]) => void;
  path?: string;
  defaultVideo?: string;
}

export function VideoUpload(props: VideoUploadProps) {
  const { id, onSuccess, defaultVideo } = props;
  const [previewVideo, setPreviewVideo] = useState<string | null>(
    defaultVideo || null
  );
  const [cancelUpload, setCancelUpload] = useState<(() => void) | null>(null);
  const notify = useNotify();
  useEffect(() => {
    return () => {
      // 在组件卸载时取消请求
      cancelUpload?.();
    };
  }, [cancelUpload]);
  const handleError = (error: Error) => {
    notify(error.message, { autoHideDuration: 5000, type: "error" });
    setPreviewVideo(null);
  };

  function getOnSuccess(result: Result<string[] | undefined>) {
    onSuccess?.(result?.data);
  }

  const { progress, uploadFile } = useUpload<string[]>({
    onError: handleError,
    onSuccess: (result: Result<string[] | undefined>) => getOnSuccess(result),
  });

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file) {
      setPreviewVideo(null);
      return;
    }

    // 检查文件类型是否为图片
    if (!file.type.startsWith("video/")) {
      notify("请上传视频", { autoHideDuration: 5000, type: "error" });
      return;
    }

    // 检查文件大小是否超过 1MB
    if (file.size > 10 * 1024 * 1024) {
      notify("视频不能大于10MB", { autoHideDuration: 5000, type: "error" });
      return;
    }

    // 创建一个 FileReader 对象来读取上传的图片
    const reader = new FileReader();
    reader.onloadend = () => {
      // 将读取到的图片数据设置为预览图片的 URL
      setPreviewVideo(reader.result as string);
    };
    reader.readAsDataURL(file);

    //path
    const cancel = await uploadFile(file, "/api/docFile/upload");
    setCancelUpload(() => cancel);
  };

  return (
    <Box>
      <input
        accept="video/*" // 限制文件类型为 PNG
        style={{ display: "none" }}
        id={id}
        type="file"
        onChange={handleFileUpload}
      />
      <label htmlFor={id}>
        <Button component="span" variant="outlined" startIcon={<VideoIcon />}>
          上传
        </Button>
      </label>
      <Box margin={"8px 0"}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      {previewVideo && (
        <Card variant="outlined" sx={{ padding: "8px", margin: "8px 0" }}>
          <video style={{ maxWidth: "100%" }} controls>
            <source src={previewVideo} />
          </video>
        </Card>
      )}
    </Box>
  );
}
