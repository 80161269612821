import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";

export const TgFriendGroupApi = {
  uploadFriend: async (file: File): Promise<Boolean> => {
    // TypeError: requestHeaders.append is not a function
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    let { data } = await fetchJson(`${API_URL}/tgFriendGroup/uploadFriend`, {
      method: "POST",
      body: formData,
    });
    return data;
  },
};
