import { ChatAccountCustomer, CustomerFriend } from "../../../model/chatData";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { LoadingButton } from "@mui/lab";
import { useAdmin } from "../../../hook/useCurrentAdmin";
import AdminAssistant from "./AdminAssistant";

export const ChatHeader = ({
  account,
  friend,
}: {
  account: ChatAccountCustomer;
  friend: CustomerFriend;
}) => {
  return (
    <Box
      sx={{
        padding: "8px",
        borderBottom: "1px solid #ddd",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <Typography variant="body2" flex={1}>
        {friend.friendAccount} {friend.friendName && <>{friend.friendName}</>}
      </Typography>
      <Stack direction={"row"} spacing={1}>
        <LoadingButton
          variant={"contained"}
          // loading={translateIng}
          // onClick={() => translateItem()}
          size={"small"}
          sx={{
            p: "6px 8px",
            lineHeight: "1.2",
            fontSize: "12px",
            minWidth: "min-content",
          }}
        >
          重新翻译全部
        </LoadingButton>
        <AdminAssistant />
      </Stack>
    </Box>
  );
};
