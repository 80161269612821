import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";

export const TgTdataGroupApi = {
  uploadFile: async (file: File): Promise<Boolean> => {
    // TypeError: requestHeaders.append is not a function
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    let { data } = await fetchJson(`${API_URL}/tgTdataGroup/uploadFile`, {
      method: "POST",
      body: formData,
    });
    return data;
  },
  startTrans: async (id: string): Promise<Boolean> => {
    let { data } = await fetchJson(`${API_URL}/tgTdataGroup/startTrans/${id}`, {
      method: "POST",
    });
    return data;
  },
  stopTrans: async (id: string): Promise<Boolean> => {
    let { data } = await fetchJson(`${API_URL}/tgTdataGroup/stopTrans/${id}`, {
      method: "POST",
    });
    return data;
  },
};
