import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
} from "@mui/material";
import React, { FormEvent, useState } from "react";
import { ChatUpBookForm } from "../../../model/chatData";
import { useChatAccount, useChatFriend } from "../hook/ChatDataContext";
import { ChatApi } from "../../../api/ChatApi";

export const UpBookBtn = () => {
  const [open, setOpen] = React.useState(false);

  const chatAccount = useChatAccount();

  const defaultFormData = {
    account: chatAccount.select?.account || "",
    group_id: "",
    phones: [],
    ptype: 2,
    up_num: 0,
  };

  const [formData, setFormData] = useState<ChatUpBookForm>(defaultFormData);

  const openDialog = () => {
    setFormData(defaultFormData);
    setOpen(true);
  };

  const { refetch } = useChatFriend();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const filteredPhones = formData.phones?.filter((p) => !p && p.length > 0);
    await setFormData({
      ...formData,
      phones: filteredPhones,
    });
    await ChatApi.doChatUpBook(formData);

    refetch?.();
    handleClose();
  };

  const handleClose = async () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        sx={{ minWidth: "200px" }}
        onClick={openDialog}
      >
        上传通讯录
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Stack spacing={2}>
              <TextField
                label="加粉数据"
                variant="outlined"
                multiline
                rows={5}
                defaultValue={formData.phones?.join("\n")}
                onChange={(e) => {
                  console.log("加粉数据 c: ", e.target.value, formData.phones);
                  setFormData({
                    ...formData,
                    phones: e.target.value.split("\n"),
                  });
                }}
                helperText={"* 每行一个手机号,"}
              />
              <TextField
                type={"number"}
                label="粉丝数量"
                defaultValue={formData.up_num}
                variant="outlined"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    up_num: Number(e.target.value),
                  });
                }}
              />
              <Box color={"text.secondary"}>
                单次最多100条,建议单号每天添加次数不要超过100
              </Box>
              <Box color={"text.secondary"}>
                单次最多100条,建议单号每天添加次数不要超过100
              </Box>
              <Box color={"text.secondary"}>
                建议单号每天上传通讯录次数不要超过4次,多次操作可能会导致上传通讯录失败。请确保数据是否已开通，否则成功上传后并不会显示在列表
              </Box>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button type="submit">添加</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
