import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { ChatMessage } from "./ChatMessage";
import { TgAccount } from "../../../model/tgProtocolChatData";
import { User, Message, MessagePageResp } from "../../../api/TgProtocolApi";

export const TgChatHistory = ({
  messages,
  account,
  friend,
  onDelMsg,
}: {
  messages?: Message[];
  account: TgAccount;
  friend: User;
  onDelMsg: (message: Message) => void;
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [friend?.id]);

  return (
    <Box sx={{ flex: 1, overflowY: "auto", padding: 2, height: "100%" }}>
      {messages &&
        messages.map((message, i) => (
          <ChatMessage
            key={message.id}
            message={message}
            account={account}
            friend={friend}
            onDelMsg={onDelMsg}
          />
        ))}
      <div ref={messagesEndRef} />
    </Box>
  );
};
