import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
} from "@mui/material";
import React, { FormEvent, useState } from "react";
import { useChatAccount } from "../hook/ChatDataContext";
import { TgProtocolApi } from "../../../api/TgProtocolApi";
import { LoadingButton } from "@mui/lab";

export const AddTgAccountBtn = () => {
  const [open, setOpen] = React.useState(false);
  const [phone, setPhone] = useState<string>("");
  const [error, setError] = React.useState<string>();
  const { refetch } = useChatAccount();
  const [loading, setLoading] = useState(false);

  const addTgAccount = async () => {
    setLoading(true);
    try {
      let cleanedPhone = phone.replace(/[\s()（）+-]/g, "");
      if (!cleanedPhone && cleanedPhone.length === 0) {
        setError("手机号不能为空");
        setLoading(false);
        return;
      }
      const tgAccount = await TgProtocolApi.create(phone);
    } finally {
      setLoading(false);
    }
  };

  const openDialog = () => {
    setPhone("");
    setOpen(true);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await addTgAccount();
    await refetch?.();
    handleClose();
  };

  const handleClose = async () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="outlined" sx={{ minWidth: "68px" }} onClick={openDialog}>
        新增
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Stack spacing={2}>
              <TextField
                label="手机号"
                variant="outlined"
                rows={5}
                defaultValue={phone}
                value={phone}
                onChange={(e) => {
                  setError(undefined);
                  setPhone(e.target.value);
                }}
                error={Boolean(error)}
                helperText={
                  Boolean(error)
                    ? error
                    : "* 手机号要携带区号, 不要任何括号和空格"
                }
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <LoadingButton loading={loading} type="submit">
              添加
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
