import { Box } from "@mui/system";
import Phone from "./auth/Phone";
import { useEffect, useState } from "react";
import {
  TgAuthStateProvider,
  useTgAuthStateData,
} from "../hook/TgAuthStateProvider";
import { TelegramLogo } from "../../../icons/TelegramLogo";
import * as React from "react";
import { Stack } from "@mui/material";
import Code from "./auth/Code";
import { useChatAccount } from "../hook/ChatDataContext";
import NeedRecon from "./NeedRecon";

export interface AuthPageProps {}

function AuthPage({}: AuthPageProps) {
  return (
    <TgAuthStateProvider>
      <InnerPage />
    </TgAuthStateProvider>
  );
}

function InnerPage() {
  // 判断显示哪个页面
  const [page, setPage] = useState<React.ReactNode>();
  const authState = useTgAuthStateData();
  const { refetch } = useChatAccount();
  const [changePhone, setChangePhone] = useState(false);

  useEffect(() => {
    if (authState) {
      if (changePhone) {
        setPage(<Phone onChangePhone={(state) => setChangePhone(state)} />);
        return;
      }
      switch (authState.login_status) {
        case 1:
          setPage(<Phone onChangePhone={(state) => setChangePhone(state)} />);
          break;
        case 2:
          console.log("AuthPageTypes.StateReady login ok");
          refetch();
          break;
        case 3:
          setPage(<NeedRecon />);
          break;
        case 4:
          break;
        case 5:
          setPage(<Code onChangePhone={(state) => setChangePhone(state)} />);
          break;
        default:
          console.log("unkown auth state :", authState);
      }
    } else {
      setPage(<Box>no select account</Box>);
    }
  }, [authState?.login_status, changePhone]);

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      marginTop={"80px"}
    >
      <Stack spacing={3} maxWidth={"500px"}>
        <Box
          margin={"40px 100px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <TelegramLogo />
        </Box>
        {page}
      </Stack>
    </Box>
  );
}

export default AuthPage;
