import { Avatar, Box } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import React from "react";

export interface MyAvatarProps {
  head?: string;
  sx?: object; // 添加 sx 属性
}

export const MyAvatar = ({ head, sx }: MyAvatarProps) => {
  return (
    <Avatar variant="rounded" sx={sx}>
      {head ? (
        <Box
          component="img"
          sx={{ width: "40px", height: "auto" }}
          src={head}
          alt="avatar"
        />
      ) : (
        <ImageIcon />
      )}
    </Avatar>
  );
};
