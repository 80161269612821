import { useCallback, useState } from "react";
import { User, TgProtocolApi } from "../../../api/TgProtocolApi";
import { useTgChatHistory } from "./TgAccountDataContext";
import { useChatAccount } from "./ChatDataContext";

export function useTgSendMessage(friend?: User | null) {
  const [sending, setSending] = useState(false);
  const { refetch } = useTgChatHistory();
  const { select: account } = useChatAccount();

  const handleSend = useCallback(
    async (message: string) => {
      setSending(true);
      if (friend) {
        try {
          await TgProtocolApi.messageSend({
            clientId: account?.id!!,
            receiver: `${friend?.oid}`,
            message: message,
            msgType: "1",
          });
        } finally {
          setSending(false);
        }
      }
    },
    [friend]
  );

  let send = async function (message: string, handleClose: () => void) {
    try {
      await handleSend(message);
    } finally {
      await refetch();
      handleClose();
    }
  };
  return {
    send,
    sending,
  };
}
