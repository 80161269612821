import React, { useCallback, useRef, useState } from "react";
import { Box, ButtonGroup, Tab, Tabs } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AskList from "./AskList";
import { useChatAccount } from "../hook/ChatDataContext";
import {
  useTgChatFriend,
  useTgChatHistory,
} from "../hook/TgAccountDataContext";

function TabPanel(props: {
  children?: React.ReactNode;
  index: number;
  value: number;
}) {
  const { children, value, index, ...other } = props;

  return (
    <Box role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </Box>
  );
}

export interface ToolPanelProps {}
export function ToolPanel(props: ToolPanelProps) {
  const { list: history, methods } = useTgChatHistory();

  const { select: account } = useChatAccount();
  const { select: friend } = useTgChatFriend();
  const [value, setValue] = useState(0);

  const askListRef = useRef<{ doReAnswer: () => Promise<void> }>(null);
  const [reOptimizeing, setReOptimizeing] = useState(false);

  const handleReAnswer = useCallback(async () => {
    try {
      setReOptimizeing(true);
      await askListRef.current?.doReAnswer();
    } finally {
      setReOptimizeing(false);
    }
  }, [askListRef]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  if (!account || !friend) {
    return <></>;
  }

  const tabs = [
    {
      label: "生成回答",
      content: (
        <Box className="tool-panel">
          <AskList
            ref={askListRef}
            friend={friend}
            history={history}
            account={account}
          />
        </Box>
      ),
    },
    {
      label: "预定回答",
      content: <div className="tool-panel"></div>,
    },
  ];

  return (
    <Box sx={{ width: "250px" }}>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: "white",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tool panel tabs"
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              id={`simple-tab-${index}`}
              aria-controls={`simple-tabpanel-${index}`}
            />
          ))}
        </Tabs>
      </Box>
      <Box
        sx={{
          width: "250px",
          height: "calc(100vh - (41px + 48px + 60px + 40px))",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {tabs.map((tab, index) => (
          <TabPanel key={index} value={value} index={index}>
            {tab.content}
          </TabPanel>
        ))}
      </Box>
      <Box
        p={1}
        textAlign={"center"}
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: "white",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <ButtonGroup variant="contained" size="small">
          <LoadingButton onClick={handleReAnswer} loading={reOptimizeing}>
            重新生成
          </LoadingButton>
        </ButtonGroup>
      </Box>
    </Box>
  );
}
