import { ExportButton, RefreshButton, TopToolbar } from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyEdit from "../../components/core/MyEdit";
import GroupIcon from "@mui/icons-material/Group";
import { ResourceType } from "../../components/core/MyResource";
import { TransStateTypeChoices } from "../../model/TgTdataGroupModel";
import UploadTdataGroupBtn from "./commands/UploadTdataGroupBtn";
import { StartTransCommand } from "./commands/StartTransCommand";
import { ShowCommand } from "../../components/command/ShowCommand";
import { EditCommand } from "../../components/command/EditCommand";
import { DeleteCommand } from "../../components/command/DeleteCommand";
import TgTdataGroupShow from "./components/TgTdataGroupShow";

const fields: MyField[] = [
  Field.objectId({}),
  Field.text({
    label: "名称",
    source: "filename",
  }),
  Field.text({
    label: "备注",
    source: "remarks",
    isFilter: false,
  }),
  Field.select({
    label: "转换状态",
    source: "transState",
    choices: TransStateTypeChoices,
    isCreate: false,
    isUpdate: false,
  }),
  Field.editNumber({
    label: "上传总数",
    source: "accountCount",
    isCreate: false,
    isUpdate: false,
    isEdit: false,
    isFilter: false,
  }),
  Field.editNumber({
    label: "转换成功",
    source: "transOk",
    isCreate: false,
    isUpdate: false,
    isEdit: false,
    isFilter: false,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource: ResourceType = {
  commands: [
    <StartTransCommand />,
    <ShowCommand />,
    <EditCommand />,
    <DeleteCommand />,
  ],
  show: () => {
    return <TgTdataGroupShow fields={fields} />;
  },
  list: () => {
    return (
      <MyList
        pagination={false}
        fields={fields}
        actions={
          <>
            <TopToolbar>
              <UploadTdataGroupBtn />
              <ExportButton />
              <RefreshButton />
            </TopToolbar>
          </>
        }
      />
    );
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <GroupIcon />,
};

export default resource;
