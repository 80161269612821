import { Button, Stack, TextField, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { PhoneCountry } from "../../../../utils/PhoneUtil";
import { FilterOptionsState } from "@mui/base/useAutocomplete/useAutocomplete";
import { useTgAuthStateData } from "../../hook/TgAuthStateProvider";
import { TgProtocolApi } from "../../../../api/TgProtocolApi";
import { useChatAccount } from "../../hook/ChatDataContext";

export interface PhoneProps {
  onChangePhone: (state: boolean) => void;
}

function Phone({ onChangePhone }: PhoneProps) {
  const { select: account } = useChatAccount();
  const [phoneNumber, setPhoneNumber] = useState(account?.phone || "");
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState<PhoneCountry | null>(null);
  const [countryData, setCountryData] = useState<PhoneCountry[]>();
  const phoneInputRef = useRef<HTMLInputElement>(null);
  const [errorString, setErrorString] = useState<string>();
  const authState = useTgAuthStateData();

  useEffect(() => {
    if (account) {
      setPhoneNumber(account?.phone || "");
    }
  }, [account]);

  useEffect(() => {
    if (Boolean(authState?.login_status !== 1)) {
      setLoading(false);
    }
  }, [authState?.login_status]);

  const handleFilterOptions = (
    options: PhoneCountry[],
    { inputValue }: FilterOptionsState<PhoneCountry>
  ) => {
    if (!options) return [];

    let value = inputValue.toLowerCase().replace(/ /g, "");
    value = value.length > 0 && value[0] === "+" ? value.substring(1) : value;

    return options.filter((x) => isValidOption(x, value));
  };

  const handleCountryChange = (
    event?: React.SyntheticEvent,
    nextCountry?: PhoneCountry | null
  ) => {
    if (!nextCountry) return;

    const prevPhone =
      (country ? phoneNumber.replace(country.phone!!, "") : phoneNumber) || "";
    const nextPhone = nextCountry.phone + " " + prevPhone.trimStart();

    setCountry(nextCountry);
    setPhoneNumber(nextPhone);
    phoneInputRef.current?.focus();
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let nextPhone = event.target.value;
    setPhoneNumber(nextPhone);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleDone();
    } else if (!isWhitelistKey(event.key)) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handleDone = async () => {
    if (!isValidPhoneNumber(phoneNumber)) {
      setErrorString("InvalidPhoneNumber");
      return;
    }

    setErrorString(undefined);
    setLoading(true);

    await TgProtocolApi.loginSendCode(
      account!!.id,
      phoneNumber.replaceAll("+", "").replaceAll(" ", "")
    );
    onChangePhone(false);
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const plainText = event.clipboardData.getData("text/plain");
    if (plainText) {
      event.preventDefault();
      // const phoneRegExp = '/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g';

      let phone = "";

      for (let ch of plainText) {
        if (isWhitelistKey(ch)) {
          phone += ch;
        }
      }

      setPhoneNumber(phone);
    }
  };

  return (
    <Stack
      spacing={3}
      width={"fit-content"}
      minWidth={"360px"}
      minHeight={"80%"}
      alignItems={"center"}
    >
      <Typography variant="h4">
        <span>Sign in to Telegram</span>
        {/*{connecting && <HeaderProgress />}*/}
      </Typography>
      <Typography variant="caption" style={{ width: 264 }}>
        Please confirm your country code and enter your phone number.
      </Typography>
      <TextField
        id="phoneNumber"
        classes={{ root: "auth-input" }}
        inputRef={phoneInputRef}
        variant="outlined"
        color="primary"
        label={"Phone number"}
        disabled={loading}
        error={Boolean(errorString)}
        helperText={errorString}
        fullWidth
        autoFocus
        autoComplete="off"
        value={phoneNumber}
        onChange={handlePhoneChange}
        onKeyPress={handleKeyPress}
        onPaste={handlePaste}
      />
      <Button
        classes={{ root: "auth-button" }}
        variant="contained"
        disableElevation
        fullWidth
        color="primary"
        disabled={loading}
        onClick={handleDone}
      >
        Next
      </Button>
      {/*<Typography className="sign-in-continue-on">*/}
      {/*  <Link onClick={onRequestQrCode}>Quick log in using QR code</Link>*/}
      {/*</Typography>*/}
    </Stack>
  );
}

function phoneEquals(phone1: string, phone2?: string) {
  return clearPhone(phone1) === clearPhone(phone2);
}

function isPhoneWithOptionCode(phone: string, option: PhoneCountry) {
  if (!phone) return false;
  if (!option) return false;

  phone = clearPhone(phone);
  const code = clearPhone(option.phone);

  return phone.startsWith(code);
}

function getCountryFromPhone(phone: string, data?: PhoneCountry[]) {
  if (!data) return null;

  const index = data.findIndex((x) => isPhoneWithOptionCode(phone, x));

  return index !== -1 ? data[index] : null;
}

function clearPhone(phone?: string) {
  if (!phone) return "";

  return phone?.replace(/ /g, "").replace("+", "").toLowerCase();
}

function isValidOption(x: PhoneCountry, value?: string) {
  if (!x) return false;
  if (!value) return true;

  if (value.length > 0 && value[0] === "(") {
    value = value.substring(1);
  }

  const names = x.name.toLowerCase().split(" ");
  for (let i = 0; i < names.length; i++) {
    if (names[i].length > 0 && names[i][0] === "(") {
      names[i] = names[i].substring(1);
    }
  }
  const phone = clearPhone(x.phone);

  if (names.some((x) => x.startsWith(value!!))) return true;
  if (phone!!.startsWith(value) || value.startsWith(phone!!)) return true;

  return false;
}

function isValidPhoneNumber(phoneNumber: string) {
  if (!phoneNumber) return false;

  let isBad = !phoneNumber.match(/^[\d\-+\s]+$/);
  if (!isBad) {
    phoneNumber = phoneNumber.replace(/\D/g, "");
    if (phoneNumber.length < 7) {
      isBad = true;
    }
  }

  return !isBad;
}

function isWhitelistKey(key: string) {
  if (key >= "0" && key <= "9") return true;
  if (key === " ") return true;
  if (key === "+") return true;

  return false;
}

export default Phone;
