import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import { MyDatagrid } from "../../components/core/MyDatagrid";
import { RiListUnordered } from "@remixicon/react";
import {
  getUserTypeTimeTypeName,
  UserTypeOpTypeChoices,
  UserTypeTimeTypeChoices,
} from "../../model/User";
import dayjs from "dayjs";

const fields: MyField<any>[] = [
  Field.objectId(),
  Field.userField(),
  // Field.select({
  //   label: "时间",
  //   source: "userTypeTimeType",
  //   isFilter: true,
  //   choices: UserTypeTimeTypeChoices,
  // }),
  Field.select({
    label: "交易类型",
    source: "opType",
    isFilter: true,
    choices: UserTypeOpTypeChoices,
  }),
  Field.functionField({
    label: "详情",
    source: "userTypeId",
    isFilter: false,
    fullWidth: true,
    render: (record?: any, source?: string) => {
      if (record.opType === "ADD") {
        return `开通: ${record.toUserTypeName}, 时长 :${
          record.number
        } ${getUserTypeTimeTypeName(record.userTypeTimeType)}`;
      }
      return `升级: ${record.srcUserTypeName} - ${record.toUserTypeName}`;
    },
  }),
  Field.dateTime({
    label: "会员结束日期",
    source: "endDateTime",
  }),
  Field.dateTime({
    label: "记录时间",
    source: "opDateTime",
  }),
];

export const UserWalletLog = () => {
  return <MyDatagrid fields={fields} rowActions={[]} />;
};

const resource = {
  list: () => {
    return <MyList fields={fields} rowActions={[]} />;
  },
  icon: <RiListUnordered />,
};

export const UserTypeOpRecord = () => {
  return <MyDatagrid fields={fields} rowActions={[]} />;
};

export default resource;
