import { DeleteButton, EditButton } from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { BooleanTypeChoices } from "../../model/Core";
import { ProxyType, ProxyTypeChoices } from "../../model/TgProxyAddressModel";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

const fields: MyField[] = [
  Field.objectId({}),
  Field.text({
    label: "ip",
    source: "host",
  }),
  Field.number({
    label: "最大使用数",
    source: "useLimit",
    isFilter: false,
    defaultValue: 5,
    isEdit: true,
    isUpdate: true,
  }),
  Field.number({
    label: "已使用",
    source: "useCount",
    isFilter: false,
    defaultValue: 0,
  }),
  Field.text({
    label: "端口",
    source: "port",
    isFilter: false,
  }),
  Field.editSelect({
    label: "代理类型",
    isEdit: false,
    choices: ProxyTypeChoices,
    source: "proxyType",
    defaultValue: ProxyType.SOCKS5,
  }),
  Field.text({
    label: "国家",
    source: "country",
    isFilter: false,
  }),
  Field.editDate({
    label: "到期时间",
    source: "endTime",
    isFilter: false,
  }),
  Field.editSelect({
    label: "是否启用",
    source: "enable",
    choices: BooleanTypeChoices,
    defaultValue: true,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        fields={fields}
        rowActions={[<EditButton />, <DeleteButton />]}
        // groupingColDef={groupingColDef}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <VpnKeyIcon />,
};

export default resource;
