import {
  ArrayFieldProps,
  ArrayInputProps,
  AutocompleteArrayInputProps,
  AutocompleteInputProps,
  BooleanFieldProps,
  BooleanInputProps,
  CheckboxGroupInputProps,
  ChipFieldProps,
  CommonInputProps,
  DatagridInputProps,
  DateFieldProps,
  DateInputProps,
  DateTimeInputProps,
  FileFieldProps,
  FileInputProps,
  FunctionFieldProps,
  ImageFieldProps,
  ImageInputProps,
  InputHelperTextProps,
  LoadingInputProps,
  NullableBooleanInputProps,
  NumberFieldProps,
  NumberInputProps,
  PasswordInputProps,
  RadioButtonGroupInputProps,
  ReferenceArrayFieldProps,
  ReferenceArrayInputProps,
  ReferenceFieldProps,
  ReferenceInputProps,
  ReferenceManyFieldProps,
  ReferenceOneFieldProps,
  RichTextFieldProps,
  SelectArrayInputProps,
  SelectFieldProps,
  SelectInputProps,
  TextFieldProps,
  TextInputProps,
  TimeInputProps,
  TranslatableFieldsProps,
  TranslatableFieldsTabContentProps,
  TranslatableFieldsTabProps,
  TranslatableInputsProps,
  TranslatableInputsTabContentProps,
  TranslatableInputsTabsProps,
  UrlFieldProps,
  WrapperFieldProps,
} from "react-admin";
import { RaRecord } from "ra-core";
import React from "react";
import { GridCellParams } from "@mui/x-data-grid/models/params/gridCellParams";

export type MyFieldProps =
  | ArrayFieldProps
  | BooleanFieldProps
  | ChipFieldProps
  | DateFieldProps
  | FileFieldProps
  | FunctionFieldProps
  | ImageFieldProps
  | NumberFieldProps
  | ReferenceArrayFieldProps
  | ReferenceFieldProps
  | ReferenceManyFieldProps
  | ReferenceOneFieldProps
  | RichTextFieldProps
  // | sanitizeFieldRestProps
  | SelectFieldProps
  | TextFieldProps
  | TranslatableFieldsProps
  | TranslatableFieldsTabProps
  | TranslatableFieldsTabContentProps
  | UrlFieldProps
  | WrapperFieldProps
  | any;

export type MyInputProps =
  | ArrayInputProps
  | AutocompleteInputProps
  | AutocompleteArrayInputProps
  | BooleanInputProps
  | CheckboxGroupInputProps
  | CommonInputProps
  | DatagridInputProps
  | DateInputProps
  | DateTimeInputProps
  | TimeInputProps
  | FileInputProps
  | ImageInputProps
  | InputHelperTextProps
  | LoadingInputProps
  | NullableBooleanInputProps
  | NumberInputProps
  | PasswordInputProps
  | RadioButtonGroupInputProps
  | ReferenceArrayInputProps
  | ReferenceInputProps
  | SelectArrayInputProps
  | SelectInputProps
  | TextInputProps
  | TranslatableInputsProps
  | TranslatableInputsTabContentProps
  | TranslatableInputsTabsProps
  | any;

export interface MyField<RecordType extends { id?: string } | RaRecord = any> {
  source?: string;
  valueSource?: string;
  label?: string;
  isFilter?: boolean;
  choices?: any[];
  isPrimary?: boolean;
  isList?: boolean;
  filterProps?: MyFieldProps;
  sortable?: boolean; // 默认false
  component?: React.FunctionComponent<any>;
  props?: MyFieldProps;
  isCreate?: boolean;
  isEdit?: boolean;
  isUpdate?: boolean;
  editComponent?: React.FunctionComponent<any>;
  editProps?: MyInputProps;
  helperText?: string;

  minWidth?: number;
  maxWidth?: number;
  fullWidth?: boolean;
  colSpan?:
    | number
    | ((params: GridCellParams<any, any, any>) => number | undefined);
}

export const ExcludeProps = ["fullWidth", "minWidth", "maxWidth", "helperText"];
