import { CommandProps } from "../../../components/command/Command";
import {
  BaseCommand,
  BaseCommandProps,
} from "../../../components/command/BaseCommand";
import { useRecordContext } from "ra-core";
import { LinkOutlined, RefreshOutlined } from "@mui/icons-material";
import React from "react";
import { useConfirm } from "../../../hook/ConfirmContext";
import { useNotify } from "react-admin";
import { TgProtocolApi } from "../../../api/TgProtocolApi";

export interface TgAcStateCheckCommandProps extends CommandProps {}

TgAcStateCheckCommand.defaultProps = {
  enableTool: true,
  enableRow: false,
  permission: "update",
} as BaseCommandProps;

export function TgAcStateCheckCommand(props: TgAcStateCheckCommandProps) {
  const { ...rest } = props;
  const record = useRecordContext(props);
  const confirm = useConfirm();
  const notify = useNotify();
  return (
    <BaseCommand
      command={{
        isEnable: (rowData: any) => {
          return true;
        },
        label: (r) => {
          return "一键状态检测";
        },
        icon: <LinkOutlined />,
        onHandle: async () => {
          let title = "一键状态检测";
          const form = await confirm({
            title: title,
            description: <p>所有在线账号检测状态, 任务会在后台执行</p>,
          });
          if (form) {
            await TgProtocolApi.allOkStateAcGetMe();
            notify(`状态检测已开始!`);
          }
          return true;
        },
      }}
      {...rest}
    />
  );
}
