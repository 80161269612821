interface ErrorInfo {
  error: string;
  errorDate: string;
}

export const WalletOpTypeChoices = [
  { id: "ADMIN_RECHARGE", name: "后台充值" },
  { id: "ADMIN_DEDUCT", name: "后台扣除" },
  { id: "RECHARGE", name: "充值" },
  { id: "WITHDRAW", name: "提现" },
  { id: "TRANSFER_IN", name: "划入" },
  { id: "TRANSFER_OUT", name: "划出" },
  { id: "TRADE_IN", name: "交易买入" },
  { id: "TRADE_OUT", name: "交易卖出" },
  { id: "MARGIN_REVERSAL", name: "冲正" },
  { id: "TRADE_FREEZE", name: "交易冻结" },
  { id: "TRADE_THAW", name: "交易解冻" },
];

export enum WalletOpType {
  /**
   * 后台充值
   */
  ADMIN_RECHARGE = "ADMIN_RECHARGE",

  /**
   *
   */
  ADMIN_DEDUCT = "ADMIN_DEDUCT",

  /**
   * 充值
   */
  RECHARGE = "RECHARGE",

  /**
   * 提现
   */
  WITHDRAW = "WITHDRAW",

  /**
   * 划入
   */
  TRANSFER_IN = "TRANSFER_IN",

  /**
   * 划出
   */
  TRANSFER_OUT = "TRANSFER_OUT",

  /**
   * 交易买入
   */
  TRADE_IN = "TRADE_IN",

  /**
   * 交易卖出
   */
  TRADE_OUT = "TRADE_OUT",

  /**
   * 冲正
   */
  MARGIN_REVERSAL = "MARGIN_REVERSAL",

  /**
   * 交易冻结
   */
  TRADE_FREEZE = "TRADE_FREEZE",

  /**
   * 交易解冻
   */
  TRADE_THAW = "TRADE_THAW",
}

export const LogStatusTypeChoices = [
  { id: "INIT", name: "初始化" },
  { id: "NORMAL", name: "NORMAL" },
  { id: "ERROR", name: "ERROR" },
];

export enum LogStatusType {
  INIT = "INIT",
  NORMAL = "NORMAL",
  ERROR = "ERROR",
}

export interface UserWalletLogModel {
  id: string;

  logStatus: LogStatusType;

  userWalletId: string;

  userId: string;

  coinSymbol: string;

  opType: WalletOpType;

  opOrderId?: string;

  opAccountId?: string;

  opAccountType?: string;

  opAccountClientUa?: string;

  opAccountClientIp?: string;

  /**
   * 金额
   */
  amount: string;

  /**
   * 手续费
   */
  fee: string;

  feeUnit: string;

  /**
   * 交易后余额
   */
  historyBalance: string;

  /**
   * 交易后冻结余额
   */
  historyFrozenBalance: string;

  error?: string;

  errorDate?: string;

  errors?: ErrorInfo[];

  createdDate: string;
  updateDate: string;
}
