import React from "react";
import { Box } from "@mui/material";
import { Loading } from "react-admin";
import { ChatHeader } from "./ChatHeader";
import { TgChatHistory } from "./TgChatHistory";
import { ChatInput } from "./ChatInput";
import { useTgSendMessage } from "../hook/useTgSendMessage";
import {
  useTgChatFriend,
  useTgChatHistory,
} from "../hook/TgAccountDataContext";
import { Message, TgProtocolApi } from "../../../api/TgProtocolApi";
import { useChatAccount } from "../hook/ChatDataContext";

export interface ChatViewProps {}

export const TgChatView = (props: ChatViewProps) => {
  const {
    list,
    error,
    isLoading,
    isError,
    refetch,
    methods,
  } = useTgChatHistory();

  const { select: account } = useChatAccount();
  const { select: friend } = useTgChatFriend();

  const { send, sending } = useTgSendMessage(friend);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div>Error: {`${error}`}</div>;
  }

  const onDelMsg = async (message: Message) => {
    await TgProtocolApi.messageDelete(account?.id, `${message.id}`);
    await refetch();
  };
  const handleSendMessage = async (message: string) => {
    await send(message, () => {});
  };
  if (!account || !friend) {
    return <></>;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <ChatHeader account={account} friend={friend} />
      <TgChatHistory
        messages={list}
        account={account}
        friend={friend}
        onDelMsg={onDelMsg}
      />
      <ChatInput
        sending={sending}
        friend={friend}
        onSend={handleSendMessage}
        account={account}
        history={list}
      />
    </Box>
  );
};
