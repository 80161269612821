import { ChatAccountCustomer } from "../../../model/chatData";
import Box from "@mui/material/Box";
import React, { ReactNode } from "react";
import { Button, IconButton, Paper, Stack } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import CopyToClipboard from "../../../components/CopyToClipboard";
import { ChatApi } from "../../../api/ChatApi";

export interface AccountInfoProps {
  account: ChatAccountCustomer;
  refetch: () => Promise<any>;
}

function toStatus(status: number) {
  switch (status) {
    case 1:
      return "离线";
    case 2:
      return "在线";
    default:
      return "未知";
  }
}

export function AccountInfo({ account, refetch }: AccountInfoProps) {
  async function handleTop() {
    if (account?.isTop === 2) {
      await ChatApi.doChatCancelUpTop({
        account: account?.account,
        ptype: 1,
      });
    } else {
      await ChatApi.doChatUpTop({
        account: account?.account,
        ptype: 1,
      });
    }

    await refetch();
  }

  return (
    <Paper
      sx={{
        padding: 2,
        fontSize: "14px",
        lineHeight: "1.5",
        fontWeight: "400",
      }}
    >
      <Stack spacing={2}>
        <Box>
          账号: {account?.account}
          <CopyToClipboard>
            {({ copy }) => (
              <IconButton onClick={() => copy(account?.account)}>
                <ContentCopy sx={{ fontSize: 10 }} />
              </IconButton>
            )}
          </CopyToClipboard>
        </Box>
        <Box>昵称: {account?.nickName}</Box>
        <Box>状态: {toStatus(account.status)}</Box>
        <Box>
          原因: <span style={{ color: "red" }}>{account.reason}</span>
        </Box>
        <Box>ip国家: {account.ipCountry}</Box>
        <Button
          variant="outlined"
          sx={{ minWidth: "200px" }}
          onClick={handleTop}
        >
          {account?.isTop === 2 ? "取消置顶" : "置顶"}
        </Button>
      </Stack>
    </Paper>
  );
}
