import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { Divider, List, ListItemButton, ListItemText } from "@mui/material";
import { useQuery } from "react-query";
import { AskItem } from "../../../model/chatData";
import { Loading } from "react-admin";
import EditMessage from "./EditMessage";
import { TgAccount } from "../../../model/tgProtocolChatData";
import { User, Message, TgProtocolApi } from "../../../api/TgProtocolApi";

export interface AskListProps {
  account: TgAccount;
  friend: User;
  history?: Message[];
  onChange?: (friend: AskItem | null) => void; // onChange 回调函数
}

const AskList = forwardRef<
  {
    doReAnswer: () => Promise<void>;
  },
  AskListProps
>(({ history, friend, account, onChange }, ref) => {
  const [msgs, setMsgs] = useState<AskItem[] | undefined>(undefined);

  const { data, error, isLoading, isError } = useQuery(
    ["ChatAskList", account, history, friend],
    async () => {
      const optimizeMessage = await TgProtocolApi.answer({
        account: `${account?.tgUserId}`,
        accountId: `${account?.tgUserId}`,
        friendAccount: `${friend?.oid}`,
        list: history,
      });
      setMsgs(optimizeMessage);
    },
    {
      enabled: !!account?.phone && !!friend && !!history?.length,
    }
  );

  const doReAnswer = useCallback(async () => {
    const optimizeMessage = await TgProtocolApi.reAnswer({
      account: `${account?.tgUserId}`,
      accountId: `${account?.tgUserId}`,
      friendAccount: `${friend?.oid}`,
      list: history,
    });
    setMsgs(optimizeMessage);
  }, [account, history, friend]);

  useImperativeHandle(ref, () => ({
    doReAnswer,
  }));

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div>Error: {`${error}`}</div>;
  }

  const handleClick = (friend: AskItem | null) => {
    onChange?.(friend);
  };

  return (
    <List
      sx={{
        width: "240px",
        bgcolor: "background.paper",
        "& ul": { padding: 0 },
      }}
      subheader={<li />}
    >
      {(msgs || data)?.map((item, index) => (
        <React.Fragment key={index}>
          <EditMessage key={index} msg={item.answer} friend={friend}>
            <ListItemButton
              sx={{ padding: "1px 4px", textAlign: "left" }}
              onClick={() => handleClick(item)}
            >
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    fontSize: "0.875rem",
                  },
                }}
                secondaryTypographyProps={{
                  style: {
                    fontSize: "0.75rem",
                  },
                }}
                primary={item.answer}
                secondary={item.translation}
              />
            </ListItemButton>
          </EditMessage>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
});

export default AskList;
