import { WrapperField } from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import WalletIcon from "@mui/icons-material/Wallet";
import { MyDatagrid } from "../../components/core/MyDatagrid";
import {
  LogStatusTypeChoices,
  UserWalletLogModel,
  WalletOpTypeChoices,
} from "../../model/UserWalletLog";
import { UserWalletTypeChoices } from "../../model/UserWallet";

const fields: MyField<UserWalletLogModel>[] = [
  Field.objectId(
    {},
    {
      isList: false,
    }
  ),
  Field.userField(),
  Field.text(
    {
      label: "货币管理",
      source: "coinSymbol",
    },
    {
      isFilter: true,
    }
  ),
  Field.select({
    label: "状态",
    source: "logStatus",
    isFilter: true,
    choices: LogStatusTypeChoices,
  }),
  Field.select({
    label: "交易种类",
    source: "type",
    isFilter: true,
    choices: UserWalletTypeChoices,
  }),
  Field.select({
    label: "交易类型",
    source: "opType",
    isFilter: true,
    choices: WalletOpTypeChoices,
  }),
  Field.text({
    label: "订单id",
    source: "opOrderId",
  }),
  Field.amount({
    label: "金额",
    source: "amount",
  }),
  Field.amount({
    label: "手续费",
    source: "fee",
  }),
  Field.amount({
    label: "单位",
    source: "feeUnit",
  }),
  Field.amount({
    label: "交易后余额",
    source: "historyBalance",
  }),
  Field.amount({
    label: "交易后冻结余额",
    source: "historyFrozenBalance",
  }),
  Field.text(
    {
      label: "错误",
      source: "error",
    },
    {
      isFilter: false,
    }
  ),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

export const UserWalletLog = () => {
  return <MyDatagrid fields={fields} rowActions={[]} />;
};

const resource = {
  list: () => {
    return (
      <MyList
        fields={fields}
        filterDefaultValues={{
          coinSymbol: "USDT",
        }}
        rowActions={[]}
      />
    );
  },
  icon: <WalletIcon />,
};

export default resource;
