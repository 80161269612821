import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { LoadingButton } from "@mui/lab";
import AdminAssistant from "./AdminAssistant";
import { TgAccount } from "../../../model/tgProtocolChatData";
import { User } from "../../../api/TgProtocolApi";

export const ChatHeader = ({
  account,
  friend,
}: {
  account: TgAccount;
  friend: User;
}) => {
  return (
    <Box
      sx={{
        padding: "8px",
        borderBottom: "1px solid #ddd",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <Typography variant="body2" flex={1}>
        {friend?.phone} {`${friend.first_name} ${friend.last_name}`}{" "}
        {`${friend.username ?? ""}`}
      </Typography>
      <Stack direction={"row"} spacing={1}>
        <LoadingButton
          variant={"contained"}
          // loading={translateIng}
          // onClick={() => translateItem()}
          size={"small"}
          sx={{
            p: "6px 8px",
            lineHeight: "1.2",
            fontSize: "12px",
            minWidth: "min-content",
          }}
        >
          重新翻译全部
        </LoadingButton>
        <AdminAssistant />
      </Stack>
    </Box>
  );
};
