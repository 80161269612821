import React, { ReactElement, useMemo } from "react";
import {
  CreateButton,
  DeleteWithConfirmButton,
  EditButton,
  ExportButton,
  FilterButton,
  List,
  ListProps,
  RefreshButton,
  TopToolbar,
  useResourceDefinition,
} from "react-admin";
// import MobileGrid from "./MobileGrid";
import { RaRecord, SortPayload } from "ra-core";
import isUndefined from "lodash/isUndefined";
import { ExcludeProps, MyField } from "../types";
import { MyProDataGrid } from "./MyProDataGrid";
import { GridActionsCellItemProps } from "@mui/x-data-grid/components/cell/GridActionsCellItem";
import omit from "lodash/omit";
import {
  GridGroupingColDefOverride,
  GridGroupingColDefOverrideParams,
} from "@mui/x-data-grid-pro/models/gridGroupingColDefOverride";
import { GridGroupNode, GridRowParams } from "@mui/x-data-grid";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { usePermission } from "../../hook/usePermission";
import { useCommands } from "../../hook/ResourceConfigContext";

export interface MyListProp<RecordType extends RaRecord = any>
  extends Omit<ListProps<RecordType>, "children"> {
  sort?: SortPayload;
  renderActionCell?: (
    params: GridRenderCellParams<any, any, any>
  ) => React.ReactNode;
  fields: MyField<RecordType>[];
  rowActions?: React.ReactElement<GridActionsCellItemProps>[];
  getTreeDataPath?: (row: RecordType) => string[];
  isGroupExpandedByDefault?: (node: GridGroupNode) => boolean;
  groupingColDef?:
    | GridGroupingColDefOverride<RecordType>
    | ((
        params: GridGroupingColDefOverrideParams
      ) => GridGroupingColDefOverride<RecordType> | undefined | null);
  getDetailPanelContent?: (
    params: GridRowParams<RecordType>
  ) => React.ReactNode;
  perPage?: number;
  bulkActionButtons?: ReactElement | false;
}

const DEFAULT_TABLE_ACTIONS = [<EditButton />, <DeleteWithConfirmButton />];

const DEFAULT_TABLE_ACTIONS_NOT_EDIT = [<DeleteWithConfirmButton />];

const MyList = <RecordType extends RaRecord = any>(
  props: MyListProp<RecordType>
) => {
  const { hasCreate: isCreate, hasEdit: isUpdate } = useResourceDefinition();
  const commands = useCommands();
  const {
    sort,
    fields,
    renderActionCell,
    rowActions = isUpdate
      ? DEFAULT_TABLE_ACTIONS
      : DEFAULT_TABLE_ACTIONS_NOT_EDIT,
    getDetailPanelContent,
    perPage = 25,
    bulkActionButtons,
    ...rest
  } = props;

  const checkPermission = usePermission();

  const buttons = useMemo(() => {
    return commands
      ?.map((command, index) => {
        if (
          !command.props.enableTool ||
          !checkPermission(command.props.permission)
        ) {
          return undefined;
        }
        return React.cloneElement(command, {
          key: index,
          isMenu: false,
          showIcon: true,
        });
      })
      .filter((r) => r);
  }, [commands]);

  const ListActions = () => (
    <TopToolbar>
      <FilterButton />
      {buttons}
      <ExportButton />
      <RefreshButton />
    </TopToolbar>
  );

  const visitorFilters = fields
    .filter((f) => f.isFilter && f.editComponent != null)
    .map((field: MyField, index) => {
      const {
        editComponent: FieldComponent,
        source,
        label,
        filterProps,
      } = field;
      if (!FieldComponent) {
        return <></>;
      }
      return (
        <FieldComponent
          key={`${index}`}
          source={source}
          label={label}
          {...omit(filterProps, ExcludeProps)}
        />
      );
    });
  return (
    <List
      empty={false}
      filters={visitorFilters}
      sort={sort || { field: "id", order: "DESC" }}
      actions={<ListActions />}
      perPage={perPage}
      sx={{
        "& .RaList-content": {
          marginTop: "48px",
        },
      }}
      {...rest}
    >
      <MyProDataGrid
        size="small"
        autosizeColumns
        renderActionCell={renderActionCell}
        bulkActionButtons={bulkActionButtons ? bulkActionButtons : false}
        commands={commands}
        getTreeDataPath={props.getTreeDataPath}
        isGroupExpandedByDefault={props.isGroupExpandedByDefault}
        groupingColDef={props.groupingColDef}
        fields={fields.filter(
          (f) => (f.isList || isUndefined(f.isList)) && f.component
        )}
        getDetailPanelContent={getDetailPanelContent}
        rowActions={[]}
      ></MyProDataGrid>
    </List>
  );
};

export { MyList };
