import Admins from "./pages/Admin";
import LoginLogs from "./pages/LoginLog";
import Users from "./pages/User";
import Docs from "./pages/content/Doc";
import WebPages from "./pages/content/WebPage";
import DocCategories from "./pages/content/DocCategory";
import Settings from "./pages/setting/setting";
import Goods from "./pages/goods/Goods";

import SiteResource from "./pages/content/SiteResource";
import SiteNotification from "./pages/content/SiteNotification";
import UserHead from "./pages/content/UserHead";
import SysModule from "./pages/sys/SysModule";
import SysRole from "./pages/sys/SysRole";
import { MenuItem } from "./model/menu";
import Assistant from "./pages/chat/Assistant";
import GoodsOrder from "./pages/goods/GoodsOrder";
import GoodsCategory from "./pages/goods/GoodsCategory";
import AssistantApi from "./pages/api/AssistantApi";
import UserType from "./pages/user/UserType";
import AssistantCategory from "./pages/chat/AssistantCategory";
import UserTypeOpRecord from "./pages/user/UserTypeOpRecord";
import AppVersion from "./pages/app/AppVersion";
import AppsPage from "./pages/app/AppsPage";
import ApiKeyInfo from "./pages/api/ApiKeyInfo";
import ChatLists from "./pages/chat/ChatLists";
import TgProtocolChatLists from "./pages/tgProtocolChat/TgProtocolChatLists";
import TgProxyAddress from "./pages/tgChatConfig/TgProxyAddress";
import TgMsgGroup from "./pages/tgChatConfig/TgMsgGroup";
import TgFriendGroup from "./pages/tgChatConfig/TgFriendGroup";
import TgGroupMsgTask from "./pages/tgChatConfig/TgGroupMsgTask";
import TgAccount from "./pages/tgChatConfig/TgAccount";
import TgTdataGroup from "./pages/tgChatConfig/TgTdataGroup";

export const menuItems: MenuItem[] = [
  {
    name: "会员管理",
    code: "userManager",
    icon: Users.icon,
    children: [
      {
        name: "会员列表",
        icon: Users.icon,
        code: "users",
        href: "/users",
      },
      {
        name: "账务记录",
        code: "userWalletLog",
      },
      {
        name: "会员配置",
        icon: UserType.icon,
        code: "userTypes",
        href: "/userTypes",
      },
      {
        name: "会员开通记录",
        code: "userTypeOpRecords",
        icon: UserTypeOpRecord.icon,
        href: "/userTypeOpRecords",
      },
      // {
      //   name: "会员等级",
      //   icon: UserLevelSetting.icon,
      //   code: "userLevelSetting",
      //   href: "/userLevelSetting",
      // },
      {
        name: "会员组管理",
        icon: Users.icon,
        code: "userByGroup",
        href: "/userByGroup",
      },
    ],
  },
  {
    name: "商品管理",
    icon: Goods.icon,
    code: "goodsManager",
    children: [
      {
        name: "商品分类",
        icon: GoodsCategory.icon,
        code: "goodsCategories",
        href: "/goodsCategories",
      },
      {
        name: "商品",
        icon: Goods.icon,
        code: "goods",
        href: "/goods",
      },
      {
        name: "商品订单",
        icon: GoodsOrder.icon,
        code: "goodsOrders",
        href: "/goodsOrders",
      },
    ],
  },
  {
    name: "AI管理",
    icon: Goods.icon,
    code: "aiManager",
    children: [
      {
        name: "AI角色",
        icon: Assistant.icon,
        code: "assistants",
        href: "/assistants",
      },
      {
        name: "AI角色分类",
        icon: AssistantCategory.icon,
        code: "assistantCategories",
        href: "/assistantCategories",
      },
    ],
  },
  {
    name: "聊天",
    icon: ChatLists.icon,
    code: "chats",
    children: [
      {
        name: "聊天",
        icon: ChatLists.icon,
        code: "chats",
        href: "/chats",
      },
    ],
  },
  {
    name: "TG任务中心",
    icon: TgProtocolChatLists.icon,
    code: "tgAuto",
    children: [
      {
        name: "TG账号",
        icon: TgAccount.icon,
        code: "tgAccount",
        href: "/tgAccount",
      },
      {
        name: "群发任务",
        icon: TgGroupMsgTask.icon,
        code: "tgGroupMsgTask",
        href: "/tgGroupMsgTask",
      },
      {
        name: "群发任务结果",
        icon: TgGroupMsgTask.icon,
        code: "tgGroupMsgTaskRes",
        href: "/tgGroupMsgTaskRes",
      },
      {
        name: "好友上传",
        icon: TgFriendGroup.icon,
        code: "tgFriendGroup",
        href: "/tgFriendGroup",
      },
      {
        name: "自定义消息",
        icon: TgMsgGroup.icon,
        code: "tgMsgGroup",
        href: "/tgMsgGroup",
      },
      {
        name: "代理IP",
        icon: TgProxyAddress.icon,
        code: "tgProxyAddress",
        href: "/tgProxyAddress",
      },
      {
        name: "TData账号",
        icon: TgTdataGroup.icon,
        code: "tgTdataGroup",
        href: "/tgTdataGroup",
      },
      {
        name: "TData账号详情",
        icon: TgTdataGroup.icon,
        code: "tgTdataAccount",
        href: "/tgTdataAccount",
      },
    ],
  },
  {
    name: "TG聊天室",
    icon: TgProtocolChatLists.icon,
    code: "tgProtocolChat",
    children: [
      {
        name: "TG聊天",
        icon: TgProtocolChatLists.icon,
        code: "tgProtocolChat",
        href: "/tgProtocolChat",
      },
    ],
  },
  {
    name: "内容管理",
    icon: Docs.icon,
    code: "contentManager",
    children: [
      {
        name: "文章管理",
        icon: Docs.icon,
        code: "docs",
        href: "/docs",
      },
      {
        name: "文章分类",
        icon: DocCategories.icon,
        code: "docCategories",
        href: "/docCategories",
      },
      {
        name: "页面信息",
        icon: WebPages.icon,
        code: "webPages",
        href: "/webPages",
      },
      {
        name: "通知管理",
        icon: SiteNotification.icon,
        code: "siteNotification",
        href: "/siteNotification",
      },
      {
        name: "APP管理",
        icon: AppsPage.icon,
        code: "apps",
        href: "/apps",
      },
      {
        name: "APP版本管理",
        icon: AppVersion.icon,
        code: "appVersions",
        href: "/appVersions",
      },
      {
        name: "站点资源",
        icon: SiteResource.icon,
        code: "siteResource",
        href: "/siteResource",
      },
    ],
  },
  {
    name: "系统设置",
    icon: Settings.icon,
    code: "sysSetting",
    children: [
      {
        name: "站点设置",
        icon: Settings.icon,
        code: "settings.update",
        href: "/settings/SiteSetting",
      },
      {
        name: "AI设置",
        icon: Settings.icon,
        code: "settings.update",
        href: "/settings/AiSetting",
      },
      {
        name: "会员设置",
        icon: Settings.icon,
        code: "settings.update",
        href: "/settings/UserSetting",
      },
      // {
      //   name: "支付设置",
      //   icon: Settings.icon,
      //   code: "settings.update",
      //   href: "/settings/PaySetting",
      // },
      {
        name: "邮件推送设置",
        icon: Settings.icon,
        code: "settings.update",
        href: "/settings/EmailSetting",
      },
      {
        name: "Tg设置",
        icon: Settings.icon,
        code: "settings.update",
        href: "/settings/TgSetting",
      },
      {
        name: "用户头像管理",
        icon: UserHead.icon,
        code: "userHead",
        href: "/userHead",
      },
      {
        name: "系统设置表单",
        code: "settings",
      },
      {
        name: "AI助理类型",
        icon: AssistantApi.icon,
        code: "assistantApis",
        href: "/assistantApis",
      },
      {
        name: "API密钥",
        icon: ApiKeyInfo.icon,
        code: "apiKeyInfos",
        href: "/apiKeyInfos",
      },
      {
        name: "星河客服账号",
        icon: ApiKeyInfo.icon,
        code: "xhAccountGroup",
        href: "/xhAccountGroup",
      },
    ],
  },
  {
    name: "管理员",
    icon: Admins.icon,
    code: "adminManager",
    children: [
      {
        name: "管理员",
        icon: Admins.icon,
        code: "admins",
        href: "/admins",
      },
      {
        name: "角色",
        icon: SysRole.icon,
        code: "sysRoles",
        href: "/sysRoles",
      },
      {
        name: "功能权限",
        icon: SysModule.icon,
        code: "sysModules",
        href: "/sysModules",
      },
      {
        name: "登录记录",
        icon: LoginLogs.icon,
        code: "loginLogs",
        href: "/loginLogs",
      },
    ],
  },
];
