import { FilterButton, TopToolbar } from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import GroupIcon from "@mui/icons-material/Group";
import { ResourceType } from "../../components/core/MyResource";
import { BooleanTypeChoices } from "../../model/Core";
import { ShowCommand } from "../../components/command/ShowCommand";
import { MyDatagrid } from "../../components/core/MyDatagrid";

const fields: MyField[] = [
  Field.objectId({}),
  Field.objectId({
    label: "分组ID",
    source: "groupId",
  }),
  Field.text({
    label: "文件名",
    source: "filename",
  }),
  Field.select({
    label: "转换结束",
    source: "transEnd",
    isFilter: false,
    choices: BooleanTypeChoices,
  }),
  Field.select({
    label: "转换成功",
    source: "resolveRes",
    choices: BooleanTypeChoices,
  }),
  Field.text({
    label: "失败原因",
    source: "errMsg",
    isFilter: false,
  }),
  Field.text({
    label: "手机号",
    source: "phone",
    isFilter: false,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

export const TgTdataAccountTable = () => {
  return <MyDatagrid fields={fields} rowActions={[]} />;
};

const resource: ResourceType = {
  commands: [<ShowCommand />],
  list: () => {
    return (
      <MyList
        fields={fields}
        actions={
          <>
            <TopToolbar>
              <FilterButton />
            </TopToolbar>
          </>
        }
      />
    );
  },
  icon: <GroupIcon />,
};

export default resource;
