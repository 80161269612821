import { CommandProps } from "../../components/command/Command";
import {
  BaseCommand,
  BaseCommandProps,
} from "../../components/command/BaseCommand";
import { FormDataConsumer, useRecordContext } from "ra-core";
import { CloudSync } from "@mui/icons-material";
import React from "react";
import get from "lodash/get";
import { useConfirmForm } from "../../hook/ConfirmContext";
import {
  AutocompleteInput,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
} from "react-admin";
import { UserTypeTimeTypeChoices } from "../../model/User";
import { UserManagerApi } from "../../api/UserManagerApi";
import { MyTabSelectInput } from "../../components/MyTabSelectInput";

export interface OpenVipCommandProps extends CommandProps {}

OpenVipCommand.defaultProps = {
  enableTool: false,
  enableRow: true,
  permission: "update",
} as BaseCommandProps;

export function OpenVipCommand(props: OpenVipCommandProps) {
  const { ...rest } = props;
  const record = useRecordContext(props);
  const id = get(record, "id");
  const confirm = useConfirmForm();
  return (
    <BaseCommand
      command={{
        isEnable: (rowData: any) => {
          return true;
        },
        label: (r) => {
          if (r.isFree) {
            return "开通会员";
          }
          return "续期会员";
        },
        icon: <CloudSync />,
        onHandle: async () => {
          const form = await confirm({
            title: record.isFree ? "开通会员" : "续期会员",
            form: (
              <>
                <MyTabSelectInput
                  isRequired
                  validate={[required()]}
                  source={"opType"}
                  defaultValue={"ADD"}
                  choices={
                    record.isFree
                      ? [{ id: "ADD", name: "开通会员" }]
                      : [
                          { id: "ADD", name: "续期" },
                          { id: "UPGRADE", name: "升级" },
                        ]
                  }
                />
                <FormDataConsumer>
                  {({ formData, ...rest }) => {
                    if (formData.opType === "ADD") {
                      return (
                        <>
                          <ReferenceInput
                            source="title"
                            filter={{ isFree: false }}
                            reference={"userTypes"}
                          >
                            <AutocompleteInput
                              label={"会员类型"}
                              isRequired
                              readOnly={!record.isFree}
                              defaultValue={record.userTypeId}
                              validate={[required()]}
                              optionText={(choice: any) =>
                                `${choice.defaultTitle}`
                              }
                              name="userTypeId"
                            />
                          </ReferenceInput>
                          <SelectInput
                            source="userTypeTimeType"
                            label={"时间类型"}
                            fullWidth
                            isRequired
                            validate={[required()]}
                            defaultValue={"Month"}
                            choices={UserTypeTimeTypeChoices}
                          />
                          <NumberInput
                            label={"时间"}
                            isRequired
                            validate={[required()]}
                            source={"number"}
                            defaultValue={1}
                          />
                        </>
                      );
                    }
                    return (
                      <>
                        <ReferenceInput
                          source="title"
                          reference={"userTypes"}
                          filter={{ isFree: false }}
                        >
                          <AutocompleteInput
                            label={"会员类型"}
                            isRequired
                            readOnly={true}
                            defaultValue={record.userTypeId}
                            validate={[required()]}
                            optionText={(choice: any) =>
                              `${choice.defaultTitle}`
                            }
                            name="userTypeId"
                          />
                        </ReferenceInput>
                        <ReferenceInput
                          source="title"
                          reference={"userTypes"}
                          filter={{ isFree: false }}
                        >
                          <AutocompleteInput
                            label={"升级会员类型"}
                            isRequired
                            defaultValue={record.userTypeId}
                            validate={[required()]}
                            optionText={(choice: any) =>
                              `${choice.defaultTitle}`
                            }
                            name="toUserTypeId"
                          />
                        </ReferenceInput>
                      </>
                    );
                  }}
                </FormDataConsumer>
              </>
            ),
          });
          //
          if (form) {
            if (form.opType === "UPGRADE") {
              await UserManagerApi.upgrade(id as string, form.toUserTypeId);
            } else {
              await UserManagerApi.open(
                id as string,
                form.userTypeId,
                form.userTypeTimeType,
                form.number
              );
            }
          }
          return true;
        },

        // to: (record, rd) => `#/${rd?.name}/create`,
      }}
      {...rest}
    />
  );
}
