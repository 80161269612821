import { useQuery } from "react-query";
import { useState, useEffect, useCallback, useRef } from "react";
import { ContextData } from "./data";
import {
  Dialog,
  TgProtocolApi,
  TgUnreadModel,
  User,
} from "../../../api/TgProtocolApi";
import { useAdmin, useCurrentAdmin } from "../../../hook/useCurrentAdmin"; // 假设 ContextData 已定义

interface UseReqTgUnReadFriendProps {}

export const useReqTgUnReadFriend = ({}: UseReqTgUnReadFriendProps): ContextData<
  TgUnreadModel
> => {
  const admin = useAdmin();
  const [selectIndex, setSelectIndex] = useState<number>();
  const intervalRef = useRef<NodeJS.Timeout>();

  const { data, error, isLoading, isError, refetch } = useQuery(
    ["UnReadChatFriendList"],
    async () => {
      return await TgProtocolApi.unReadChat(admin.id);
    },
    {
      enabled: !!admin,
      onSuccess: (data) => {
        // Ensure selectIndex is within bounds when data is fetched
        if (data) {
          if (!selectIndex || selectIndex < 0 || selectIndex >= data.length) {
            setSelectIndex(0);
          }
        } else {
          setSelectIndex(0);
        }
      },
    }
  );

  useEffect(() => {
    if (admin) {
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(() => {
        refetch();
      }, 2000);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [admin]);

  const changeSelect = useCallback(
    (index: number) => {
      if (data) {
        if (index < 0 || index >= data.length) {
          index = 0;
        }
        setSelectIndex(index);
      }
    },
    [data]
  );

  useEffect(() => {
    if (data) {
      if (!selectIndex || selectIndex < 0 || selectIndex >= data.length) {
        setSelectIndex(0);
      }
    } else {
      setSelectIndex(0);
    }
  }, [data, selectIndex]);

  return {
    isError,
    error,
    isLoading,
    refetch,
    select: data ? data[selectIndex!] : null,
    list: data ?? [],
    selectIndex,
    changeSelect,
  };
};
