import React from "react";
import {
  DefaultCommands,
  ResourceType,
} from "../../components/core/MyResource";
import { SyncCommand } from "./SyncCommand";
import { MyTgChatList } from "./MyTgChatList";
import { Telegram } from "@mui/icons-material";

const resource: ResourceType = {
  commands: [...DefaultCommands, <SyncCommand />],
  list: () => {
    return <MyTgChatList />;
  },
  icon: <Telegram />,
};
export default resource;
