import { useInfiniteQuery, useQuery } from "react-query";
import { useCallback, useEffect, useState } from "react";
import { ContextData } from "./data";
import {
  Message,
  MessagePageResp,
  TgProtocolApi,
  User,
} from "../../../api/TgProtocolApi"; // 假设 ContextData 已定义

interface UseCustomerMessageListProps {
  account: string | null | undefined;
  friendAccount: User | null | undefined;
  // groupId: string | null | undefined;
}

export const useReqTgHistoryList = ({
  account,
  friendAccount,
}: UseCustomerMessageListProps): ContextData<Message> => {
  const [selectIndex, setSelectIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);

  const { data, error, isLoading, isError, refetch } = useQuery(
    ["CustomerMessageList", friendAccount, page, limit],
    async () => {
      return await TgProtocolApi.messageGet({
        client_id: account,
        receiver: friendAccount?.id?.toString(),
        page,
        limit,
      });
    },
    {
      enabled: !!friendAccount && !!account,
      onSuccess: (data) => {
        // Ensure selectIndex is within bounds when data is fetched
        if (data && (selectIndex < 0 || selectIndex >= data.length)) {
          setSelectIndex(0);
        }
      },
    }
  );

  // 分页查询
  // const {
  //   data,
  //   error,
  //   isLoading,
  //   fetchNextPage,
  //   hasNextPage,
  //   isFetching,
  //   isFetchingNextPage,
  //   isError,
  //   refetch,
  // } = useInfiniteQuery(
  //   ["CustomerMessageList", friendAccount, page, limit],
  //   async ({ pageParam = 0 }) => {
  //     const d = await TgProtocolApi.messageGet({
  //       client_id: account,
  //       receiver: friendAccount?.id?.toString(),
  //       page: pageParam,
  //       limit,
  //     });
  //     return d;
  //   },
  //   {
  //     enabled: !!friendAccount && !!account,
  //     getNextPageParam: (lastPage, pages) =>
  //       (lastPage?.page ?? 0) * (lastPage?.limit ?? 0) < (lastPage?.total ?? 0)
  //         ? lastPage.page + 1
  //         : false,
  //   }
  // );

  const addPage = () => {
    console.log("addPage: ", page);
    if (!isLoading) {
      setPage((pre) => pre + 1);
    }
  };

  const changeSelect = useCallback(
    (index: number) => {
      if (data) {
        if (index < 0 || index >= data?.length) {
          index = 0;
        }
        setSelectIndex(index);
      }
    },
    [data]
  );
  //
  // useEffect(() => {
  //   if (data && (selectIndex < 0 || selectIndex >= data.length)) {
  //     setSelectIndex(0);
  //   }
  // }, [data, selectIndex]);

  // const getAllHistory = useCallback(() => {
  //   return data?.pages
  //     ?.map((pr) => pr.data_list)
  //     .filter((pr) => !!pr)
  //     .reduce((res, cur) => res!!.concat(cur!!));
  // }, [data]);

  return {
    isError,
    error,
    isLoading,
    refetch,
    select: data ? data?.[selectIndex] : undefined,
    list: data ?? [],
    selectIndex,
    changeSelect,
    methods: {
      addPage: addPage,
      // getAllHistory: getAllHistory,
    },
  };
};
