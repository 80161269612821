import { Box, Button, TextField } from "@mui/material";
import React from "react";
import { useTgChatFriend } from "../hook/TgAccountDataContext";

export const TgFriendSearchBtn = () => {
  const [ac, setAc] = React.useState("");

  const { search } = useTgChatFriend();

  const handleSearch = async () => {
    console.log("search --- ", ac);
    await search?.(ac);
  };

  return (
    <Box display="flex" justifyContent="center">
      <TextField
        size={"small"}
        variant="outlined"
        placeholder={"请输入好友账号"}
        sx={{
          "& .MuiFormControl-root": {
            marginTop: 0,
            marginBottom: 0,
          },
          "& .MuiInputBase-root": {
            paddingX: 1,
          },
        }}
        resource={ac}
        value={ac}
        onChange={(e) => setAc(e.target.value)}
        InputProps={{
          endAdornment: (
            <Button size="small" variant="contained" onClick={handleSearch}>
              查询
            </Button>
          ),
        }}
      />
    </Box>
  );
};
