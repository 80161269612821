import { TgProxyAddressModel } from "./TgProxyAddressModel";

export interface TgAccount {
  id: string;
  userId: string;
  tgUserId?: string;
  phone?: string;
  nickname?: string;
  username?: string;
  headUrl?: string;
  isRead?: number;
  isTop?: number;
  status: number;
  createDate?: string;
  tgProxyAddress?: TgProxyAddressModel;
  lastLoginErrorMsg?: string;
}

export interface TgChat {
  id: string;
  userId: string;
  phone?: string;
  friendPhone?: string;
}

export const TgAccountStatusChoices = [
  {
    id: 2,
    name: "正常",
  },
  {
    id: 3,
    name: "异常状态",
  },
  {
    id: 5,
    name: "需要登录",
  },
];
