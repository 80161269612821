import React, { useMemo } from "react";
import { Divider, List, ListItemButton, ListItemText } from "@mui/material";
import { Loading } from "react-admin";
import { useChatGroup } from "../hook/ChatDataContext";

export interface GroupListProps {}

export const GroupList = ({}: GroupListProps) => {
  const {
    changeSelect,
    selectIndex,
    list,
    error,
    isLoading,
    isError,
  } = useChatGroup();

  const totalFriendNum = useMemo(() => {
    return list?.reduce((sum, group) => sum + group.friendNum, 0) ?? 0;
  }, [list]);

  const handleClick = (index: number, group: any | null) => {
    changeSelect(index);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div>Error: {`${error}`}</div>;
  }

  return (
    <List
      sx={{
        width: "120px",
        bgcolor: "background.paper",
        "& ul": { padding: 0 },
      }}
      subheader={<li />}
    >
      {/*<ListItemButton*/}
      {/*  sx={{ padding: "4px 8px", textAlign: "center" }}*/}
      {/*  selected={selectIndex === -1}*/}
      {/*  onClick={() => handleClick(-1, null)}*/}
      {/*>*/}
      {/*  <ListItemText primary={`全部用户(${totalFriendNum})`} />*/}
      {/*</ListItemButton>*/}
      <Divider />
      {list?.map((item, index) => (
        <React.Fragment key={item.id}>
          <ListItemButton
            sx={{ padding: "4px 8px", textAlign: "center" }}
            selected={selectIndex === index}
            onClick={() => handleClick(index, item)}
          >
            <ListItemText primary={`${item.name}(${item.friendNum})`} />
          </ListItemButton>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
};
