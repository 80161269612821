export interface TgProxyAddressModel {
  id?: string;
  // ip
  name?: string;
  remarks?: string;
  // port
  infoList: TgMsgInfo[];
  createdDate?: string;
  updateDate?: string;
}

export interface TgMsgInfo {
  msgType: TgMsgFileType;
  msgContent: string;
}

export enum TgMsgFileType {
  TEXT = "TEXT",
  PHOTO = "PHOTO",
  VIDEO = "VIDEO",
}

export const TgMsgFileTypeChoices = [
  { id: "TEXT", name: "文本" },
  { id: "PHOTO", name: "图片" },
  { id: "VIDEO", name: "视频" },
];
