import { LoadingButton } from "@mui/lab";
import { ChangeEvent, useRef, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { useNotify, useRefresh } from "react-admin";
import { TgFriendGroupApi } from "../../../api/TgFriendGroupApi";

function UploadFriendBtn() {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const refresh = useRefresh();

  const selectFile = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files.length > 0) {
        const file = e.target.files[0];
        try {
          setLoading(true);
          notify(`开始上传文件：${file.name}`);
          await TgFriendGroupApi.uploadFriend(file);
          await refresh();
        } catch (e) {
          notify(`文件上传失败: ${e}`, { type: "error" });
        }
        setLoading(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    }
  };

  return (
    <>
      <LoadingButton
        loading={loading}
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
      >
        好友上传
        <VisuallyHiddenInput
          type="file"
          onChange={selectFile}
          ref={fileInputRef}
        />
      </LoadingButton>
    </>
  );
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default UploadFriendBtn;
