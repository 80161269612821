import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";

export const TgGroupMsgTaskApi = {
  manualStart: async (id: string): Promise<Boolean> => {
    let { data } = await fetchJson(
      `${API_URL}/tgGroupMsgTask/manualStart/${id}`,
      {
        method: "POST",
      }
    );
    return data;
  },
  manualStop: async (id: string): Promise<Boolean> => {
    let { data } = await fetchJson(
      `${API_URL}/tgGroupMsgTask/manualStop/${id}`,
      {
        method: "POST",
      }
    );
    return data;
  },
};
