import React from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  required,
  TextInput,
} from "react-admin";
import get from "lodash/get";

export const AiSetting: React.FC = (props) => {
  let optionText = (choice?: any) => {
    let value = get(choice, "name");
    return value // the empty choice is { id: '' }
      ? `${value}`
      : "";
  };
  return (
    <>
      <ReferenceInput source={"setting.apiKeyInfoId"} reference={"apiKeyInfos"}>
        <AutocompleteInput
          label={`默认密钥`}
          optionText={optionText}
          name={"setting.apiKeyInfoId"}
        />
      </ReferenceInput>
      <TextInput
        label="全局提示词"
        maxRows={10}
        multiline
        rows={5}
        source="setting.instructions"
        fullWidth
      />
      <TextInput
        label="翻译提示词"
        maxRows={10}
        multiline
        rows={5}
        source="setting.translateInstructions"
        fullWidth
      />
      <TextInput
        label="生成预选答案提示"
        maxRows={10}
        multiline
        rows={5}
        source="setting.multipleAsk"
        fullWidth
      />
    </>
  );
};
// var apiKeyInfoId: String = "",
//   var instructions: String = "",
// var translateInstructions: String = "",
