import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TgProtocolApi } from "../../../api/TgProtocolApi";
import { useChatAccount } from "../hook/ChatDataContext";
import { base64ToBlobUrl } from "../../../utils/fileToBase64";

export interface TgAvatarProps {
  head?: string;
  firstName?: string;
  lastName?: string;
}

const defaultHeadUrl = "https://web.telegram.org/a/icon-square-192x192.png";

function TgAvatar({ head, firstName, lastName }: TgAvatarProps) {
  const { select: account } = useChatAccount();
  const [imgSrc, setImgSrc] = useState({});

  useEffect(() => {
    async function fetchData() {
      if (head && head.length > 0) {
        try {
          const url = base64ToBlobUrl(head, "image/jpeg");
          setImgSrc({ src: url });

          return;
        } catch (e) {}
      }

      if (firstName || lastName) {
        setImgSrc(stringAvatar(`${firstName} ${lastName}`));
      } else {
        setImgSrc({ src: defaultHeadUrl });
      }
    }
    if (account) {
      fetchData();
    }
  }, []);

  return (
    <Avatar
      {...imgSrc}
      sx={{
        borderRadius: "50%",
        width: "40px",
        height: "40px",
      }}
    />
  );
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export default TgAvatar;
