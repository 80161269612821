import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Loading } from "react-admin";
import { ChatHeader } from "./ChatHeader";
import { ChatHistory } from "./ChatHistory";
import { ChatInput } from "./ChatInput";
import {
  useChatAccount,
  useChatFriend,
  useChatHistory,
} from "../hook/ChatDataContext";
import { ChatApi } from "../../../api/ChatApi";
import { useSendMessage } from "../hook/useSendMessage";
import { CustomerMessage } from "../../../model/chatData";

export interface ChatViewProps {}

export const ChatView = (props: ChatViewProps) => {
  const { list, error, isLoading, isError, refetch } = useChatHistory();

  const { select: account } = useChatAccount();
  const { select: friend } = useChatFriend();

  const { send, sending } = useSendMessage(friend);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div>Error: {`${error}`}</div>;
  }

  const onDelMsg = async (message: CustomerMessage) => {
    await ChatApi.delMessage(
      message.dataId,
      message.msgId,
      message.receiveAccount,
      message.sendAccount
    );
    await refetch();
  };
  const handleSendMessage = async (message: string) => {
    await send(message, () => {});
  };
  if (!account || !friend) {
    return <></>;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <ChatHeader account={account} friend={friend} />
      <ChatHistory
        messages={list}
        account={account}
        friend={friend}
        onDelMsg={onDelMsg}
      />
      <ChatInput
        sending={sending}
        friend={friend}
        onSend={handleSendMessage}
        account={account}
        history={list ?? []}
      />
    </Box>
  );
};
