import { RaRecord, useRecordContext, useResourceContext } from "ra-core";
import * as React from "react";
import { MouseEvent, ReactElement, useMemo, useState } from "react";
import { useListContext, useNotify, useRefresh } from "react-admin";
// import { RaRecord } from "ra-core/src/types";
import isFunction from "lodash/isFunction";
import { Button, ButtonProps, CircularProgress, SxProps } from "@mui/material";
import { Link, To } from "react-router-dom";

export type IconCallback = <
  RecordType extends RaRecord | Omit<RaRecord, "id"> = RaRecord
>(
  record: RecordType,
  loading: boolean
) => ReactElement;

type LoadingCallback = <
  RecordType extends RaRecord | Omit<RaRecord, "id"> = RaRecord
>(
  event: React.MouseEvent,
  record: RecordType,
  resource: string
) => Promise<void>;

interface Props {
  resource?: string;
  label?: React.ReactNode;
  to?: To;
  doLoading: LoadingCallback;
  icon?: IconCallback | ReactElement;
}

export type LoadingButtonProps = Props & ButtonProps;

// 提取的样式
const buttonStyles: SxProps = {
  p: "2px 8px",
  fontSize: "13px !important",
  "& .MuiSvgIcon-root": { fontSize: "16px !important" },
};

export const LoadingButton = (props: LoadingButtonProps) => {
  const { doLoading, children, disabled, to, icon, ...rest } = props;
  const resource = useResourceContext(props);
  const record = useRecordContext(props);
  const { isLoading } = useListContext(props);
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const handleClick = async (e: MouseEvent) => {
    e.stopPropagation();
    setLoading(true);
    try {
      await doLoading(e, record, resource);
    } catch (e) {
      // @ts-ignore
      notify(e["message"] || error + "", { type: "error" });
    } finally {
      refresh();
      setLoading(false);
    }
  };

  const curIcon = useMemo(() => {
    if (isFunction(icon)) {
      return icon(record, isLoading || loading);
    } else {
      return loading ? <CircularProgress size={18} color="info" /> : icon;
    }
  }, [icon, isLoading, loading, record]);
  if (to)
    return (
      <Button
        sx={buttonStyles}
        size={"small"}
        component={Link}
        to={to}
        onClick={handleClick}
        disabled={disabled || loading}
        startIcon={curIcon}
        {...rest} // 确保将剩余的 props 传递给 Button 组件
      >
        {children}
      </Button>
    );
  else {
    return (
      <Button
        sx={buttonStyles}
        size={"small"}
        onClick={handleClick}
        disabled={disabled || loading}
        startIcon={curIcon}
        {...rest} // 确保将剩余的 props 传递给 Button 组件
      >
        {children}
      </Button>
    );
  }
};
