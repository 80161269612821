import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { Assessment } from "@mui/icons-material";
import { BooleanTypeChoices } from "../../model/Core";
import { ResourceType } from "../../components/core/MyResource";
import { MyShow } from "../../components/core/MyShow";
import { MyShowFields } from "../../components/MyShowFields";

const fields: MyField[] = [
  Field.text({
    label: "等级",
    source: "id",
    isCreate: true,
    isUpdate: false,
    isEdit: false,
    isFilter: false,
  }),
  Field.text({
    label: "等级名称",
    source: "name",
    isFilter: false,
    isRequired: true,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource: ResourceType = {
  show: () => {
    return (
      <MyShow>
        <MyShowFields fields={fields} />
      </MyShow>
    );
  },
  list: () => {
    return <MyList fields={fields} />;
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <Assessment />,
};

export default resource;
