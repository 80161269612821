import { DeleteButton, EditButton } from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { RiGroupFill } from "@remixicon/react";
import { AssistantTable } from "../chat/Assistant";

const fields: MyField[] = [
  Field.id(),
  Field.editNumber({
    label: "等级",
    source: "level",
    defaultValue: 0,
  }),
  Field.localesValue({
    label: "名称",
    source: "title",
    isFilter: false,
  }),
  Field.refTable(
    {
      label: "助手",
      source: "assistantIds",
      reference: "assistants",
      fullWidth: true,
    },
    {
      editProps: {
        children: <AssistantTable />,
      },
    }
  ),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        pagination={false}
        fields={fields}
        rowActions={[<EditButton />, <DeleteButton />]}
        sort={{ field: "level", order: "DESC" }}
        // groupingColDef={groupingColDef}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <RiGroupFill />,
};

export default resource;
