export const UserWalletTypeChoices = [
  { id: "SPOT", name: "现货" },
  { id: "LEVERAGE", name: "杠杆" },
  { id: "CONTRACT", name: "合约" },
];
// enum class UserTypeOpType {
//   ADD, UPGRADE;
// }

export interface UserWallet {
  id: number;
  userId: number;
  userName: string;
  coinId: number;

  coinSymbol: string;
  balance: string;
  frozenBalance: string;

  address: Record<string, string>;

  isLock: boolean;

  createdDate: string;
  updateDate: string;
  version: number;
}
