import { Box } from "@mui/material";
import { TgChatView } from "./TgChatView";
import { TgFriendList } from "./TgFriendList";
import { ToolPanel } from "./ToolPanel";
import { useChatAccount } from "../hook/ChatDataContext";
import { TgAccountDataProvider } from "../hook/TgAccountDataContext";
import { useEffect } from "react";
import { TgProtocolApi } from "../../../api/TgProtocolApi";
import { TgAccount } from "../../../model/tgProtocolChatData";

function TgChatPage() {
  const { select: account } = useChatAccount();

  return (
    <TgAccountDataProvider account={account}>
      <InnerPage />
    </TgAccountDataProvider>
  );
}

function InnerPage() {
  const { selectIndex, select: account } = useChatAccount();

  return (
    <Box display={"flex"}>
      {/*{selectIndex !== 0 && (*/}
      {/*  <Box*/}
      {/*    sx={(theme) => ({*/}
      {/*      display: "flex",*/}
      {/*      bgcolor: "background.paper",*/}
      {/*      overflow: "auto",*/}
      {/*      height: "calc(100vh - (41px + 48px))",*/}
      {/*      borderRight: "1px solid #ccc",*/}
      {/*    })}*/}
      {/*  >*/}
      {/*    <GroupList />*/}
      {/*  </Box>*/}
      {/*)}*/}
      <Box
        sx={(theme) => ({
          display: "flex",
          bgcolor: "background.paper",
          overflow: "auto",
          height: "calc(100vh - (41px + 48px))",
          borderRight: "1px solid #ccc",
        })}
      >
        <TgFriendList />
      </Box>
      <Box
        flex={1}
        sx={(theme) => ({
          display: "flex",
          bgcolor: "background.paper",
          overflow: "auto",
          height: "calc(100vh - (41px + 48px))",
          borderRight: "1px solid #ccc",
        })}
      >
        <TgChatView />
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          bgcolor: "background.paper",
          overflow: "auto",
          height: "calc(100vh - (41px + 48px))",
          borderRight: "1px solid #ccc",
        })}
      >
        <ToolPanel />
      </Box>
    </Box>
  );
}

export default TgChatPage;
