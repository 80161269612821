import { User } from "../model/User";
import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";

export const XhAccountGroupApi = {
  syncData: async (): Promise<User> => {
    const { data } = await fetchJson(`${API_URL}/xhAccountGroup/syncData`, {
      method: "POST",
    });
    return data;
  },
};
