import { useCallback, useState } from "react";
import { useTgChatHistory } from "./TgAccountDataContext";
import { Message, TgProtocolApi } from "../../../api/TgProtocolApi";

export function useTranslateItem(msg: Message) {
  const [loading, setLoading] = useState(false);
  const { refetch } = useTgChatHistory();

  const handleSend = useCallback(async () => {
    setLoading(true);
    try {
      await TgProtocolApi.translateItem(msg);
    } finally {
      setLoading(false);
    }
  }, [msg]);

  let translateItem = async function () {
    try {
      await handleSend();
    } finally {
      await refetch();
    }
  };
  return {
    translateItem,
    loading,
  };
}
