import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";
import { Edit, SimpleForm, useRecordContext } from "react-admin";
import { SiteSetting } from "./SiteSetting";
import { EmailSetting } from "./EmailSetting";
import { AiSetting } from "./AiSetting";
import { TgSetting } from "./TgSetting";
import { UserSetting } from "./UserSetting";

const FormMap = {
  SiteSetting: SiteSetting,
  EmailSetting: EmailSetting,
  AiSetting: AiSetting,
  UserSetting: UserSetting,
  TgSetting: TgSetting,
};

const MyEditForm = () => {
  const record = useRecordContext();
  // @ts-ignore
  let Com = FormMap[record.id];
  return <SimpleForm>{Com && <Com />}</SimpleForm>;
};

export const MyEdit = () => {
  return (
    <Edit redirect={false} mutationMode={"pessimistic"}>
      <MyEditForm />
    </Edit>
  );
};

export default {
  edit: MyEdit,
  icon: <SettingsIcon />,
};
