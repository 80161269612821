import Box from "@mui/material/Box";
import React, { useState } from "react";
import { Button, IconButton, Paper, Stack } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import CopyToClipboard from "../../../components/CopyToClipboard";
import { TgAccount } from "../../../model/tgProtocolChatData";
import { TgProtocolApi } from "../../../api/TgProtocolApi";
import { LoadingButton } from "@mui/lab";
import { useNotify } from "react-admin";

export interface TgAccountInfoProps {
  account: TgAccount;
  refetch: () => Promise<any>;
}

function toStatus(status: number) {
  switch (status) {
    case 1:
      return "离线";
    case 2:
      return "在线";
    default:
      return "未知";
  }
}

export function TgAccountInfo({ account, refetch }: TgAccountInfoProps) {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  async function handleTop() {
    if (account?.isTop === 2) {
      await TgProtocolApi.doChatCancelUpTop(account?.id);
    } else {
      await TgProtocolApi.doChatUpTop(account?.id);
    }

    await refetch();
  }

  const reconnectClick = async () => {
    setLoading(true);
    try {
      if (account?.id) {
        await TgProtocolApi.reconnect(account?.id);
        notify("重新连接请求成功", { type: "success" });
      }
    } catch (ignore) {}
    setLoading(false);
  };

  return (
    <Paper
      sx={{
        padding: 2,
        fontSize: "14px",
        lineHeight: "1.5",
        fontWeight: "400",
      }}
    >
      <Stack spacing={2}>
        <Box>
          账号: {account?.phone}
          <CopyToClipboard>
            {({ copy }) => (
              <IconButton onClick={() => copy(account?.phone)}>
                <ContentCopy sx={{ fontSize: 10 }} />
              </IconButton>
            )}
          </CopyToClipboard>
        </Box>
        <Box>
          用户名: {account?.username}
          <CopyToClipboard>
            {({ copy }) => (
              <IconButton onClick={() => copy(account?.username)}>
                <ContentCopy sx={{ fontSize: 10 }} />
              </IconButton>
            )}
          </CopyToClipboard>
        </Box>
        <Box>昵称: {account?.nickname}</Box>
        <Box>状态: {toStatus(account.status)}</Box>
        <Box>
          代理: {account?.tgProxyAddress?.host}:{account.tgProxyAddress?.port}
        </Box>
        <Button
          variant="outlined"
          sx={{ minWidth: "200px" }}
          onClick={handleTop}
        >
          {account?.isTop === 2 ? "取消置顶" : "置顶"}
        </Button>
        {account?.status === 3 && (
          <>
            <Box>错误原因：{account.lastLoginErrorMsg?.substring(0, 20)}</Box>
            <LoadingButton loading={loading} onClick={reconnectClick}>
              重新连接
            </LoadingButton>
          </>
        )}
      </Stack>
    </Paper>
  );
}
