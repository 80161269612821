import { useEffect, useState, useCallback } from "react";
import { ContextData } from "./data";
import { TgAccount } from "../../../model/tgProtocolChatData";
import TgAvatar from "../compoents/TgAvatar";

// 假设 ContextData 已定义

export function useAccounts(
  accounts?: TgAccount[],
  accountRefresh?: () => void
): ContextData<TgAccount> {
  const [selectIndex, setSelectIndex] = useState<number>();

  const changeSelect = useCallback(
    (index: number) => {
      if (accounts) {
        if (index < 0 || index > accounts.length) {
          index = 1;
        }
        setSelectIndex(index);
      } else {
        setSelectIndex(1);
      }
    },
    [accounts?.length]
  );

  useEffect(() => {
    if (accounts) {
      if (
        selectIndex === undefined ||
        selectIndex < 0 ||
        selectIndex > accounts.length
      ) {
        setSelectIndex(1);
      }
    } else {
      setSelectIndex(1);
    }
  }, [accounts, selectIndex]);

  return {
    isError: false,
    error: null,
    isLoading: false,
    refetch: () =>
      new Promise((resolve) => {
        accountRefresh?.();
        resolve("");
      }),
    select: accounts && selectIndex !== 0 ? accounts[selectIndex! - 1] : null,
    list: accounts,
    selectIndex,
    changeSelect,
  };
}
