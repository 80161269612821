export interface TgProxyAddressModel {
  id?: string;
  // ip
  host?: string;
  // port
  port?: string;

  proxyType?: ProxyType;
  // 国家
  country?: string;
  endTime?: string;
  enable?: Boolean;

  limit?: number;
  useCount?: number;

  createdDate?: string;
  updateDate?: string;
}

export enum ProxyType {
  SOCKS5 = "SOCKS5",
  HTTP = "HTTP",
}

export const ProxyTypeChoices = [
  { id: "SOCKS5", name: "SOCKS5" },
  { id: "HTTP", name: "HTTP" },
];
