import { DeleteButton, EditButton } from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import { BooleanTypeChoices } from "../../model/Core";
import { Chip } from "@mui/material";
import { ChipOwnProps } from "@mui/material/Chip/Chip";
import TgGroupMsgTaskShow from "./components/TgGroupMsgTaskShow";
import { ShowCommand } from "../../components/command/ShowCommand";
import { EditCommand } from "../../components/command/EditCommand";
import { DeleteCommand } from "../../components/command/DeleteCommand";
import { StartStopCommand } from "./commands/StartStopCommand";
import { CreateCommand } from "../../components/command/CreateCommand";

const fields: MyField[] = [
  Field.objectId({}),
  Field.text({
    label: "名称",
    source: "name",
  }),
  Field.text({
    label: "备注",
    source: "remarks",
    isFilter: false,
  }),
  Field.editDate({
    label: "开始时间",
    source: "startTime",
    fullWidth: false,
  }),
  Field.functionField({
    label: "任务状态",
    source: "taskState",
    fullWidth: true,
    render: (record?: any, source?: string) => {
      if (record.taskState === "INIT") {
        return <Chip color="default" label={"未运行"} variant="outlined" />;
      } else if (record.taskState === "RUNNING") {
        return <Chip color="primary" label={"运行中"} variant="outlined" />;
      } else if (record.taskState === "ERROR") {
        return <Chip color="error" label={"失败"} variant="outlined" />;
      } else if (record.taskState === "SUCCESS") {
        return <Chip color="success" label={"结束"} variant="outlined" />;
      }
    },
    isCreate: false,
    isEdit: false,
    isUpdate: false,
  }),
  Field.ref({
    label: "消息组",
    source: "tgMsgGroupId",
    isRequired: true,
    reference: "tgMsgGroup",
    referenceLabel: "name",
  }),
  Field.ref({
    label: "好友组",
    source: "friendGroupId",
    isRequired: true,
    reference: "tgFriendGroup",
    referenceLabel: "name",
  }),
  Field.select({
    label: "任务是否结束",
    source: "taskIsOver",
    isFilter: true,
    choices: BooleanTypeChoices,
    isEdit: false,
    isUpdate: false,
    isCreate: false,
  }),
  Field.editNumber({
    label: "发送间隔",
    source: "stepSecond",
  }),
  Field.text({
    label: "已发送数量",
    source: "sendCount",
    isEdit: false,
    isUpdate: false,
    isFilter: false,
    isCreate: false,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "完成时间",
    source: "completedTime",
  }),
];

const resource = {
  commands: [
    <StartStopCommand />,
    <ShowCommand />,
    <CreateCommand />,
    <EditCommand />,
    <DeleteCommand />,
  ],
  show: () => {
    return <TgGroupMsgTaskShow fields={fields} />;
  },
  list: () => {
    return (
      <MyList
        fields={fields}
        rowActions={[<EditButton />, <DeleteButton />]}
        // groupingColDef={groupingColDef}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <AutoGraphIcon />,
};

export default resource;
