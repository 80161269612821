import React, { createContext, useContext } from "react";
import { useReqHistoryList } from "./useReqHistoryList";
import { useReqChatFriendList } from "./useReqChatFriendList";
import { useReqChatGroupList } from "./useReqChatGroupList";
import {
  ChatAccountCustomer,
  ChatGroup,
  CustomerFriend,
  CustomerMessage,
} from "../../../model/chatData";
import { ContextData } from "./data";
import { useAccounts } from "./useAccounts";
import { useReqUnReadChatFriendList } from "./useReqUnReadChatFriendList";
import { useCurrentAdmin } from "../../../hook/useCurrentAdmin";

interface ChatData {
  accountData: ContextData<ChatAccountCustomer>;
  groupData: ContextData<ChatGroup>;
  friendData: ContextData<CustomerFriend>;
  unReadFriendData: ContextData<CustomerFriend>;
  historyData: ContextData<CustomerMessage>;
  setChatFilters: (
    filters: any,
    displayedFilters: any,
    debounce?: boolean
  ) => void;
}

const ChatDataContext = createContext<ChatData | undefined>(undefined);

export const ChatDataProvider: React.FC<{
  children: React.ReactNode;
  accounts?: ChatAccountCustomer[];
  setChatFilters: (
    filters: any,
    displayedFilters: any,
    debounce?: boolean
  ) => void;
  accountRefresh: () => void;
}> = ({ children, accounts, setChatFilters, accountRefresh }) => {
  const { admin } = useCurrentAdmin();
  const accountData = useAccounts(accounts, accountRefresh);

  const unReadFriendData = useReqUnReadChatFriendList({
    staffNo: admin.chatAccount,
    account: accountData?.select?.account,
  });

  const groupData = useReqChatGroupList({
    account: accountData?.select?.account,
  });

  const friendData = useReqChatFriendList({
    account: accountData?.select?.account,
    groupId: groupData?.select?.id,
  });

  const historyData = useReqHistoryList({
    friendAccount: friendData?.select,
    groupId: groupData?.select?.id,
  });

  return (
    <ChatDataContext.Provider
      value={{
        accountData,
        groupData,
        friendData,
        unReadFriendData,
        historyData,
        setChatFilters,
      }}
    >
      {children}
    </ChatDataContext.Provider>
  );
};

export const useChatData = (): ChatData => {
  const context = useContext(ChatDataContext);
  if (!context) {
    throw new Error("useChatData must be used within a ChatDataProvider");
  }
  return context;
};

export function useChatAccount() {
  const { accountData } = useChatData();
  return accountData;
}

export function useChatGroup() {
  const { groupData } = useChatData();
  return groupData;
}

export function useChatFriend() {
  const { friendData } = useChatData();
  return friendData;
}

export function useUnReadFriendData() {
  const { unReadFriendData } = useChatData();
  return unReadFriendData;
}

export function useChatHistory() {
  const { historyData } = useChatData();
  return historyData;
}

export function useChatFilters() {
  const { setChatFilters } = useChatData();
  return setChatFilters;
}
