import { Create, RemoveRedEye } from "@mui/icons-material";
import { CommandProps } from "./Command";
import React from "react";
import { BaseCommand, BaseCommandProps } from "./BaseCommand";
import { useCreatePath, useRecordContext, useResourceContext } from "ra-core";

export interface ShowCommandProps extends CommandProps {}

ShowCommand.defaultProps = {
  enableTool: false,
  enableRow: true,
  permission: "show",
} as BaseCommandProps;

export function ShowCommand(props: ShowCommandProps) {
  const { ...rest } = props;
  const createPath = useCreatePath();
  const record = useRecordContext(props);
  const resource = useResourceContext();
  const to = createPath({ type: "show", resource, id: record?.id });
  return (
    <BaseCommand
      command={{
        to: to,
        label: "查看",
        icon: <RemoveRedEye />,
        // to: (record, rd) => `#/${rd?.name}/create`,
      }}
      {...rest}
    />
  );
}
