export const convertFileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      let data = reader.result as string;
      // data = data.split(";base64,")[1];
      resolve(data);
    };
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });

export function base64ToArrayBuffer(base64: string) {
  try {
    let binaryString = window.atob(base64);
    let bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  } catch (e) {}
  return new Uint8Array();
}

export function base64ToBlobUrl(base64: string, type: string) {
  const byteArray = base64ToArrayBuffer(base64);
  const blob = new Blob([byteArray], { type: type });
  const url = URL.createObjectURL(blob);
  return url;
}
