import { useQuery } from "react-query";
import { useState, useEffect, useCallback } from "react";
import { ChatApi } from "../../../api/ChatApi";
import { CustomerFriend } from "../../../model/chatData";
import { ContextData } from "./data"; // 假设 ContextData 已定义

interface UseChatUnReadFriendListProps {
  staffNo: string | null | undefined;
  account: string | null | undefined;
}

export const useReqUnReadChatFriendList = ({
  staffNo,
  account,
}: UseChatUnReadFriendListProps): ContextData<CustomerFriend> => {
  const [selectIndex, setSelectIndex] = useState<number>();
  const [friendAccount, setFriendAccount] = useState("");

  const { data, error, isLoading, isError, refetch } = useQuery(
    ["UnReadChatFriendList", staffNo],
    async () => {
      return (
        await ChatApi.getCustomerUnReadList({
          staffNo: staffNo ?? "",
          account: "",
          groupId: "",
          friendAccount: friendAccount ?? "",
        })
      ).list;
    },
    {
      enabled: !!staffNo,
      onSuccess: (data) => {
        // Ensure selectIndex is within bounds when data is fetched
        if (data) {
          if (!selectIndex || selectIndex < 0 || selectIndex >= data.length) {
            setSelectIndex(0);
          }
        } else {
          setSelectIndex(0);
        }
      },
    }
  );

  const changeSelect = useCallback(
    (index: number) => {
      if (data) {
        if (index < 0 || index >= data.length) {
          index = 0;
        }
        setSelectIndex(index);
      }
    },
    [data]
  );

  useEffect(() => {
    if (data) {
      if (!selectIndex || selectIndex < 0 || selectIndex >= data.length) {
        setSelectIndex(0);
      }
    } else {
      setSelectIndex(0);
    }
  }, [data, selectIndex]);

  const searchByFriendAccount = (friendAccount: string) => {
    setFriendAccount(friendAccount);
  };

  useEffect(() => {
    refetch();
  }, [friendAccount]);

  return {
    isError,
    error,
    isLoading,
    refetch,
    select: data ? data[selectIndex!] : null,
    list: data ?? [],
    selectIndex,
    changeSelect,
    search: searchByFriendAccount,
  };
};
