import {
  DeleteButton,
  EditButton,
  FormDataConsumer,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import {
  TgMsgFileType,
  TgMsgFileTypeChoices,
} from "../../model/TgMsgGroupModel";
import { MyImageInput } from "../../components/MyImageInput";
import { MyVideoInput } from "../../components/MyVideoInput";
import MessageIcon from "@mui/icons-material/Message";

const fields: MyField[] = [
  Field.objectId({}),
  Field.text({
    label: "名称",
    source: "name",
  }),
  Field.text({
    label: "备注",
    source: "remarks",
    isFilter: false,
  }),
  Field.iterator({
    label: "消息",
    source: "infoList",
    fullWidth: true,
    children: (
      <SimpleFormIterator
        fullWidth
        inline
        getItemLabel={(index) => `第${index + 1}条`}
      >
        <SelectInput
          source="msgType"
          label={"消息类型"}
          choices={TgMsgFileTypeChoices}
          defaultValue={TgMsgFileType.TEXT}
          isRequired
        />
        <FormDataConsumer>
          {({ formData, scopedFormData, getSource, ...rest }) => {
            let msgType = scopedFormData?.msgType;
            if (msgType === TgMsgFileType.TEXT) {
              return (
                <TextInput
                  label={"文本"}
                  required
                  source={getSource("msgContent")}
                  multiline
                  rows={4}
                />
              );
            } else if (msgType === TgMsgFileType.PHOTO) {
              return (
                <MyImageInput label={""} source={getSource("msgContent")} />
              );
            } else if (msgType === TgMsgFileType.VIDEO) {
              return (
                <MyVideoInput label={""} source={getSource("msgContent")} />
              );
            }
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    ),
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        fields={fields}
        rowActions={[<EditButton />, <DeleteButton />]}
        // groupingColDef={groupingColDef}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <MessageIcon />,
};

export default resource;
