import { useEffect, useRef, useState } from "react";
import { AuthStateModel, TgAccountApi } from "../../../api/TgAccountApi";
import { TgClientSession, TgProtocolApi } from "../../../api/TgProtocolApi";
import { useChatAccount } from "./ChatDataContext";

export interface AuthStateProps {}

export function useAuthSate() {
  const { select: account } = useChatAccount();
  const intervalValue = useRef<NodeJS.Timeout>();
  const [authState, setAuthState] = useState<TgClientSession>();

  useEffect(() => {
    if (account) {
      clearInterval(intervalValue.current);
      intervalValue.current = setInterval(async () => {
        const state = await TgProtocolApi.accountLoginState(account.id);
        setAuthState(state);
      }, 2000);
    }

    return () => {
      clearInterval(intervalValue.current);
    };
  }, [account]);

  return {
    authState,
  };
}
