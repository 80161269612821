import { WrapperField } from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import AmountButton from "./AmountButton";
import { Field } from "../../utils/Field";
import React from "react";
import { UserWallet } from "../../model/UserWallet";
import WalletIcon from "@mui/icons-material/Wallet";
import { MyDatagrid } from "../../components/core/MyDatagrid";

const fields: MyField<UserWallet>[] = [
  Field.objectId(
    {},
    {
      isList: false,
    }
  ),
  Field.userField(),
  Field.text({
    label: "货币",
    source: "coinSymbol",
  }),
  Field.text(
    {
      label: "余额",
      source: "balance",
    },
    {
      isFilter: false,
    }
  ),
  Field.text(
    {
      label: "锁定余额",
      source: "frozenBalance",
    },
    {
      isFilter: false,
    }
  ),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

export const UserWalletTable = () => {
  return (
    <MyDatagrid
      fields={fields}
      rowActions={[
        <AmountButton type={"Add"} />,
        <AmountButton type={"Deduct"} />,
      ]}
    />
  );
};

const resource = {
  list: () => {
    return (
      <MyList
        fields={fields}
        filterDefaultValues={{
          coinSymbol: "USDT",
        }}
        rowActions={[
          <AmountButton type={"Add"} />,
          <AmountButton type={"Deduct"} />,
        ]}
      />
    );
  },
  // create: () => {
  //   return <MyCreate fields={fields} />;
  // },
  // edit: () => {
  //   return (
  //     <MyEdit
  //       transform={(data) => {
  //         if (data?.password.length === 0) {
  //           return omit(data, ["password"]);
  //         }
  //         return data;
  //       }}
  //       fields={fields}
  //     />
  //   );
  // },
  icon: <WalletIcon />,
};
export default resource;
