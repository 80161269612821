import { Close } from "@mui/icons-material";
import { Button, Card, IconButton, TextField, Tooltip } from "@mui/material";
import React from "react";
import { useChatFilters } from "../hook/ChatDataContext";

export const TgAccountSearchBtn = () => {
  const [open, setOpen] = React.useState(false);
  const [ac, setAc] = React.useState("");
  const setChatFilter = useChatFilters();

  const handleSearch = () => {
    let filters = { phone: ac };
    console.log("search --- ", ac);
    setChatFilter(filters, undefined, false);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Tooltip
      title={
        <Card
          sx={{
            display: "flex",
            padding: 2,
            "& .MuiPaper-root": {
              padding: 4,
            },
          }}
        >
          <TextField
            variant="outlined"
            placeholder={"请输入账号"}
            sx={{
              "& .MuiFormControl-root": {
                marginTop: 0,
                marginBottom: 0,
              },
            }}
            resource={ac}
            value={ac}
            onChange={(e) => setAc(e.target.value)}
          />
          {/*<FilterLiveSearch source="q" label="Search" />*/}
          <Button
            size="small"
            variant="contained"
            sx={{
              width: "32px",
              fontSize: "12px",
              minWidth: "48px",
              margin: "4px",
            }}
            onClick={handleSearch}
          >
            搜索
          </Button>
          <IconButton
            onClick={handleTooltipClose}
            sx={{
              width: "28px",
              height: "28px",
            }}
          >
            <Close />
          </IconButton>
        </Card>
      }
      arrow
      placement={"right"}
      onClose={handleTooltipClose}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      sx={{
        "& .MuiTooltip-tooltip": {
          padding: 0,
        },
      }}
    >
      <Button
        size="small"
        variant="contained"
        sx={{
          width: "32px",
          fontSize: "12px",
          minWidth: "48px",
          margin: "4px",
        }}
        onClick={handleTooltipOpen}
      >
        搜索
      </Button>
    </Tooltip>
  );
};
