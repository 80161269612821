import { CommandProps } from "../../../components/command/Command";
import {
  BaseCommand,
  BaseCommandProps,
} from "../../../components/command/BaseCommand";
import { useRecordContext } from "ra-core";
import { CloudSync } from "@mui/icons-material";
import React from "react";
import get from "lodash/get";
import { useConfirmForm } from "../../../hook/ConfirmContext";
import { TransStateType } from "../../../model/TgTdataGroupModel";
import { useNotify, useRefresh } from "react-admin";
import { TgTdataGroupApi } from "../../../api/TgTdataGroupApi";

export interface StartTransCommandProps extends CommandProps {}

StartTransCommand.defaultProps = {
  enableTool: false,
  enableRow: true,
  permission: "update",
} as BaseCommandProps;

export function StartTransCommand(props: StartTransCommandProps) {
  const { ...rest } = props;
  const record = useRecordContext(props);
  const id = get(record, "id");
  const transState = get(record, "transState");
  const confirm = useConfirmForm();
  const notify = useNotify();
  const refresh = useRefresh();
  return (
    <BaseCommand
      command={{
        isEnable: (rowData: any) => {
          return rowData.transState !== TransStateType.END;
        },
        label: (r) => {
          if (r.transState === TransStateType.INIT) {
            return "开始转换";
          } else if (r.transState === TransStateType.RUNNING) {
            return "停止转换";
          } else {
            return "转换结束";
          }
        },
        icon: <CloudSync />,
        onHandle: async () => {
          const form = await confirm({
            title:
              record.transState === TransStateType.INIT
                ? "开始转换"
                : "停止转换",
          });
          //
          if (form) {
            if (transState === TransStateType.END) {
              notify("任务已经结束， 不能再次开启");
              return;
            }
            if (transState === TransStateType.INIT) {
              await TgTdataGroupApi.startTrans(id as string);
            } else {
              await TgTdataGroupApi.stopTrans(id as string);
            }
            await refresh();
          }
          return true;
        },

        // to: (record, rd) => `#/${rd?.name}/create`,
      }}
      {...rest}
    />
  );
}
