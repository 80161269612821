import React, { createContext, useContext, useEffect } from "react";
import { Admin } from "../model/Admin";
import { AccountApi } from "../api/AccountApi";
import { Loading } from "react-admin";

interface ContextCurrentAdmin {
  admin: Admin;
  setAdmin: (admin: Admin) => void;
}

const CurrentAdminContext = createContext<ContextCurrentAdmin | undefined>(
  undefined
);

export const CurrentAdminProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [admin, setAdmin] = React.useState<Admin>();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    async function fetchAdmin() {
      const a = await AccountApi.getCurrent();
      setAdmin(a);
      setLoading(false);
    }

    fetchAdmin();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <CurrentAdminContext.Provider
      value={{
        admin: admin!,
        setAdmin: setAdmin,
      }}
    >
      {children}
    </CurrentAdminContext.Provider>
  );
};

export const useCurrentAdmin = (): ContextCurrentAdmin => {
  const context = useContext(CurrentAdminContext);
  if (!context) {
    throw new Error(
      "useCurrentAdmin must be used within a CurrentAdminProvider"
    );
  }
  return context;
};

export function useAdmin() {
  const { admin } = useCurrentAdmin();
  return admin;
}

export function useSetAdmin() {
  const { setAdmin } = useCurrentAdmin();
  return setAdmin;
}
