import { CommandProps } from "../../components/command/Command";
import {
  BaseCommand,
  BaseCommandProps,
} from "../../components/command/BaseCommand";
import { useRecordContext } from "ra-core";
import { CloudSync } from "@mui/icons-material";
import React from "react";
import get from "lodash/get";
import { AssistantApi } from "../../api/AssistantApi";

export interface SyncCommandProps extends CommandProps {}

SyncCommand.defaultProps = {
  enableTool: false,
  enableRow: true,
  permission: "update",
} as BaseCommandProps;

export function SyncCommand(props: SyncCommandProps) {
  const { ...rest } = props;
  const record = useRecordContext(props);
  const id = get(record, "id");
  return (
    <BaseCommand
      command={{
        onHandle: async () => {
          await AssistantApi.sync(id);
        },
        label: "同步",
        icon: <CloudSync />,
        // to: (record, rd) => `#/${rd?.name}/create`,
      }}
      {...rest}
    />
  );
}
