import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import {
  DefaultCommands,
  ResourceType,
} from "../../components/core/MyResource";
import { MyDatagridSelect } from "../../components/core/MyDatagrid";
import { RiChat1Line } from "@remixicon/react";
import { SyncCommand } from "./SyncCommand";
import { MyChatList } from "./MyChatList";

const fields: MyField[] = [
  Field.id(),
  Field.text({ label: "账号", source: "account" }),
];

export const AssistantTable = (props: any) => {
  return <MyDatagridSelect fields={fields} rowActions={[]} />;
};
const resource: ResourceType = {
  commands: [...DefaultCommands, <SyncCommand />],
  list: () => {
    return <MyChatList />;
  },
  icon: <RiChat1Line />,
};
export default resource;
