import * as React from "react";
import { FC, ReactNode, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
import IconButton from "@mui/material/IconButton";
import { useTranslate } from "ra-core";
import { SvgIconProps } from "@mui/material";
export interface FileItem {
  data: string;
  name: string;
  src?: string;
}

export const MyFileInputPreview = (props: FileInputPreviewProps) => {
  const {
    children,
    className,
    onRemove,
    file,
    removeIcon: RemoveIcon = RemoveCircle,
    ...rest
  } = props;

  const translate = useTranslate();

  return (
    <Root className={className} {...rest}>
      <IconButton
        className={FileInputPreviewClasses.removeButton}
        onClick={onRemove}
        aria-label={translate("ra.action.delete")}
        title={translate("ra.action.delete")}
        size="small"
      >
        <RemoveIcon className={FileInputPreviewClasses.removeIcon} />
      </IconButton>
      {children}
    </Root>
  );
};

MyFileInputPreview.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  file: PropTypes.object,
  onRemove: PropTypes.func.isRequired,
  removeIcon: PropTypes.elementType,
};

const PREFIX = "MyRaFileInputPreview";

const FileInputPreviewClasses = {
  removeButton: `${PREFIX}-removeButton`,
  removeIcon: `${PREFIX}-removeIcon`,
};

const Root = styled("div", {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${FileInputPreviewClasses.removeButton}`]: {},

  [`& .${FileInputPreviewClasses.removeIcon}`]: {
    color: theme.palette.error.main,
  },
}));

export interface FileInputPreviewProps {
  children: ReactNode;
  className?: string;
  onRemove: () => void;
  file: FileItem;
  removeIcon?: FC<SvgIconProps>;
}
