import React, { useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";
import { Message, TgProtocolApi } from "../../../api/TgProtocolApi";
import { useChatAccount } from "../hook/ChatDataContext";
import {
  base64ToArrayBuffer,
  base64ToBlobUrl,
} from "../../../utils/fileToBase64";
import { Loading, useNotify } from "react-admin";
import { useTgChatFriend } from "../hook/TgAccountDataContext";
import Link from "@mui/material/Link";
import { LoadingButton } from "@mui/lab";

interface MessageContentProps {
  message: Message;
}

export const MessageContent = ({ message }: MessageContentProps) => {
  const { select } = useChatAccount();
  const { select: friend } = useTgChatFriend();
  const notify = useNotify();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const intervalRef = useRef<NodeJS.Timeout>();
  const [download, setDownload] = useState(false);

  const downfile = async () => {
    setDownload(true);
    const res = await TgProtocolApi.downMedia({
      clientId: `${select?.id}`,
      userId: `${friend?.id}`,
      msgId: `${message.id}`,
    });
    if (res.status === 1) {
      const fileBytes = base64ToArrayBuffer(res.tgFile?.fileContent ?? "");
      // 创建 Blob 对象
      const blob = new Blob([fileBytes], { type: "application/octet-stream" });

      // 创建下载链接
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = message.file_name ?? "empty.txt"; // 指定下载的文件名
      document.body.appendChild(a);
      a.click();

      // 移除下载链接
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } else {
      notify("文件还没准备好");
    }
    setDownload(false);
  };

  useEffect(() => {
    if (message.msg_type === 1 || message.msg_type === 5) {
      setLoading(false);
    } else {
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(async () => {
        const res = await TgProtocolApi.downMedia({
          clientId: `${select?.id}`,
          userId: `${friend?.id}`,
          msgId: `${message.id}`,
        });
        if (res.status === 1) {
          const url = base64ToBlobUrl(res.tgFile?.fileContent ?? "", "");
          setData({ src: url });
          setLoading(false);
          clearInterval(intervalRef.current);
        }
      }, 2000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [message]);

  if (loading) {
    return <Loading />;
  }

  switch (message.msg_type) {
    case 1: // 文字消息
      return (
        <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
          {message.message}
        </Typography>
      );
    case 2: // 图片消息
      return (
        <img
          {...data}
          alt="image"
          style={{ maxWidth: "200px", borderRadius: "8px" }}
        />
      );
    case 4: // 视频消息
      return (
        <video {...data} controls style={{ width: "200px", height: "100px" }} />
      );
    case 3: // 语音消息
      return (
        <audio controls style={{ width: "200px" }}>
          <source {...data} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      );
    case 5: // 文件
      return (
        <LoadingButton variant="text" loading={download} onClick={downfile}>
          {message.file_name}
        </LoadingButton>
      );
    default:
      return (
        <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
          Unsupported message type
        </Typography>
      );
  }
};
