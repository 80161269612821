import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import {
  AutocompleteInput,
  EditBase,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ShowBase,
  SimpleForm,
  TextField,
  useChoicesContext,
  useNotify,
  useRecordContext,
  useUpdate,
} from "react-admin";
import React from "react";
import { useAdmin, useSetAdmin } from "../../../hook/useCurrentAdmin";
import { useWatch } from "react-hook-form";
import { AssistantAiModel } from "../../../model/Assistant";
import get from "lodash/get";

function AdminAssistant() {
  const admin = useAdmin();
  const setAdmin = useSetAdmin();
  const [open, setOpen] = React.useState(false);
  const notify = useNotify();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onEditSuccess = async () => {
    console.log("onEditSuccess");
    handleClose();
  };

  const [update, { isLoading, error }] = useUpdate();

  const handleSubmit = async (e: Record<string, any>) => {
    console.log("handleSubmit: ", e);
    await update(
      "admins",
      {
        id: admin.id,
        data: e,
        previousData: admin,
      },
      {
        onSuccess: () => {
          admin.assistantApiId = e.assistantApiId;
          admin.nativeApiModeName = e.nativeApiModeName;
          setAdmin(admin);
          notify("修改成功");
          handleClose();
        },
      }
    );
  };

  return (
    <>
      <ShowBase id={admin.id} resource={"admins"}>
        <Stack
          direction={"row"}
          spacing={1}
          border="#eee solid 1px"
          padding={1}
        >
          <Chip
            size={"small"}
            label={
              <Box display={"flex"} justifyContent={"center"}>
                AI助理:
                <ReferenceField
                  source={"assistantApiId"}
                  reference="assistantApis"
                  label="AI助理"
                  emptyText={"-"}
                >
                  <TextField source="name" />
                </ReferenceField>
              </Box>
            }
            variant="outlined"
          />
          <Chip
            size={"small"}
            label={`模型: ${admin.nativeApiModeName ?? "-"}`}
            variant="outlined"
          />
          <Button
            variant={"contained"}
            // loading={translateIng}
            onClick={handleClickOpen}
            size={"small"}
            sx={{
              p: "6px 8px",
              lineHeight: "1.2",
              fontSize: "12px",
              minWidth: "min-content",
            }}
          >
            修改
          </Button>
        </Stack>
      </ShowBase>
      {open && (
        <Dialog open={open} fullWidth maxWidth={"xs"} onClose={handleClose}>
          <DialogTitle id="scroll-dialog-title">AI助手</DialogTitle>
          <DialogContent dividers>
            <EditBase
              id={admin.id}
              resource={"admins"}
              redirect={false}
              mutationOptions={{ onSuccess: onEditSuccess }}
            >
              <SimpleForm onSubmit={(e) => handleSubmit(e)}>
                <ReferenceInput
                  source={"assistantApiId"}
                  reference="assistantApis"
                  label={"AI助理"}
                >
                  <AssistantApiSelect />
                </ReferenceInput>
              </SimpleForm>
            </EditBase>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

function AssistantApiSelect() {
  const { allChoices } = useChoicesContext();
  const newAssistantApiId = useWatch({ name: "assistantApiId" });
  const [modeNameChoices, setModeNameChoices] = React.useState([]);
  const record = useRecordContext();

  React.useEffect(() => {
    changeAssistantApiId(newAssistantApiId);
  }, [newAssistantApiId]);

  React.useEffect(() => {
    if (allChoices?.length > 0) {
      changeAssistantApiId(record.assistantApiId);
    }
  }, [record, allChoices]);

  const changeAssistantApiId = (newAssistantApiId?: string) => {
    if (newAssistantApiId) {
      let mnc = allChoices
        .find((c) => c.id === newAssistantApiId)
        ?.aiModels?.map((m: AssistantAiModel) => ({
          id: m.nativeApiModeName,
          name: m.nativeApiModeName,
        }));
      setModeNameChoices(mnc ?? []);
    } else {
      setModeNameChoices([]);
    }
  };

  let optionText = (choice?: any) => {
    let value = get(choice, "name");
    return value // the empty choice is { id: '' }
      ? `${value}`
      : "";
  };

  return (
    <>
      <AutocompleteInput
        label={"AI助理"}
        optionText={optionText}
        name={"assistantApiId"}
      />
      <SelectInput choices={modeNameChoices} source="nativeApiModeName" />
    </>
  );
}

export default AdminAssistant;
