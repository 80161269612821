import { useInput } from "ra-core";
import * as React from "react";
import { useEffect } from "react";
import { CommonInputProps } from "react-admin";
import Box from "@mui/material/Box";
import { InputLabel, Paper } from "@mui/material";
import { VideoUpload } from "./VideoUpload";
import { isEmpty } from "lodash";

export interface MyVideoInputProps extends CommonInputProps {
  className?: string;
}

export function MyVideoInput(props: MyVideoInputProps) {
  const {
    className,
    defaultValue = "",
    label,
    helperText,
    onBlur,
    onChange,
    parse,
    resource,
    source,
    validate,
    ...rest
  } = props;

  const {
    field,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
    id,
    isRequired,
  } = useInput({
    defaultValue,
    parse,
    resource,
    source,
    type: "text",
    validate,
    onBlur,
    onChange,
    ...rest,
  });

  const { value } = field;

  useEffect(() => {
    if (value === "") {
      field.onChange();
    }
  }, [value]);

  function changeImage(url: string) {
    field.onChange(url);
  }

  return (
    <Box sx={{ p: "8px 0", width: "100%", maxWidth: "500px" }}>
      <InputLabel>{label}</InputLabel>
      <Paper
        sx={{
          display: "flex",
          width: "100%",
          padding: "8px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "left",
        }}
      >
        <Box sx={{ width: "max-content", "& img": { width: "64px" } }}>
          <VideoUpload
            defaultVideo={value}
            onSuccess={(e) => e && !isEmpty(e) && changeImage(e[0])}
            id={source}
          />
        </Box>
      </Paper>
    </Box>
  );
}
