import React, { createContext, useContext } from "react";
import { useAuthSate } from "./useAuthSate";
import { TgClientSession } from "../../../api/TgProtocolApi";

export interface TgAuthStateData {
  authState?: TgClientSession;
}

const TgAuthStateContext = createContext<TgAuthStateData>({});

export const TgAuthStateProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { authState } = useAuthSate();

  return (
    <TgAuthStateContext.Provider value={{ authState }}>
      {children}
    </TgAuthStateContext.Provider>
  );
};

export const useTgAuthState = (): TgAuthStateData => {
  const context = useContext(TgAuthStateContext);
  if (!context) {
    throw new Error("useTgAuthState must be used within a TgAuthStateContext");
  }
  return context;
};

export const useTgAuthStateData = (): TgClientSession | undefined => {
  const { authState } = useTgAuthState();
  return authState;
};
