import { Box, Typography } from "@mui/material";

function NeedRecon() {
  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      rowGap={"16px"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Typography variant={"h5"}>登录异常，需要手动登录</Typography>
      <Typography>鼠标放在头像上， 显示重新连接按钮</Typography>
      <Typography>检查错误原因，并重新连接</Typography>
    </Box>
  );
}

export default NeedRecon;
