import { useCallback, useState } from "react";
import { ChatApi } from "../../../api/ChatApi";
import { CustomerMessage } from "../../../model/chatData";
import { useChatHistory } from "./ChatDataContext";

export function useTranslateItem(friend: CustomerMessage) {
  const [loading, setLoading] = useState(false);
  const { refetch } = useChatHistory();

  const handleSend = useCallback(async () => {
    setLoading(true);
    try {
      await ChatApi.translateItem(friend);
    } finally {
      setLoading(false);
    }
  }, [friend]);

  let translateItem = async function () {
    try {
      await handleSend();
    } finally {
      await refetch();
    }
  };
  return {
    translateItem,
    loading,
  };
}
