import React from "react";
import { AutocompleteInput, ReferenceInput, TextInput } from "react-admin";
import get from "lodash/get";

export const UserSetting: React.FC = (props) => {
  let optionText = (choice?: any) => {
    let value = get(choice, "defaultTitle");
    return value // the empty choice is { id: '' }
      ? `${value}`
      : "";
  };

  /*
  var xhUsername: String = "nplbtk",
    var xhPwd: String = "cainiao888",
    var defaultCustomerSupport: String = "cainiao888",
   */

  return (
    <>
      <ReferenceInput
        reference={"userTypes"}
        label="defaultTitle"
        referenceLabel="defaultTitle"
        source="setting.defaultUserType"
        fullWidth
      >
        <AutocompleteInput
          label="默认用户类型"
          optionText={optionText}
        ></AutocompleteInput>
      </ReferenceInput>
      <TextInput label={"星河账号"} source={"setting.xhUsername"} />
      <TextInput label={"星河密码"} source={"setting.xhPwd"} />
      <TextInput
        label={"默认客服账号"}
        source={"setting.defaultCustomerSupport"}
        helperText={"在没有可用客服时, 使用该ws账户"}
      />
    </>
  );
};
