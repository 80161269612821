export interface TgTdataGroupModel {}

export enum TransStateType {
  INIT = "INIT",
  RUNNING = "RUNNING",
  END = "END",
}

export const TransStateTypeChoices = [
  { id: "INIT", name: "未开始" },
  { id: "RUNNING", name: "运行中" },
  { id: "END", name: "运行结束" },
];
