import Box from "@mui/material/Box";
import { Tabs } from "@mui/material";
import { AccountTab } from "./compoents/AccountTab";
import { GroupList } from "./compoents/GroupList";
import { FriendList } from "./compoents/FriendList";
import { ToolPanel } from "./compoents/ToolPanel";
import React from "react";
import { useChatAccount } from "./hook/ChatDataContext";
import { ChatView } from "./compoents/ChatView";
import { AccountSearchBtn } from "./compoents/AccountSearchBtn";

export const ChatContent = () => {
  const { selectIndex, list, changeSelect, refetch } = useChatAccount();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    changeSelect(newValue);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        border: "1px solid #ccc",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          bgcolor: "background.paper",
          height: "calc(100vh - (41px + 48px))",
          "& .MuiTab-root": {
            width: "48px",
            minWidth: "48px",
            margin: "4px",
            height: "48px",
            minHeight: "48px",
            border: "1px solid #ccc",
            borderRadius: "48px",
          },
          "& .MuiTouchRipple-root": {},
          "& .Mui-selected": {
            // backgroundColor: alpha(theme.palette.success.light, 0.1),
          },
          "& .MuiTabs-indicator": {
            // display: "none",
          },
        })}
      >
        <AccountSearchBtn />
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={selectIndex}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
          scrollButtons
        >
          {list && [
            <AccountTab
              refetch={refetch}
              key={0}
              label={"未读"}
              isRead={true}
            />,
            ...list.map((item, index: number) => (
              <AccountTab
                account={item}
                refetch={refetch}
                key={index + 1}
                label={index + 1}
                isRead={item?.isRead !== 2}
              />
            )),
          ]}
        </Tabs>
      </Box>
      {selectIndex !== 0 && (
        <Box
          sx={(theme) => ({
            display: "flex",
            bgcolor: "background.paper",
            overflow: "auto",
            height: "calc(100vh - (41px + 48px))",
            borderRight: "1px solid #ccc",
          })}
        >
          <GroupList />
        </Box>
      )}
      <Box
        sx={(theme) => ({
          display: "flex",
          bgcolor: "background.paper",
          overflow: "auto",
          height: "calc(100vh - (41px + 48px))",
          borderRight: "1px solid #ccc",
        })}
      >
        <FriendList accountIndex={selectIndex} />
      </Box>
      <Box
        flex={1}
        sx={(theme) => ({
          display: "flex",
          bgcolor: "background.paper",
          overflow: "auto",
          height: "calc(100vh - (41px + 48px))",
          borderRight: "1px solid #ccc",
        })}
      >
        <ChatView></ChatView>
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          bgcolor: "background.paper",
          overflow: "auto",
          height: "calc(100vh - (41px + 48px))",
          borderRight: "1px solid #ccc",
        })}
      >
        <ToolPanel />
      </Box>
    </Box>
  );
};
