import React from "react";
import { useListController } from "ra-core";
import { ChatAccountCustomer } from "../../model/chatData";
import { ChatDataProvider } from "./hook/ChatDataContext";
import { ChatContent } from "./ChatContent";
import { CurrentAdminProvider } from "../../hook/useCurrentAdmin";

export interface MyChatListProps {}

export function MyChatList(props: MyChatListProps) {
  const { data, setFilters, refetch } = useListController<ChatAccountCustomer>({
    sort: { field: "published_at", order: "DESC" },
    perPage: 10,
  });

  return (
    <CurrentAdminProvider>
      <ChatDataProvider
        accounts={data}
        setChatFilters={setFilters}
        accountRefresh={refetch}
      >
        <ChatContent />
      </ChatDataProvider>
    </CurrentAdminProvider>
  );
}
