import React from "react";
import { SimpleShowLayout } from "react-admin";
// import MobileGrid from "./MobileGrid";
import { RaRecord } from "ra-core";
import isUndefined from "lodash/isUndefined";
import { ExcludeProps, MyField } from "./types";
import omit from "lodash/omit";
import { Box, Stack } from "@mui/material";

export interface MyShowFieldsProp<RecordType extends RaRecord = any> {
  fields: MyField<RecordType>[];
}

const MyShowFields = <RecordType extends RaRecord = any>(
  props: MyShowFieldsProp<RecordType>
) => {
  const { fields } = props;
  return (
    <SimpleShowLayout>
      {fields
        .filter((f) => (f.isList || isUndefined(f.isList)) && f.component)
        .map((field: MyField, index) => {
          const {
            component: FieldComponent,
            source,
            label,
            props,
            sortable = false,
          } = field;

          if (!FieldComponent) {
            return <></>;
          }

          return (
            <Stack direction={"row"} key={`${index}`}>
              <Box width={"140px"}>{label}:</Box>
              <Box>
                <FieldComponent
                  source={source}
                  label={label}
                  sortable={sortable}
                  {...omit(props, ExcludeProps)}
                />
              </Box>
            </Stack>
          );
        })}
    </SimpleShowLayout>
  );
};

export { MyShowFields };
