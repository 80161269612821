import { CommandProps } from "../../../components/command/Command";
import {
  BaseCommand,
  BaseCommandProps,
} from "../../../components/command/BaseCommand";
import { DeleteOutline } from "@mui/icons-material";
import React from "react";
import { useConfirm } from "../../../hook/ConfirmContext";
import { useNotify } from "react-admin";
import { TgProtocolApi } from "../../../api/TgProtocolApi";

export interface TgAcDelDeactivatedCommandProps extends CommandProps {}

TgAcDelDeactivatedCommand.defaultProps = {
  enableTool: true,
  enableRow: false,
  permission: "update",
} as BaseCommandProps;

export function TgAcDelDeactivatedCommand(
  props: TgAcDelDeactivatedCommandProps
) {
  const { ...rest } = props;
  const confirm = useConfirm();
  const notify = useNotify();
  return (
    <BaseCommand
      command={{
        isEnable: (rowData: any) => {
          return true;
        },
        label: (r) => {
          return "一键删除冻结账号";
        },
        icon: <DeleteOutline />,
        onHandle: async () => {
          let title = "一键删除冻结账号";
          const form = await confirm({
            title: title,
            description: <p>所有提示&lt;session被禁用&gt;的账号都会被删除</p>,
          });
          if (form) {
            const count = await TgProtocolApi.delAllDeactivated();
            notify(`删除成功!`);
          }
          return true;
        },
      }}
      {...rest}
    />
  );
}
