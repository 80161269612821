import { CommandProps } from "../../../components/command/Command";
import {
  BaseCommand,
  BaseCommandProps,
} from "../../../components/command/BaseCommand";
import { useRecordContext } from "ra-core";
import { CloudSync } from "@mui/icons-material";
import React from "react";
import get from "lodash/get";
import { useConfirmForm } from "../../../hook/ConfirmContext";
import { TransStateType } from "../../../model/TgTdataGroupModel";
import { useNotify, useRefresh } from "react-admin";
import { TaskOverType } from "../../../model/TgGroupMsgTaskModel";
import { TgGroupMsgTaskApi } from "../../../api/TgGroupMsgTaskApi";

export interface StartTransCommandProps extends CommandProps {}

StartStopCommand.defaultProps = {
  enableTool: false,
  enableRow: true,
  permission: "update",
} as BaseCommandProps;

export function StartStopCommand(props: StartTransCommandProps) {
  const { ...rest } = props;
  const record = useRecordContext(props);
  const id = get(record, "id");
  const taskState = get(record, "taskState");
  const confirm = useConfirmForm();
  const notify = useNotify();
  const refresh = useRefresh();
  return (
    <BaseCommand
      command={{
        isEnable: (rowData: any) => {
          return rowData.taskState !== TaskOverType.SUCCESS;
        },
        label: (r) => {
          if (r.taskState === TaskOverType.INIT) {
            return "开始执行";
          } else if (r.taskState === TaskOverType.RUNNING) {
            return "停止";
          } else if (r.taskState === TaskOverType.ERROR) {
            return "继续执行";
          } else if (r.taskState === TaskOverType.SUCCESS) {
            return "执行完成";
          }
        },
        icon: <CloudSync />,
        onHandle: async () => {
          let title = "";
          if (taskState === TaskOverType.INIT) {
            title = "开始执行";
          } else if (taskState === TaskOverType.RUNNING) {
            title = "停止";
          } else if (taskState === TaskOverType.ERROR) {
            title = "继续执行";
          }
          const form = await confirm({
            title: title,
          });
          //
          if (form) {
            if (taskState === TransStateType.END) {
              notify("任务已经结束， 不能再次开启");
              return;
            }
            if (
              taskState === TaskOverType.INIT ||
              taskState === TaskOverType.ERROR
            ) {
              await TgGroupMsgTaskApi.manualStart(id as string);
            } else {
              await TgGroupMsgTaskApi.manualStop(id as string);
            }
            await refresh();
          }
          return true;
        },

        // to: (record, rd) => `#/${rd?.name}/create`,
      }}
      {...rest}
    />
  );
}
