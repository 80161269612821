import React, { createContext, useContext } from "react";
import { ContextData } from "./data";
import { useAccounts } from "./useAccounts";
import { useCurrentAdmin } from "../../../hook/useCurrentAdmin";
import { TgAccount } from "../../../model/tgProtocolChatData";
import { useReqTgUnReadFriend } from "./useReqTgUnReadFriend";
import { TgUnreadModel } from "../../../api/TgProtocolApi";

interface ChatData {
  accountData: ContextData<TgAccount>;
  unReadFriend: ContextData<TgUnreadModel>;
  setChatFilters: (
    filters: any,
    displayedFilters: any,
    debounce?: boolean
  ) => void;
}

const ChatDataContext = createContext<ChatData | undefined>(undefined);

export const ChatDataProvider: React.FC<{
  children: React.ReactNode;
  accounts?: TgAccount[];
  setChatFilters: (
    filters: any,
    displayedFilters: any,
    debounce?: boolean
  ) => void;
  accountRefresh: () => void;
}> = ({ children, accounts, setChatFilters, accountRefresh }) => {
  const { admin } = useCurrentAdmin();
  const accountData = useAccounts(accounts, accountRefresh);

  const unReadFriend = useReqTgUnReadFriend({});

  return (
    <ChatDataContext.Provider
      value={{
        accountData,
        unReadFriend,
        setChatFilters,
      }}
    >
      {children}
    </ChatDataContext.Provider>
  );
};

export const useChatData = (): ChatData => {
  const context = useContext(ChatDataContext);
  if (!context) {
    throw new Error("useChatData must be used within a ChatDataProvider");
  }
  return context;
};

export function useChatAccount() {
  const { accountData } = useChatData();
  return accountData;
}

export function useChatFilters() {
  const { setChatFilters } = useChatData();
  return setChatFilters;
}

export function useTgUnReadFriend() {
  const { unReadFriend } = useChatData();
  return unReadFriend;
}
