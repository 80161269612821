import AuthPage from "./compoents/AuthPage";
import TgChatPage from "./compoents/TgChatPage";
import { Loading } from "react-admin";
import { ReactNode, useEffect, useState } from "react";
import { useChatAccount } from "./hook/ChatDataContext";

export interface TgChatMainContentProps {}

function TgChatMainContent({}: TgChatMainContentProps) {
  const { select: account } = useChatAccount();
  const [mainPage, setMainPage] = useState<ReactNode>();

  useEffect(() => {
    if (account?.status !== 2) {
      setMainPage(<AuthPage />);
    } else {
      setMainPage(<TgChatPage />);
    }
  }, [account, account?.status]);

  // TODO account 不存在， 只有是选中未读的时候， 这个不能这样处理， 必须要修改
  if (!account) return <Loading />;

  return <>{mainPage}</>;
}

export default TgChatMainContent;
