import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";
import {
  AnswerForm,
  AskItem,
  ChatGroup,
  ChatUpBookForm,
  ChatUpTopForm,
  CustomerFriendForm,
  CustomerFriendList,
  CustomerMessage,
  CustomerMessageForm,
  CustomerMessageList,
  OptimizeMessageForm,
  SendMsgForm,
  SendMsgResponse,
} from "../model/chatData";

export const ChatApi = {
  getChatGroupList: async (
    account: string,
    customerId: string,
    name: string
  ): Promise<ChatGroup[]> => {
    let { data } = await fetchJson(`${API_URL}/chats/chatGroupList`, {
      method: "POST",
      body: new URLSearchParams({
        account,
        customerId,
        name,
      }),
    });
    return data;
  },

  getCustomerFriendList: async (
    form: CustomerFriendForm
  ): Promise<CustomerFriendList> => {
    let { data } = await fetchJson(`${API_URL}/chats/customerFriendList`, {
      method: "POST",
      body: new URLSearchParams({
        ...form,
      }),
    });
    return data;
  },
  getCustomerUnReadList: async (
    form: CustomerFriendForm
  ): Promise<CustomerFriendList> => {
    let { data } = await fetchJson(`${API_URL}/chats/customerUnReadList`, {
      method: "POST",
      body: new URLSearchParams({
        ...form,
      }),
    });
    return data;
  },

  getCustomerMessageList: async (
    form: CustomerMessageForm
  ): Promise<CustomerMessageList> => {
    let { data } = await fetchJson(`${API_URL}/chats/customerMessageList`, {
      method: "POST",
      body: new URLSearchParams({
        page: form?.page?.toString() || "1",
        limit: form?.limit?.toString() || "100",
        friendAccount: form.friendAccount,
        account: form.account,
        avatar: form.avatar || "",
        head: form.head || "",
      }),
    });
    return data;
  },
  doNowTranslateMessage: async (
    form: CustomerMessageForm
  ): Promise<CustomerMessageList> => {
    let { data } = await fetchJson(`${API_URL}/chats/doNowTranslateMessage`, {
      method: "POST",
      body: new URLSearchParams({
        page: form?.page?.toString() || "1",
        limit: form?.limit?.toString() || "100",
        friendAccount: form.friendAccount,
        account: form.account,
        avatar: form.avatar || "",
        head: form.head || "",
      }),
    });
    return data;
  },
  translateItem: async (form: CustomerMessage): Promise<CustomerMessage> => {
    let { data } = await fetchJson(`${API_URL}/chats/translateItem`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(form),
    });
    return data;
  },
  doChatUpBook: async (form: ChatUpBookForm): Promise<Boolean> => {
    let { data } = await fetchJson(`${API_URL}/chats/doChatUpBook`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(form),
    });
    return data;
  },
  doChatUpTop: async (form: ChatUpTopForm): Promise<Boolean> => {
    let { data } = await fetchJson(`${API_URL}/chats/doChatUpTop`, {
      method: "POST",
      body: new URLSearchParams({
        account: form.account || "",
        friendAccount: form.friendAccount || "",
        ptype: form.ptype ? form.ptype.toString() : "2",
      }),
    });
    return data;
  },
  doChatCancelUpTop: async (form: ChatUpTopForm): Promise<Boolean> => {
    let { data } = await fetchJson(`${API_URL}/chats/doChatCancelUpTop`, {
      method: "POST",
      body: new URLSearchParams({
        account: form.account || "",
        friendAccount: form.friendAccount || "",
        ptype: form.ptype ? form.ptype.toString() : "2",
      }),
    });
    return data;
  },
  sendMsg: async (form: SendMsgForm): Promise<SendMsgResponse> => {
    let { data } = await fetchJson(`${API_URL}/chats/sendMsg`, {
      method: "POST",
      body: new URLSearchParams({
        msgType: form.msgType.toString(),
        sendAccount: form.sendAccount,
        receiveAccount: form.receiveAccount,
        msgContent: form.msgContent,
        translateText: form.translateText || "",
      }),
    });
    return data;
  },
  doReadMessage: async (
    account: String,
    friendAccount: String
  ): Promise<void> => {
    await fetchJson(
      `${API_URL}/chats/doReadMessage?account=${account}&friendAccount=${friendAccount}`,
      {
        method: "POST",
      }
    );
  },
  delMessage: async (
    dataId: string,
    msgId: string,
    receiveAccount: string,
    sendAccount: string
  ): Promise<void> => {
    await fetchJson(`${API_URL}/chats/delMessage`, {
      method: "POST",
      body: new URLSearchParams({ dataId, msgId, receiveAccount, sendAccount }),
    });
  },
  uploadFile: async (file: string, fileName: string): Promise<string> => {
    let { data } = await fetchJson(`${API_URL}/chats/upload`, {
      method: "POST",
      body: new URLSearchParams({ file, fileName }),
    });
    return data;
  },
  getAnswer: async (form: AnswerForm): Promise<AskItem[]> => {
    let { data } = await fetchJson(`${API_URL}/chats/answer`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(form),
    });
    return data;
  },
  reAnswer: async (form: AnswerForm): Promise<AskItem[]> => {
    let { data } = await fetchJson(`${API_URL}/chats/reAnswer`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(form),
    });
    return data;
  },
  doOptimizeMessageAnswer: async (
    form: OptimizeMessageForm
  ): Promise<AskItem[]> => {
    let { data } = await fetchJson(`${API_URL}/chats/optimize`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(form),
    });
    return data;
  },
};
