import {
  DeleteButton,
  EditButton,
  ImageField,
  TextField,
  TextInput,
} from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { RiApps2AddFill } from "@remixicon/react";
import {
  AppPlatformChoices,
  AppUpdateTypeVersion,
  AppUpdateTypeVersionChoices,
} from "../../model/App";
import { SiteResourceType } from "../../model/SiteResource";
import { MyLocalesValueField } from "../../components/MyLocalesValueField";
import { MyRichTextInput } from "../../components/MyRichTextInput";
import { MyImageInput } from "../../components/MyImageInput";
import { MyImageSwipersValueInput } from "../../components/MyImageSwipersValueInput";
import { MyFileBase64Input } from "../../components/MyFileBase64Input";
import { MyFileField } from "../../components/MyFileField";
import { MyTextInput } from "../../components/MyTextInput";
import { RetrievalFileType } from "../../model/chat";
import { MyFileInput } from "../../components/MyFileInput";

const fields: MyField[] = [
  Field.objectId(),
  Field.ref({
    label: "App",
    source: "appId",
    isRequired: true,
    isList: true,
    isFilter: true,
    reference: "apps",
    referenceLabel: "name",
  }),
  Field.select({
    label: "平台",
    choices: AppPlatformChoices,
    source: "platform",
  }),
  Field.editNumber({
    label: "版本编码",
    helperText: "数字版本号,更新程序会获取版本好最大的版本",
    source: "versionValue",
  }),
  Field.text({
    label: "版本号",
    helperText: "显示给用户的版本号(应该和包内版本号一致)",
    source: "versionName",
  }),
  Field.select({
    label: "更新方式",
    choices: AppUpdateTypeVersionChoices,
    source: "updateType",
  }),
  Field.dependency({
    label: "下载地址",
    fullWidth: true,
    source: "url",
    render(record: any, source?: string) {
      const type: AppUpdateTypeVersion = record.updateType;
      if (!type) {
        return undefined;
      }
      if (type === AppUpdateTypeVersion.URL) {
        return <TextField label={"跳转地址"} source="url" />;
      } else {
        return <MyFileField source="url" />;
      }
    },
    editRender({ formData, rest }) {
      const type: AppUpdateTypeVersion = formData.updateType;
      if (!type) {
        return undefined;
      }
      if (type === AppUpdateTypeVersion.URL) {
        return <MyTextInput label={"跳转地址"} source="url" {...rest} />;
      } else {
        return (
          <MyFileInput
            accept={".apk"}
            maxSize={1000_000_000}
            label={"安装文件"}
            source="url"
            {...rest}
          />
        );
      }
    },
  }),
  Field.richText({
    label: "版本描述",
    source: "desc",
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        pagination={false}
        fields={fields}
        rowActions={[<EditButton />, <DeleteButton />]}
        // groupingColDef={groupingColDef}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <RiApps2AddFill />,
};

export default resource;
