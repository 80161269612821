import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";
import { Identifier } from "react-admin";

export const AssistantApi = {
  sync: async (id: Identifier): Promise<any> => {
    let { data } = await fetchJson(`${API_URL}/assistant/sync/${id}`, {
      method: "POST",
    });
    return data;
  },
};
