import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import { BooleanTypeChoices } from "../../model/Core";
import { MyDatagrid } from "../../components/core/MyDatagrid";
import { ShowCommand } from "../../components/command/ShowCommand";

const fields: MyField[] = [
  Field.objectId({}),
  Field.text({
    label: "账号",
    source: "senderPhone",
  }),
  Field.ref({
    label: "任务",
    source: "taskId",
    reference: "tgGroupMsgTask",
    referenceLabel: "name",
    originSource: "id",
    isFilter: true,
  }),
  Field.text({
    label: "手机号",
    source: "friendPhone",
  }),
  Field.select({
    label: "发送成功",
    source: "sendOk",
    isFilter: true,
    choices: BooleanTypeChoices,
    sortable: true,
  }),
  Field.text({
    label: "备注",
    source: "remarks",
    fullWidth: false,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "完成时间",
    source: "completedTime",
  }),
];

export const TgGroupMsgTaskResTable = () => {
  return <MyDatagrid fields={fields} rowActions={[]} />;
};

const resource = {
  commands: [<ShowCommand />],
  list: () => {
    return <MyList pagination={false} fields={fields} rowActions={[]} />;
  },
  icon: <AutoGraphIcon />,
};

export default resource;
