import React from "react";
import { Box, Typography } from "@mui/material";
import { CustomerMessage } from "../../../model/chatData";

interface MessageContentProps {
  message: CustomerMessage;
}

export const MessageContent = ({ message }: MessageContentProps) => {
  switch (message.msgType) {
    case 1: // 文字消息
      return (
        <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
          {message.msgContent}
        </Typography>
      );
    case 2: // 图片消息
      return (
        <img
          src={message.msgContent}
          alt="image"
          style={{ maxWidth: "200px", borderRadius: "8px" }}
        />
      );
    case 4: // 视频消息
      return (
        <video
          src={message.msgContent}
          controls
          style={{ width: "200px", height: "100px" }}
        />
      );
    case 3: // 语音消息
      return (
        <audio controls style={{ width: "200px" }}>
          <source src={message.msgContent} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      );
    case 5: // 语音消息
      return <>语音呼叫</>;
    default:
      return (
        <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
          Unsupported message type
        </Typography>
      );
  }
};
