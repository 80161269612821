import { ChatAccountCustomer } from "../../../model/chatData";
import { AccountInfo } from "./AccountInfo";
import { Badge, Tab, TabProps, Tooltip } from "@mui/material";
import React, { forwardRef } from "react";
import { Download } from "@mui/icons-material";

export type AccountTabProps = TabProps & {
  account?: ChatAccountCustomer;
  refetch: () => Promise<any>;
  children?: React.ReactNode;
  isRead: boolean;
};

export const AccountTab = forwardRef<HTMLElement, AccountTabProps>(
  ({ account, refetch, isRead, ...tabProps }, ref) => {
    return (
      <Tooltip
        disableHoverListener={!account}
        title={
          account ? <AccountInfo account={account} refetch={refetch} /> : <></>
        }
        arrow
        ref={ref}
        placement={"right"}
        style={{
          backgroundColor: "transparent",
        }}
      >
        <Badge
          invisible={isRead}
          color={"error"}
          overlap="circular"
          badgeContent=" "
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          variant="dot"
        >
          <Badge
            invisible={account?.isTop !== 2}
            badgeContent={
              <Download
                color={"primary"}
                sx={{
                  height: "12px",
                  width: "12px",
                  transform: "translate(10px, -3px)",
                  rotate: "180deg",
                }}
              />
            }
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Tab
              sx={{
                "&.MuiTab-root": {
                  bgcolor:
                    !account || account?.status !== 2 ? "#c2c2c2" : "#fff",
                },
              }}
              {...tabProps}
            />
          </Badge>
        </Badge>
      </Tooltip>
    );
  }
);
