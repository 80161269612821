import React, {
  Children,
  FC,
  isValidElement,
  ReactElement,
  ReactNode,
} from "react";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  DropEvent,
  DropzoneOptions,
  FileRejection,
  useDropzone,
} from "react-dropzone";
import FormHelperText from "@mui/material/FormHelperText";
import {
  RecordContextProvider,
  shallowEqual,
  useInput,
  useTranslate,
} from "ra-core";

import { SxProps } from "@mui/system";
import { SvgIconProps } from "@mui/material";
import {
  CommonInputProps,
  InputHelperText,
  sanitizeInputRestProps,
  useNotify,
} from "react-admin";
import { Labeled } from "ra-ui-materialui";
import { FileItem, MyFileInputPreview } from "./MyFileInputPreview";

function fileToBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function toFileItem(file: any): Promise<FileItem> {
  if (!(file instanceof File)) {
    return Promise.resolve(file);
  }
  return fileToBase64(file).then((data) => ({
    src: URL.createObjectURL(file),
    data: data as string,
    name: file.name,
  }));
}

function toFileItems(files: File[]): Promise<FileItem[]> {
  return Promise.all(files.map(toFileItem));
}

export const MyFileBase64Input = (props: MyFileInputProps) => {
  const {
    accept,
    children,
    className,
    format,
    helperText,
    inputProps: inputPropsOptions,
    maxSize,
    minSize,
    multiple = false,
    label,
    labelMultiple = "ra.input.file.upload_several",
    labelSingle = "ra.input.file.upload_single",
    options = {},
    onRemove: onRemoveProp,
    parse,
    placeholder,
    removeIcon,
    resource,
    source,
    validate,
    validateFileRemoval,
    disabled,
    readOnly,
    ...rest
  } = props;
  const { onDrop: onDropProp } = options;
  const translate = useTranslate();

  const {
    id,
    field: { onChange, onBlur, value },
    fieldState,
    formState: { isSubmitted },
    isRequired,
  } = useInput({
    format: format,
    parse: parse,
    source,
    validate,
    disabled,
    readOnly,
    ...rest,
  });

  const { isTouched, error, invalid } = fieldState;
  const files = value ? (Array.isArray(value) ? value : [value]) : [];
  const notify = useNotify();
  const onDrop = (
    newFiles: File[],
    rejectedFiles: FileRejection[],
    event: DropEvent
  ) => {
    const updatedFiles = multiple ? [...files, ...newFiles] : [...newFiles];

    toFileItems(updatedFiles).then((fileItems) => {
      if (multiple) {
        onChange(fileItems);
      } else {
        onChange(fileItems[0]);
      }
    });

    if (onDropProp) {
      onDropProp(newFiles, rejectedFiles, event);
    }
    if (rejectedFiles.length) {
      //错误提示,使用notify
      const message = rejectedFiles
        .map((file) => {
          let error = file.errors.map((r) => r.message).join(",");
          return `文件:${file.file.name} 错误:[${error}]`;
        })
        .join("\n");
      notify(message, {
        autoHideDuration: 5000,
        type: "error",
        multiLine: true,
      });
    }
  };

  const onRemove = (file: any) => async () => {
    if (validateFileRemoval) {
      try {
        await validateFileRemoval(file);
      } catch (e) {
        return;
      }
    }
    if (multiple) {
      const filteredFiles = files.filter(
        (stateFile) => !shallowEqual(stateFile, file)
      );
      onChange(filteredFiles as any);
      onBlur();
    } else {
      onChange(null);
      onBlur();
    }

    if (onRemoveProp) {
      onRemoveProp(file);
    }
  };

  const childrenElement =
    children && isValidElement(Children.only(children))
      ? (Children.only(children) as ReactElement<any>)
      : undefined;

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxSize,
    minSize,
    multiple,
    disabled: disabled || readOnly,
    ...options,
    onDrop,
  });

  const renderHelperText =
    helperText !== false || ((isTouched || isSubmitted) && invalid);

  const theme = useTheme();

  return (
    <StyledLabeled
      htmlFor={id}
      label={label}
      className={clsx("ra-input", `ra-input-${source}`, className)}
      source={source}
      resource={resource}
      isRequired={isRequired}
      color={(isTouched || isSubmitted) && invalid ? "error" : undefined}
      sx={{
        cursor: disabled || readOnly ? "default" : "pointer",
        ...rest.sx,
      }}
      {...sanitizeInputRestProps(rest)}
    >
      <>
        <div
          {...getRootProps({
            className: FileInputClasses.dropZone,
            "data-testid": "dropzone",
            style: {
              color:
                disabled || readOnly
                  ? theme.palette.text.disabled
                  : inputPropsOptions?.color || theme.palette.text.primary,
              backgroundColor:
                disabled || readOnly
                  ? theme.palette.action.disabledBackground
                  : inputPropsOptions?.backgroundColor,
            },
          })}
        >
          <input
            id={id}
            name={id}
            {...getInputProps({
              ...inputPropsOptions,
            })}
          />
          {placeholder ? (
            placeholder
          ) : multiple ? (
            <p>{translate(labelMultiple)}</p>
          ) : (
            <p>{translate(labelSingle)}</p>
          )}
        </div>
        {renderHelperText ? (
          <FormHelperText error={(isTouched || isSubmitted) && invalid}>
            <InputHelperText
              touched={isTouched || isSubmitted}
              error={error?.message}
              helperText={helperText}
            />
          </FormHelperText>
        ) : null}

        {children && (
          <div className="previews">
            {files.map((file, index) => (
              <MyFileInputPreview
                key={index}
                file={file}
                onRemove={onRemove(file)}
                className={FileInputClasses.removeButton}
                removeIcon={removeIcon}
              >
                <RecordContextProvider value={file}>
                  {childrenElement}
                </RecordContextProvider>
              </MyFileInputPreview>
            ))}
          </div>
        )}
      </>
    </StyledLabeled>
  );
};

MyFileBase64Input.propTypes = {
  accept: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  children: PropTypes.element,
  className: PropTypes.string,
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.element,
  ]),
  labelMultiple: PropTypes.string,
  labelSingle: PropTypes.string,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  validateFileRemoval: PropTypes.func,
  options: PropTypes.object,
  removeIcon: PropTypes.elementType,
  resource: PropTypes.string,
  source: PropTypes.string,
  placeholder: PropTypes.node,
};

const PREFIX = "RaFileInput";

export const FileInputClasses = {
  dropZone: `${PREFIX}-dropZone`,
  removeButton: `${PREFIX}-removeButton`,
};

const StyledLabeled = styled(Labeled, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  width: "100%",
  [`& .${FileInputClasses.dropZone}`]: {
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    fontFamily: theme.typography.fontFamily,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.getContrastText(theme.palette.background.default),
  },
  [`& .${FileInputClasses.removeButton}`]: {},
}));

export type MyFileInputProps = CommonInputProps & {
  accept?: DropzoneOptions["accept"];
  className?: string;
  children?: ReactNode;
  labelMultiple?: string;
  labelSingle?: string;
  maxSize?: DropzoneOptions["maxSize"];
  minSize?: DropzoneOptions["minSize"];
  multiple?: DropzoneOptions["multiple"];
  options?: DropzoneOptions;
  onRemove?: Function;
  placeholder?: ReactNode;
  removeIcon?: FC<SvgIconProps>;
  inputProps?: any;
  validateFileRemoval?(file: any): boolean | Promise<boolean>;
  sx?: SxProps;
};
