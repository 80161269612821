import { DeleteButton, EditButton } from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { RiGroup2Fill } from "@remixicon/react";
import { StatusTypeChoices } from "../../model/Core";

const fields: MyField[] = [
  Field.id(),
  Field.localesValue({
    label: "名称",
    source: "title",
    isFilter: false,
  }),
  Field.editSelect({
    label: "状态",
    source: "status",
    choices: StatusTypeChoices,
    defaultValue: "NORMAL",
  }),
  Field.editNumber({
    fullWidth: true,
    label: "排序",
    minWidth: 80,
    sortable: true,
    defaultValue: 0,
    source: "sort",
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        pagination={false}
        fields={fields}
        sort={{ field: "sort", order: "DESC" }}
        rowActions={[<EditButton />, <DeleteButton />]}
        // groupingColDef={groupingColDef}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <RiGroup2Fill />,
};

export default resource;
