import { useQuery } from "react-query";
import { useCallback, useEffect, useState } from "react";
import { ChatApi } from "../../../api/ChatApi";
import { CustomerFriend, CustomerMessage } from "../../../model/chatData";
import { ContextData } from "./data";
import { useReqChatFriendList } from "./useReqChatFriendList"; // 假设 ContextData 已定义

interface UseCustomerMessageListProps {
  friendAccount: CustomerFriend | null | undefined;
  groupId: string | null | undefined;
}

export const useReqHistoryList = ({
  friendAccount,
  groupId,
}: UseCustomerMessageListProps): ContextData<CustomerMessage> => {
  const [selectIndex, setSelectIndex] = useState(0);

  const { refetch: refetchFriedList } = useReqChatFriendList({
    account: friendAccount?.account,
    groupId: groupId,
  });

  const { data, error, isLoading, isError, refetch } = useQuery(
    ["CustomerMessageList", friendAccount],
    async () => {
      if (!friendAccount) return [];
      if (friendAccount.unReadNum > 0) {
        await ChatApi.doReadMessage(
          friendAccount.account,
          friendAccount.friendAccount
        );
        //刷新朋友列表
        refetchFriedList();
      }
      const customerMessageList = await ChatApi.getCustomerMessageList({
        account: friendAccount.account,
        friendAccount: friendAccount.friendAccount,
      });
      return customerMessageList.list;
    },
    {
      enabled: !!friendAccount,
      onSuccess: (data) => {
        // Ensure selectIndex is within bounds when data is fetched
        if (data && (selectIndex < 0 || selectIndex >= data.length)) {
          setSelectIndex(0);
        }
      },
    }
  );

  const changeSelect = useCallback(
    (index: number) => {
      if (data) {
        if (index < 0 || index >= data.length) {
          index = 0;
        }
        setSelectIndex(index);
      }
    },
    [data]
  );

  useEffect(() => {
    if (data && (selectIndex < 0 || selectIndex >= data.length)) {
      setSelectIndex(0);
    }
  }, [data, selectIndex]);

  return {
    isError,
    error,
    isLoading,
    refetch,
    select: data ? data[selectIndex] : null,
    list: data ?? [],
    selectIndex,
    changeSelect,
  };
};
