import {
  FilterButton,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  TopToolbar,
} from "react-admin";
import { EditSelectField } from "../../components/EditSelectField";
import MyEdit from "../../components/core/MyEdit";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Admin } from "../../model/Admin";
import { StatusTypeChoices } from "../../model/Core";
import { Field } from "../../utils/Field";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import React from "react";
import { ResourceType } from "../../components/core/MyResource";
import { MyShow } from "../../components/core/MyShow";
import { EditCommand } from "../../components/command/EditCommand";
import { MyShowFields } from "../../components/MyShowFields";
import { XhSyncDataButton } from "../sys/XhSyncDataButton";

export let fields: MyField<Admin>[];
fields = [
  Field.text({
    label: "id",
    source: "id",
    isCreate: false,
    isEdit: true,
    isUpdate: false,
    isFilter: false,
  }),
  Field.text({
    label: "分组名称",
    source: "name",
    isFilter: true,
  }),
  {
    label: "状态",
    source: "status",
    component: EditSelectField,
    isFilter: false,
    props: {
      choices: StatusTypeChoices,
      alwaysOn: true,
    },
    filterProps: {
      choices: StatusTypeChoices,
      alwaysOn: true,
    },
    isCreate: true,
    isEdit: true,
    isUpdate: true,
    editComponent: SelectInput,
    editProps: {
      defaultValue: "NORMAL",
      choices: StatusTypeChoices,
      validate: [required()],
    },
  },
  Field.iterator({
    label: "账号列表",
    fullWidth: true,
    source: "accountList",
    children: (
      <SimpleFormIterator inline>
        <TextInput
          source="id"
          label={"id"}
          helperText={false}
          required={true}
        />
        <TextInput
          source="nickname"
          label={"nickname"}
          helperText={false}
          required={true}
        />
        <TextInput
          source="user"
          label={"手机号"}
          helperText={false}
          required={true}
        />
        <TextInput
          source="name"
          label={"name"}
          helperText={false}
          required={true}
        />
        <TextInput
          source="area"
          label={"地区"}
          helperText={false}
          required={true}
        />
        <TextInput
          source="ip"
          label={"ip"}
          helperText={false}
          required={true}
        />
      </SimpleFormIterator>
    ),
  }),
];

const resource: ResourceType = {
  commands: [<EditCommand />],
  show: () => {
    return (
      <MyShow>
        <MyShowFields fields={fields} />
      </MyShow>
    );
  },
  list: () => {
    return (
      <MyList
        fields={fields}
        actions={
          <>
            <TopToolbar>
              <FilterButton />
              <XhSyncDataButton />
            </TopToolbar>
          </>
        }
      />
    );
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <PermContactCalendarIcon />,
};

export default resource;
