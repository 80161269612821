import { MyShow } from "../../../components/core/MyShow";
import { MyShowFields } from "../../../components/MyShowFields";
import React from "react";
import { RaRecord } from "ra-core";
import { MyField } from "../../../components/types";
import { Divider } from "@mui/material";
import { Pagination, ReferenceManyField } from "react-admin";
import { TgGroupMsgTaskResTable } from "../TgGroupMsgTaskRes";

export interface TgGroupMsgTaskShowProp<RecordType extends RaRecord = any> {
  fields: MyField<RecordType>[];
}

function TgGroupMsgTaskShow({ fields }: TgGroupMsgTaskShowProp) {
  return (
    <MyShow>
      <MyShowFields fields={fields} />
      <Divider sx={{ marginY: "24px" }} />
      <ReferenceManyField
        reference="tgGroupMsgTaskRes"
        target="taskId"
        source={"id"}
        label={false}
        pagination={<Pagination />}
      >
        <TgGroupMsgTaskResTable />
      </ReferenceManyField>
    </MyShow>
  );
}

export default TgGroupMsgTaskShow;
