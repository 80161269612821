import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import {
  ChatAccountCustomer,
  CustomerFriend,
  CustomerMessage,
} from "../../../model/chatData";
import { ChatMessage } from "./ChatMessage";

export const ChatHistory = ({
  messages,
  account,
  friend,
  onDelMsg,
}: {
  messages?: CustomerMessage[];
  account: ChatAccountCustomer;
  friend: CustomerFriend;
  onDelMsg: (message: CustomerMessage) => void;
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [friend.id]);

  return (
    <Box sx={{ flex: 1, overflowY: "auto", padding: 2, height: "100%" }}>
      {messages &&
        messages.map((message) => (
          <ChatMessage
            key={message.id}
            message={message}
            account={account}
            friend={friend}
            onDelMsg={onDelMsg}
          />
        ))}
      <div ref={messagesEndRef} />
    </Box>
  );
};
