import React, { useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import {
  ChatAccountCustomer,
  CustomerFriend,
  CustomerMessage,
} from "../../../model/chatData";
import dayjs from "dayjs";
import { MyAvatar } from "./MyAvatar";
import { MessageContent } from "./MessageContent";
import Chip from "@mui/material/Chip";
import { LoadingButton } from "@mui/lab";
import { useTranslateItem } from "../hook/useTranslateItem";
import { Delete as DeleteICon } from "@mui/icons-material";

export const ChatMessage = ({
  message,
  account,
  friend,
  onDelMsg,
}: {
  message: CustomerMessage;
  friend: CustomerFriend;
  account: ChatAccountCustomer;
  onDelMsg: (message: CustomerMessage) => void;
}) => {
  const isOwnMessage = message.sendAccount === account.account;
  const formattedTime = dayjs(message.sendTime * 1000).format(
    "YYYY-MM-DD HH:mm:ss"
  );
  const { translateItem, loading: translateIng } = useTranslateItem(message);
  const [showDel, setShowDel] = useState<boolean>(false);

  const deleteMsg = async () => {
    await onDelMsg(message);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isOwnMessage ? "flex-end" : "flex-start",
        marginBottom: 1,
      }}
    >
      {!isOwnMessage && (
        <MyAvatar head={friend.avatar} sx={{ marginRight: 1 }} />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 0,
          gap: 1,
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            padding: 0,
            borderRadius: 0,
            display: "flex",
            gap: 1,
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Typography
            variant="caption"
            sx={{
              fontSize: "10px",
              display: "block",
              marginTop: "2px",
              textAlign: isOwnMessage ? "right" : "left",
            }}
          >
            {formattedTime}
          </Typography>
          {(message.msgStatus === 1 || message.msgStatus === 2) && (
            <Box sx={{}}>
              <Chip
                size={"small"}
                sx={{
                  fontSize: "12px",
                  height: "auto",
                  "& .MuiChip-label": {
                    display: "block",
                    whiteSpace: "normal",
                  },
                }}
                label={
                  {
                    1: <>未读</>,
                    2: <>已读</>,
                  }[message.isRead]
                }
              />
            </Box>
          )}
        </Box>
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          onMouseEnter={() => setShowDel(true)}
          onMouseLeave={() => setShowDel(false)}
        >
          {showDel && isOwnMessage && (
            <IconButton onClick={() => deleteMsg()}>
              <DeleteICon opacity={0.7} />
            </IconButton>
          )}
          <Box
            sx={{
              padding: 1,
              borderRadius: 1,
              backgroundColor: isOwnMessage ? "#b2cda3" : "#cbcbcb",
            }}
          >
            <MessageContent message={message} />
          </Box>
          {showDel && !isOwnMessage && (
            <IconButton onClick={() => deleteMsg()}>
              <DeleteICon opacity={0.7} />
            </IconButton>
          )}
        </Stack>

        {
          <Box
            sx={{
              marginTop: "0px",
              padding: "2px 4px",
              borderRadius: 1,
              backgroundColor: isOwnMessage ? "#ebfde7" : "#f4f4f4",
            }}
          >
            <Typography
              variant="body2"
              fontSize={12}
              sx={{ wordBreak: "break-word" }}
            >
              {message.translate}
              <LoadingButton
                variant={"outlined"}
                loading={translateIng}
                color={"secondary"}
                onClick={() => translateItem()}
                size={"small"}
                sx={{
                  p: "2px 6px",
                  lineHeight: "1.2",
                  fontSize: "12px",
                  minWidth: "min-content",
                }}
              >
                {message.translate ? <>重译</> : <>翻译</>}
              </LoadingButton>
            </Typography>
          </Box>
        }
        {message.msgStatus === 3 && (
          <Box
            display="flex"
            flexDirection={"row"}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
          >
            <Chip
              size={"small"}
              sx={{
                fontSize: "12px",
                height: "auto",
                "& .MuiChip-label": {
                  display: "block",
                  whiteSpace: "normal",
                },
              }}
              color={"warning"}
              label={message.errmsg || "未知错误"}
            />
          </Box>
        )}
        {message.msgStatus === 1 && (
          <Box
            sx={{
              padding: 1,
              borderRadius: 1,
            }}
          >
            <Typography
              variant="caption"
              sx={{
                fontSize: "10px",
                display: "block",
                marginTop: "2px",
                textAlign: isOwnMessage ? "right" : "left",
              }}
            >
              <>发送中</>
            </Typography>
          </Box>
        )}
      </Box>
      {isOwnMessage && <MyAvatar head={account.head} sx={{ marginLeft: 1 }} />}
    </Box>
  );
};
