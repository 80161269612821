import { useQuery } from "react-query";
import { useCallback, useEffect, useState } from "react";
import { ContextData } from "./data";
import { User, TgProtocolApi } from "../../../api/TgProtocolApi"; // 假设 ContextData 已定义

interface UseTgChatFriendListProps {
  account: string | null | undefined;
  // groupId: string | null | undefined;
}

export const useReqTgChatFriendList = ({
  account,
}: UseTgChatFriendListProps): ContextData<User> => {
  const [selectIndex, setSelectIndex] = useState<number>();
  const [friendAccount, setFriendAccount] = useState("");

  const { data, error, isLoading, isError, refetch } = useQuery(
    ["TgChatFriendList", account],
    async () => {
      let req = { phone: friendAccount ?? "" };
      const res = await TgProtocolApi.dialogsList(account, req);

      return res?.filter((item) => !item.broadcast);
    },
    {
      enabled: !!account,
      onSuccess: (data) => {
        // Ensure selectIndex is within bounds when data is fetched
        if (data) {
          if (!selectIndex || selectIndex < 0 || selectIndex >= data.length) {
            setSelectIndex(0);
          }
        } else {
          setSelectIndex(0);
        }
      },
    }
  );

  const changeSelect = useCallback(
    (index: number) => {
      if (data) {
        if (index < 0 || index >= data.length) {
          index = 0;
        }
        setSelectIndex(index);
      }
    },
    [data]
  );

  useEffect(() => {
    if (data) {
      if (!selectIndex || selectIndex < 0 || selectIndex >= data.length) {
        setSelectIndex(0);
      }
    } else {
      setSelectIndex(0);
    }
  }, [data, selectIndex]);

  const searchByFriendAccount = (friendAccount: string) => {
    setFriendAccount(friendAccount);
  };

  useEffect(() => {
    refetch();
  }, [friendAccount]);

  return {
    isError,
    error,
    isLoading,
    refetch,
    select: data ? data[selectIndex!] : null,
    list: data || [],
    selectIndex,
    changeSelect,
    search: searchByFriendAccount,
  };
};
