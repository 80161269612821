import * as React from "react";
import { useCallback } from "react";
import { Sync } from "@mui/icons-material";
import { LoadingButton } from "../../components/LoadingButton";
import { XhAccountGroupApi } from "../../api/XhAccountGroup";

export interface UploadMenuProps {}

export function XhSyncDataButton(props: UploadMenuProps) {
  const handleClick = useCallback(async () => {
    await XhAccountGroupApi.syncData();
  }, []);

  return (
    <LoadingButton label={"同步"} doLoading={handleClick} icon={<Sync />}>
      {" "}
      同步账号{" "}
    </LoadingButton>
  );
}
