import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React, { useEffect, useMemo } from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { Assistant as AssistantIcon } from "@mui/icons-material";
import { RetrievalFileType } from "../../model/chat";
import {
  DefaultCommands,
  ResourceType,
} from "../../components/core/MyResource";
import { SyncCommand } from "./SyncCommand";
import { StatusTypeChoices } from "../../model/Core";
import {
  BooleanInput,
  FileField,
  Identifier,
  SelectInput,
  useGetOne,
} from "react-admin";
import { MyFileBase64Input } from "../../components/MyFileBase64Input";
import { MyShow } from "../../components/core/MyShow";
import { MyShowFields } from "../../components/MyShowFields";
import { MyFileField } from "../../components/MyFileField";
import { useFormContext } from "react-hook-form";
import { RaRecord } from "ra-core";
import { MyDatagridSelect } from "../../components/core/MyDatagrid";
import { groupingColDef } from "../sys/SysModule";

function SelectModel({
  id,
  apiModelName,
  formData,
}: {
  id: String;
  apiModelName: String;
  formData: RaRecord<Identifier>;
}) {
  const { data, isLoading } = useGetOne(
    "assistantApis",
    { id: id },
    { enabled: !!id }
  );
  const formContext = useFormContext();

  const selectItem = useMemo(
    () => data?.aiModels?.find((r: any) => r.name === apiModelName),
    [apiModelName, data?.aiModels, formContext]
  );

  useEffect(() => {
    if (!selectItem && !isLoading) {
      formContext.setValue("apiModelName", "");
    }
  }, [selectItem, isLoading]);

  if (!id) return <></>;
  return (
    <>
      <SelectInput
        validate={() => undefined}
        isLoading={isLoading}
        source="apiModelName"
        label={"选择模型"}
        required
        choices={
          data?.aiModels?.map((r: any) => {
            return { id: r.name, name: r.remark };
          }) || []
        }
      />
      {selectItem?.enableRetrieval && (
        <BooleanInput
          label={"检索文件"}
          source={"enableRetrieval"}
          defaultValue={selectItem?.enableRetrieval}
        />
      )}
      {selectItem?.enableRetrieval &&
        selectItem?.enableChatRetrieval &&
        formData?.enableRetrieval && (
          <BooleanInput
            label={"聊天检索文件"}
            helperText={"是否支持用户在聊天框上传文件进行检索"}
            source={"enableChatRetrieval"}
            defaultValue={selectItem?.enableChatRetrieval}
          />
        )}
      {selectItem?.enableImages && (
        <BooleanInput
          label={"图片识别"}
          source={"enableImages"}
          defaultValue={selectItem?.enableImages}
        />
      )}
      {selectItem?.enableAutoVoice && (
        <BooleanInput
          label={"语音对话"}
          source={"enableAutoVoice"}
          defaultValue={selectItem?.enableAutoVoice}
        />
      )}
      {selectItem?.enableMessageToVoice && (
        <BooleanInput
          label={"语音朗读"}
          source={"enableMessageToVoice"}
          defaultValue={selectItem?.enableMessageToVoice}
        />
      )}
    </>
  );
}

const fields: MyField[] = [
  Field.id(),
  Field.ref({
    label: "分类",
    source: "categoryId",
    reference: "assistantCategories",
    referenceLabel: "defaultTitle",
  }),
  Field.editSelect({
    label: "状态",
    source: "status",
    choices: StatusTypeChoices,
    defaultValue: "NORMAL",
  }),
  Field.text({
    fullWidth: true,
    minWidth: 260,
    label: "名称",
    source: "name",
  }),
  Field.editNumber({
    fullWidth: true,
    label: "排序",
    minWidth: 80,
    defaultValue: 0,
    sortable: true,
    source: "sort",
  }),
  Field.textArray({
    label: "开场对话",
    source: "questions",
  }),
  Field.img({
    label: "图标",
    source: "icon",
  }),
  // Field.select({
  //   label: "类型",
  //   source: "apiType",
  //   choices: ApiTypeChoices,
  //   defaultValue: "OPENAI_ASSISTANT",
  // }),
  Field.ref({
    label: "AI助理类型",
    source: "apiId",
    reference: "assistantApis",
    referenceLabel: "name",
  }),
  Field.dependency({
    label: "选择模型",
    fullWidth: true,
    source: "apiModelName",
    isList: false,
    render(record: any, source?: string) {
      console.log("render", record, source);
      return <></>;
    },
    editRender({ formData, rest }) {
      return (
        <SelectModel
          id={formData.apiId}
          apiModelName={formData.apiModelName}
          formData={formData}
        />
      );
    },
  }),
  // Field.select({
  //   label: "模型",
  //   source: "apiModel",
  //   choices: ApiTypeModelChoices,
  //   defaultValue: "OPENAI_ASSISTANT",
  // }),
  Field.text({
    label: "简介",
    source: "desc",
    isRequired: true,
    fullWidth: true,
    minRows: 4,
    multiline: true,
  }),
  Field.maxText({
    minWidth: 100,
    label: "提示词",
    isRequired: false,
    source: "instructions",
    multiline: true,
    minRows: 4,
  }),
  Field.dependency({
    label: "检索资料",
    fullWidth: true,
    isList: false,
    source: "enableRetrievalFiles",
    render(record: any, source?: string) {
      if (!record.enableRetrieval) {
        return undefined;
      }
      return <FileField source="files" label={"资料"} />;
    },
    editRender({ formData, rest }) {
      if (!formData.enableRetrieval) {
        return undefined;
      }
      return (
        <MyFileBase64Input
          source="files"
          label={"资料"}
          accept={RetrievalFileType}
          maxSize={1_000_000}
          multiple={true}
          {...rest}
        >
          <MyFileField source={`src`} title="name" />
        </MyFileBase64Input>
      );
    },
  }),
  Field.boolean({
    label: "检索文件",
    source: "enableRetrieval",
    defaultValue: "false",
    isUpdate: false,
    isCreate: false,
    isEdit: false,
  }),
  Field.boolean({
    label: "聊天检索文件",
    source: "enableChatRetrieval",
    helperText: "是否支持用户在聊天框上传文件进行检索",
    defaultValue: "false",
    isUpdate: false,
    isCreate: false,
    isEdit: false,
  }),
  Field.boolean({
    label: "图片识别",
    source: "enableImages",
    defaultValue: "false",
    isUpdate: false,
    isCreate: false,
    isEdit: false,
  }),
  Field.boolean({
    label: "语音对话",
    source: "enableAutoVoice",
    defaultValue: "false",
    isUpdate: false,
    isCreate: false,
    isEdit: false,
  }),
  Field.boolean({
    label: "语音朗读",
    source: "enableMessageToVoice",
    defaultValue: "false",
    isUpdate: false,
    isCreate: false,
    isEdit: false,
  }),
  Field.boolean({
    label: "对话开关",
    source: "enableChat",
    defaultValue: "true",
  }),
  Field.dateTime(
    {
      label: "创建时间",
      source: "createdDate",
    },
    {
      isList: false,
    }
  ),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

export const AssistantTable = (props: any) => {
  return <MyDatagridSelect fields={fields} rowActions={[]} />;
};

const resource: ResourceType = {
  commands: [...DefaultCommands, <SyncCommand />],
  show: () => {
    return (
      <MyShow>
        <MyShowFields fields={fields} />
      </MyShow>
    );
  },
  list: () => {
    return (
      <MyList
        fields={fields}
        sort={{ field: "sort", order: "DESC" }}
        perPage={20}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <AssistantIcon />,
};

export default resource;
