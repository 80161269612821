import React, { useCallback } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import {
  AskItem,
  ChatAccountCustomer,
  CustomerFriend,
  CustomerMessage,
} from "../../../model/chatData";
import { ChatApi } from "../../../api/ChatApi";
import { LoadingButton } from "@mui/lab";
import EditMessage from "./EditMessage";

export const ChatInput = ({
  sending,
  account,
  onSend,
  history,
  friend,
}: {
  sending: boolean;
  friend: CustomerFriend;
  account: ChatAccountCustomer;
  history: CustomerMessage[];
  onSend: (message: string) => void;
}) => {
  const [message, setMessage] = React.useState("");
  const [optimizeMessage, setOptimizeMessage] = React.useState<AskItem[]>([]);

  const [optimizeing, setOptimizeing] = React.useState(false);

  const doOptimizeMessageAnswer = useCallback(async () => {
    try {
      setOptimizeing(true);
      const optimizeMessage = await ChatApi.doOptimizeMessageAnswer({
        account: account.account,
        msg: message,
        list: history,
      });
      setOptimizeMessage(optimizeMessage);
    } finally {
      setOptimizeing(false);
    }
  }, [account, history, message]);

  const handleSend = () => {
    if (message.trim() !== "") {
      onSend(message);
      setMessage("");
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    // setMessage((prev) => `${prev} ${suggestion}`.trim());
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderTop: "1px solid #ddd",
      }}
    >
      <Box sx={{ display: "flex", padding: "6px" }}>
        <Box flex={1}>
          <TextField
            label={"输入中文消息"}
            fullWidth
            multiline
            minRows={2}
            maxRows={3}
            variant="outlined"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type a message"
          />
          {!!optimizeMessage.length && (
            <Box
              sx={{
                position: "relative",
                height: "88px",
                flex: 1,
                display: "flex",
                overflowX: "auto",
                gap: "8px",
                alignItems: "stretch",
                padding: "8px",
                boxSizing: "border-box",
              }}
            >
              <Box
                flex={1}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  overflowX: "auto",
                  gap: "8px",
                  backgroundColor: "#fff",
                }}
              >
                {optimizeMessage.map((suggestion, index) => (
                  <EditMessage
                    key={index}
                    msg={suggestion.answer}
                    friend={friend}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        textOverflow: "ellipsis",
                        width: "max-content",
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        padding: "8px",
                        backgroundColor: "#f9f9f9",
                      }}
                      onClick={() => handleSuggestionClick(suggestion.answer)}
                    >
                      <Typography variant="body2">
                        {suggestion.answer}
                      </Typography>
                      <Typography variant="body2" sx={{ color: "gray" }}>
                        {suggestion.translation}
                      </Typography>
                    </Box>
                  </EditMessage>
                ))}
              </Box>
            </Box>
          )}
        </Box>
        <Box
          display={"flex"}
          flexDirection="column"
          gap={"6px"}
          padding={"6px"}
        >
          <LoadingButton
            loading={optimizeing}
            variant="outlined"
            onClick={doOptimizeMessageAnswer}
          >
            优化
          </LoadingButton>
          <LoadingButton
            loading={sending}
            variant="contained"
            color="primary"
            onClick={handleSend}
          >
            发送
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};
