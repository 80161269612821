import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";
import { TgAccount } from "../model/tgProtocolChatData";
import { AskItem } from "../model/chatData";
import { TgAcStateCheckCommand } from "../pages/tgChatConfig/commands/TgAcStateCheckCommand";

export const TgProtocolApi = {
  create: async (phone: string): Promise<TgAccount> => {
    let { data } = await fetchJson(
      `${API_URL}/tgProtocolChat/create/${phone}`,
      {
        method: "POST",
      }
    );
    return data;
  },
  loginSendCode: async (
    id: string,
    phone: string
  ): Promise<Number | undefined> => {
    let { data } = await fetchJson(
      `${API_URL}/tgProtocolChat/loginSendCode/${id}/${phone}`,
      {
        method: "POST",
      }
    );
    return data;
  },
  loginSendPwd: async (form: TgLoginPwdForm): Promise<Number | undefined> => {
    let { data } = await fetchJson(`${API_URL}/tgProtocolChat/loginSendPwd`, {
      method: "POST",
      body: new URLSearchParams(form),
    });
    return data;
  },
  accountLoginState: async (id: string): Promise<TgClientSession> => {
    let { data } = await fetchJson(
      `${API_URL}/tgProtocolChat/accountLoginState/${id}`,
      {
        method: "POST",
      }
    );
    return data;
  },
  reconnect: async (id: string): Promise<Boolean> => {
    let { data } = await fetchJson(
      `${API_URL}/tgProtocolChat/reconnect/${id}`,
      {
        method: "POST",
      }
    );
    return data;
  },
  accountGetMe: async (id: string): Promise<TgClientSession> => {
    let { data } = await fetchJson(
      `${API_URL}/tgProtocolChat/accountGetMe/${id}`,
      {
        method: "POST",
      }
    );
    return data;
  },
  accountPhoto: async (clientId: string, userId: string): Promise<TgFile> => {
    let { data } = await fetchJson(`${API_URL}/tgProtocolChat/accountPhoto`, {
      method: "POST",
      body: new URLSearchParams({
        clientId: clientId,
        userId: userId,
      }),
    });
    return data;
  },
  dialogsList: async (
    id?: string | null,
    f?: TgAcUserSearchForm
  ): Promise<User[] | undefined> => {
    let { data } = await fetchJson(
      `${API_URL}/tgProtocolChat/dialogsList/${id}`,
      {
        method: "POST",
        body: new URLSearchParams(f),
      }
    );
    return data;
  },
  messageGet: async (req: MessageGetForm): Promise<Message[]> => {
    let { data } = await fetchJson(`${API_URL}/tgProtocolChat/messageGet`, {
      method: "POST",
      body: JSON.stringify(req),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    });
    return data ? data : [];
  },
  messageDelete: async (
    id?: string | null,
    msgId?: string | null
  ): Promise<Message[] | undefined> => {
    let { data } = await fetchJson(
      `${API_URL}/tgProtocolChat/messageDelete/${id}/${msgId}`,
      {
        method: "POST",
      }
    );
    return data;
  },
  dialogAdd: async (
    id?: string | null,
    phone?: string | null
  ): Promise<string | undefined> => {
    let { data } = await fetchJson(
      `${API_URL}/tgProtocolChat/dialogsAdd/${id}/${phone}`,
      {
        method: "POST",
      }
    );
    return data;
  },
  dialogPin: async (
    id: string,
    friendId: string,
    pin: boolean
  ): Promise<string | undefined> => {
    let { data } = await fetchJson(`${API_URL}/tgProtocolChat/dialogPin`, {
      method: "POST",
      body: new URLSearchParams({
        clientId: id,
        peer: friendId,
        pin: `${pin}`,
      }),
    });
    return data;
  },
  messageSend: async (req: TgMessageSendForm): Promise<Boolean | undefined> => {
    let { data } = await fetchJson(`${API_URL}/tgProtocolChat/messageSend`, {
      method: "POST",
      body: new URLSearchParams(req),
    });
    return data;
  },
  unReadChatFriend: async (id?: string | null): Promise<User[] | undefined> => {
    let { data } = await fetchJson(
      `${API_URL}/tgProtocolChat/unReadChatFriend/${id}`,
      {
        method: "POST",
      }
    );
    return data;
  },
  unReadChat: async (
    id?: string | null
  ): Promise<TgUnreadModel[] | undefined> => {
    let { data } = await fetchJson(
      `${API_URL}/tgProtocolChat/unReadChat/${id}`,
      {
        method: "POST",
      }
    );
    return data;
  },
  readChat: async (id?: string | null): Promise<boolean> => {
    let { data } = await fetchJson(`${API_URL}/tgProtocolChat/readChat/${id}`, {
      method: "POST",
    });
    return data;
  },
  translateItem: async (form: Message): Promise<Message> => {
    let { data } = await fetchJson(`${API_URL}/tgProtocolChat/translateItem`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(form),
    });
    return data;
  },
  answer: async (form: AnswerForm): Promise<AskItem[]> => {
    let { data } = await fetchJson(`${API_URL}/tgProtocolChat/answer`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(form),
    });
    return data;
  },
  reAnswer: async (form: AnswerForm): Promise<AskItem[]> => {
    let { data } = await fetchJson(`${API_URL}/tgProtocolChat/reAnswer`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(form),
    });
    return data;
  },
  doOptimizeMessageAnswer: async (
    form: OptimizeMessageForm
  ): Promise<AskItem[]> => {
    let { data } = await fetchJson(`${API_URL}/tgProtocolChat/optimize`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(form),
    });
    return data;
  },
  doChatUpTop: async (id: string): Promise<Boolean> => {
    let { data } = await fetchJson(
      `${API_URL}/tgProtocolChat/doChatUpTop/${id}`,
      {
        method: "POST",
      }
    );
    return data;
  },
  doChatCancelUpTop: async (id: string): Promise<Boolean> => {
    let { data } = await fetchJson(
      `${API_URL}/tgProtocolChat/doChatCancelUpTop/${id}`,
      {
        method: "POST",
      }
    );
    return data;
  },
  doUpAcUser: async (id: string): Promise<Boolean> => {
    let { data } = await fetchJson(
      `${API_URL}/tgProtocolChat/doUpAcUser/${id}`,
      {
        method: "POST",
      }
    );
    return data;
  },
  doCancelUpAcUser: async (id: string): Promise<Boolean> => {
    let { data } = await fetchJson(
      `${API_URL}/tgProtocolChat/doCancelUpAcUser/${id}`,
      {
        method: "POST",
      }
    );
    return data;
  },
  downMedia: async (req: DownMediaReq): Promise<DownMediaResp> => {
    let { data } = await fetchJson(`${API_URL}/tgProtocolChat/downMedia`, {
      method: "POST",
      body: new URLSearchParams(req),
    });
    return data;
  },
  reconnectAll: async (): Promise<number> => {
    let { data } = await fetchJson(`${API_URL}/tgProtocolChat/reconnectAll`, {
      method: "POST",
    });
    return data;
  },
  delAllDeactivated: async (): Promise<number> => {
    let { data } = await fetchJson(
      `${API_URL}/tgProtocolChat/delAllDeactivated`,
      {
        method: "POST",
      }
    );
    return data;
  },
  allOkStateAcGetMe: async (): Promise<number> => {
    let { data } = await fetchJson(
      `${API_URL}/tgProtocolChat/allOkStateAcGetMe`,
      {
        method: "POST",
      }
    );
    return data;
  },
};

export interface TgAcUserSearchForm extends Record<string, string> {
  phone: string;
}

export interface TgUnreadModel {
  userId: string;
  unreadChatList?: string[];
}

export interface DownMediaResp {
  status: number;
  tgFile?: TgFile;
}

export interface DownMediaReq extends Record<string, string> {
  clientId: string;
  userId: string;
  msgId: string;
}

export interface MessagePageResp {
  limit: number;
  page: number;
  total: number;
  data_list?: Message[];
}

export interface MessageGetForm {
  client_id: string | null | undefined;
  receiver: string | null | undefined;
  limit: number;
  page: number;
}

export interface OptimizeMessageForm {
  account: string;
  msg: string;
  list?: Message[];
}
export interface AnswerForm {
  account: string;
  accountId: string;
  friendAccount: string;
  list?: Message[];
}

export interface TgMessageSendForm extends Record<string, string> {
  clientId: string;
  receiver: string;
  msgType: string;
  message: string;
}

export interface TgLoginPwdForm extends Record<string, string> {
  sessionName: string;
  phoneNumber: string;
  code: string;
  password: string;
}

export interface TgClientSession {
  name?: string;
  /*
    LOGIN_REQUIRED = 1
    LOGIN_DONE = 2
    LOGIN_FAILED = 3
    LOGIN_WAITING_FOR_TELEGRAM_CLIENT = 4
   */
  login_status?: number;
}

export interface Dialog {
  name?: string;
  date?: string;
  draft?: Draft;
  message?: Message;
  entity?: User;
}

export interface Draft {
  text?: string;
  entity?: User;
  date?: string;
  link_preview?: boolean;
  reply_to_msg_id?: number;
}

export interface Message {
  id?: number;
  peer_id?: PeerUser;
  msg_type?: number;
  file_name?: string;
  date?: string;
  message?: string;
  transferMsg?: string;
  out?: boolean;
  mentioned?: boolean;
  media_unread?: boolean;
  silent?: boolean;
  post?: boolean;
  from_scheduled?: boolean;
  legacy?: boolean;
  edit_hide?: boolean;
  pinned?: boolean;
  noforwards?: boolean;
  invert_media?: boolean;
  offline?: boolean;
  from_id?: PeerUser;
  from_boosts_applied?: any;
  saved_peer_id?: any;
  fwd_from?: any;
  via_bot_id?: any;
  via_business_bot_id?: any;
  reply_to?: any;
  media?: any;
  reply_markup?: any;
  entities?: any[];
  views?: any;
  forwards?: any;
  replies?: any;
  edit_date?: any;
  post_author?: any;
  grouped_id?: any;
  reactions?: any;
  restriction_reason?: any[];
  ttl_period?: any;
  quick_reply_shortcut_id?: any;
  effect?: any;
  factcheck?: any;
}

export interface PeerUser {
  user_id?: number;
}

export interface UserProfilePhoto {
  photo_id?: number;
  dc_id?: number;
  has_video?: boolean;
  personal?: boolean;
  stripped_thumb?: Uint8Array;
}

export interface UserStatusOffline {
  was_online?: string;
}

export interface User {
  id?: string;
  oid?: number;
  is_self?: boolean;
  contact?: boolean;
  mutual_contact?: boolean;
  deleted?: boolean;
  bot?: boolean;
  bot_chat_history?: boolean;
  bot_nochats?: boolean;
  verified?: boolean;
  restricted?: boolean;
  min?: boolean;
  bot_inline_geo?: boolean;
  support?: boolean;
  scam?: boolean;
  apply_min_photo?: boolean;
  fake?: boolean;
  bot_attach_menu?: boolean;
  premium?: boolean;
  attach_menu_enabled?: boolean;
  bot_can_edit?: boolean;
  close_friend?: boolean;
  stories_hidden?: boolean;
  stories_unavailable?: boolean;
  contact_require_premium?: boolean;
  bot_business?: boolean;
  access_hash?: number;
  first_name?: string;
  last_name?: string;
  username?: string;
  phone?: string;
  photo?: UserProfilePhoto;
  status?: UserStatusOffline;
  bot_info_version?: any;
  restriction_reason?: any;
  bot_inline_placeholder?: any;
  lang_code?: any;
  emoji_status?: any;
  usernames?: any;
  stories_max_id?: any;
  color?: any;
  profile_color?: any;
  broadcast?: boolean;
  last_msg?: string;
  last_msg_date?: string;
  isTop: number;
  headUrl?: string;
}

export interface TgFile {
  id?: string;
  userId?: string;
  tgUserId?: string;
  msgId?: string;
  fileContent: string;
  fileName: string;
}

export interface TgUnread {
  id?: string;
  userId?: string;
  tgUserId?: string;
  tgFriendId?: string;
  unread?: number;
  createdDate?: string;
  updateDate?: string;
}
